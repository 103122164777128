import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
    options: {
        customProperties: true
    }
})

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#355991',
                fundo: '#f2f6fc',
            },
        },
    },
})
