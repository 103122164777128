import {http} from '@/config/http'
import router from '@/router'

export const auth = {
  state: {
    logado: false,
    token: null,
    usuario: null,
    alertaMesagem: null,
    cargo: null,
    cliente: null,
  },
  mutations: {
    login(state, data){
        state.logado = true
        state.token = data.token
        state.usuario = data.usuario
        state.alertaMesagem = null
        state.cargo = null
        state.cliente = data.cliente
    },
    logout(state){
        state.logado = false
        state.token = null
        state.usuario = null
        state.alertaMesagem = null
        state.cargo = null
    },
    erroLogin(state, erro){
        state.logado = false
        state.token = null
        state.usuario = null
        if(erro){
            state.alertaMesagem = erro
        }
        else{
            state.alertaMesagem = 'Erro de requisição, entre em contato com o suporte.'
        }   
        state.cargo = null  
    },
    setCargo(state, cargo){
        state.cargo = cargo
    },
    setCliente(state, cliente){
        state.cliente = cliente
    },
    setToken(state, token){
        state.token = token
    },
  },
  actions: {
    async login({commit}, [login, senha, cliente]){

        commit('logout')

        try {

            var response = await http().post('/login', {
                login: login,
                senha: senha,
                cliente: cliente,
            })

            if(response.data.erro) {

                commit('erroLogin', response.data.erro)
            }
            if(response.data.token) {

                sessionStorage.setItem('token', response.data.token)
                localStorage.setItem('token', response.data.token)

                commit('login', response.data)
                router.push({name: "main"})
            }

        } catch (e) {
            commit('erroLogin')
        }
    },
    async loginToken({commit}, [token, cliente]){

        commit('logout')

        commit('setToken', token)

        try {

            var response = await http().post('/login/loginToken', {
                token: token,
                cliente: cliente,
            })

            if(response.data.erro) {

                commit('erroLogin', response.data.erro)
            }

            if(response.data.token) {

                sessionStorage.setItem('token', response.data.token)
                localStorage.setItem('token', response.data.token)

                commit('login', response.data)
                router.push({name: "main"})
            }
            else {

                commit('logout')
            }

        } catch (e) {

            commit('erroLogin', 'Sua sessão expirou. Faça novamente o login.')
        }
    },
    logout({commit}){

        sessionStorage.removeItem('token')
        localStorage.removeItem('token')

        commit('logout')
        router.push({name: 'login', params: { cliente: this.state.auth.cliente }})
    },
    sessaoExpirou({commit}){

        sessionStorage.removeItem('token')
        localStorage.removeItem('token')

        commit('erroLogin', 'Sua sessão expirou. Faça novamente o login.')
        router.push({name: 'login', params: { cliente: this.state.auth.cliente }})
    },
    setCargo({commit}, [cargo]){

        commit('setCargo', cargo)
    },
  },
  getters : {
    getLogado: state => state.logado,
    getToken: state => state.token,
    getUsuario: state => state.usuario,
    getAlertaMesagem: state => state.alertaMesagem,
    getCargo: state => state.cargo ? state.cargo : {cargo: null, id_cargo: null},
    getCliente: state => state.cliente,
  },
}