<template>
    <v-container fluid>

        <!-- Filtros -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <div
                    class="botao_filtro rounded"
                    @click="miniFiltros"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon 
                        color="white"
                        dense
                    >mdi-filter</v-icon>
                </div>
            </template>
            <span>Exibir Filtros</span>
        </v-tooltip>
        <v-navigation-drawer
            v-model="menuFiltros"
            :permanent="menuFiltros"
            style="position: fixed; top: 65px;"
            right
            app
            width="350"
        >
            <v-list-item 
                @click.stop="miniFiltros"
                class="px-4"
            >
                <v-list-item-title>Filtros</v-list-item-title>
                <v-icon>mdi-chevron-right</v-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-col cols="12" class="px-2 ml-auto" align="end">
                <v-btn
                    class="ml-2 mb-1"
                    color="primary"
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar
                </v-btn> 
                <v-btn
                    class="ml-2 mb-1"
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
            <v-form ref="formFiltro">
                <v-row no-gutters class="mt-2">
                    <v-col cols="12" class="px-2">
                        <v-text-field
                            label="Período Inicial"
                            v-model="filtro.mesInicial"
                            :rules="regra.mesInicial"
                            required
                            outlined
                            dense 
                            type="month"
                            :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="px-2">
                        <v-text-field
                            label="Período Final"
                            v-model="filtro.mesFinal"
                            :rules="regra.mesFinal"
                            required
                            outlined
                            dense 
                            type="month"
                            :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                        ></v-text-field>
                    </v-col>
                    <v-col v-for="(obj, index) in lista.filtro" :key="index" cols="12" class="px-2">
                        <v-autocomplete
                            v-if="obj.tabela == 'acordo.modelo'"
                            :label="obj.nome"
                            :items="lista[obj.tabela]"
                            v-model="filtro[obj.var_id]"
                            outlined
                            dense
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-if="obj.tabela != 'acordo.modelo'"
                            :label="obj.nome"
                            :items="lista[obj.tabela]"
                            v-model="filtro[obj.var_id]"
                            outlined
                            dense
                            multiple
                            clearable
                            chips
                            small-chips
                            deletable-chips
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="selecionarTudo(obj.var_id, obj.tabela)"
                                >
                                <v-list-item-action>
                                    <v-icon :color="filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                    {{ iconeSelecionarTudo(obj.var_id, obj.tabela) }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                    Selecionar Tudo
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{item}">
                                <v-chip
                                    class="mt-1"
                                    pill
                                    small
                                    :title="item.text"
                                >
                                    <v-icon 
                                        left
                                        small
                                        @click="removeItemFiltro(item, filtro[obj.var_id])"
                                    >mdi-close-circle</v-icon>
                                    {{ item.text }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
            <br>
            <br>
        </v-navigation-drawer>

        <!-- Titulo e botões -->
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Oportunidades</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                <v-btn
                    class="mt-2 ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
            </v-col>
        </v-row>

        <!-- Indicadores -->
        <v-row no-gutters>
            <div
                v-for="indicador in indicadoresTotal"
                :key="indicador.nome"
            >
                <v-tooltip top :color="indicador.cor" v-if="indicador.filtro">
                    <template v-slot:activator="{ on, attrs }">
                        <v-card
                            :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : '') + (indicador.mr ? indicador.mr : '')"
                            :color="indicador.cor"
                            v-bind="attrs"
                            v-on="on"
                            @click="filtroIndicadore(indicador.filtro)"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div 
                                        class="indicadoresTitulo"
                                        v-bind="attrs"
                                        v-on="on"
                                    ><v-icon dark small>mdi-filter</v-icon> {{indicador.nome}}</div>
                                </template>
                                <span>{{indicador.desc}}</span>
                            </v-tooltip>
                            <div 
                                class="indicadoresValor"
                            >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                            <v-avatar 
                                class="indicadoresIcone"
                                size="46"
                                :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                            >
                                <v-icon dark>{{indicador.icone}}</v-icon>
                            </v-avatar>
                            <v-progress-linear
                                v-show="indicador.percSufixo"
                                :value="indicador.perc"
                                color="#fff"
                                height="12"
                                class="indicadoresPercemtualBarra"
                            ></v-progress-linear>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div 
                                        v-show="indicador.percSufixo"
                                        class="indicadoresPercemtualValor"
                                        v-bind="attrs"
                                        v-on="on"
                                    >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                                </template>
                                <span>{{indicador.percDesc}}</span>
                            </v-tooltip>
                        </v-card>
                    </template>
                    <span>{{indicador.filtroTitulo}}</span>
                </v-tooltip>
                <v-card
                    :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : '') + (indicador.mr ? indicador.mr : '')"
                    :color="indicador.cor"
                    v-else
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                                class="indicadoresTitulo"
                                v-bind="attrs"
                                v-on="on"
                            >{{indicador.nome}}</div>
                        </template>
                        <span>{{indicador.desc}}</span>
                    </v-tooltip>
                    <div 
                        class="indicadoresValor"
                    >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                    <v-avatar 
                        class="indicadoresIcone"
                        size="46"
                        :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                    >
                        <v-icon dark>{{indicador.icone}}</v-icon>
                    </v-avatar>
                    <v-progress-linear
                        v-show="indicador.percSufixo"
                        :value="indicador.perc"
                        color="#fff"
                        height="12"
                        class="indicadoresPercemtualBarra"
                    ></v-progress-linear>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                                v-show="indicador.percSufixo"
                                class="indicadoresPercemtualValor"
                                v-bind="attrs"
                                v-on="on"
                            >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                        </template>
                        <span>{{indicador.percDesc}}</span>
                    </v-tooltip>
                </v-card>
            </div>
        </v-row>

        <!-- Graficos  -->
        <v-row no-gutters>
            <!-- Grafico Oportunidades Mensal -->
            <v-col cols="12" sm="12" :md="layoutCompacto?6:12" :lg="layoutCompacto?6:12" :xl="layoutCompacto?6:12">
                <v-card class="mt-4 px-2 pt-2" elevation="1">  
                    <v-icon 
                        style="position: absolute; top: 10px; left: 10px; z-index: 2" 
                        left
                        :color="layoutCompacto?'primary':''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                    >mdi-view-compact</v-icon>
                    <highcharts :options="graficoMes" ref="grafico1"></highcharts>
                </v-card>
            </v-col>
            <!-- Grafico Oportunidades Anual -->
            <v-col cols="12" sm="12" :md="layoutCompacto?6:12" :lg="layoutCompacto?6:12" :xl="layoutCompacto?6:12">
                <v-card class="mt-4 px-2 pt-2" :class="layoutCompacto?'ml-4':''" elevation="1"> 
                    <v-icon 
                        style="position: absolute; top: 10px; left: 10px; z-index: 2" 
                        left
                        :color="layoutCompacto?'primary':''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                    >mdi-view-compact</v-icon>
                    <highcharts :options="graficoAno" ref="grafico2"></highcharts>
                </v-card>
            </v-col>
        </v-row>

        <!-- Tabela Detalhado -->
        <v-card class="my-4 px-2 pt-2" elevation="1">
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.total_valor`]="{ item }"> 
                    {{ (item.total_valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                </template>
                <template v-slot:[`item.comprometido_percentual`]="{ item }"> 
                    {{ item.comprometido_percentual + '%' }}
                </template>
                <template v-slot:[`item.comprometido_valor`]="{ item }"> 
                    {{ (item.comprometido_valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                </template>
                <template v-slot:[`item.oportunidade_percentual`]="{ item }"> 
                    <div :class="getClassOportunidade(item.oportunidade_percentual)"></div>
                    {{ item.oportunidade_percentual + '%' }}
                </template>
                <template v-slot:[`item.oportunidade_valor`]="{ item }"> 
                    {{ (item.oportunidade_valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                </template>
            </v-data-table>  
        </v-card>

        <!-- Dialogo Detalhado -->
        <v-dialog
            v-model="dialogoDetalhado"
            width="95%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Detalhado
                </v-card-title>
                <v-row no-gutters class="mx-2">
                    <v-col cols="6">
                    </v-col>
                    <v-col cols="6" align="end"  class="py-2">  
                        <v-btn
                            class="ml-2 my-1"
                            color="primary" 
                            @click="exportar({fnDetalhado: true})"  
                        >
                            <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="dadosDetalhado"
                    :server-items-length="totalDetalhado" 
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                    }"
                    :options.sync="paginationDetalhado"
                    multi-sort
                    class="elevation-0"
                >
                    <template v-slot:[`item.total_valor`]="{ item }"> 
                        {{ (item.total_valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                    </template>
                    <template v-slot:[`item.comprometido_percentual`]="{ item }"> 
                        {{ item.comprometido_percentual + '%' }}
                    </template>
                    <template v-slot:[`item.comprometido_valor`]="{ item }"> 
                        {{ (item.comprometido_valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                    </template>
                    <template v-slot:[`item.oportunidade_percentual`]="{ item }"> 
                        <div :class="getClassOportunidade(item.oportunidade_percentual)"></div>
                        {{ item.oportunidade_percentual + '%' }}
                    </template>
                    <template v-slot:[`item.oportunidade_valor`]="{ item }"> 
                        {{ (item.oportunidade_valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="dialogoDetalhado = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            menuFiltros: null,
            lista: {},
            filtro: {},
            headers: [
                { text: 'Total', value: 'total', align: 'center'},
                { text: 'Valor Total', value: 'total_valor', align: 'center'},
                { text: 'Comprometido', value: 'comprometido', align: 'center'},
                { text: 'Comprometido (%)', value: 'comprometido_percentual', align: 'center'},
                { text: 'Comprometido (R$)', value: 'comprometido_valor', align: 'center'},
                { text: 'Oportunidade', value: 'oportunidade', align: 'center'},
                { text: 'Oportunidade (%)', value: 'oportunidade_percentual', align: 'center'},
                { text: 'Oportunidade (R$)', value: 'oportunidade_valor', align: 'center'},
            ],
            dados: [],
            total: null, 
            paginationLimpa: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 5,
                multiSort: true,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            pagination: {},
            regra: { 
                mesInicial: [
                    v => !!v || 'Campo obrigatório.',
                ],
                mesFinal: [
                    v => !!v || 'Campo obrigatório.',
                    v => (!!v && v >= this.filtro.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                ],
            },
            modelosLista: [],
            carregando: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            indicadoresTotal: [
                {
                    nome: 'VALOR TOTAL', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Valor total dos espaços.', 
                    icone: 'mdi-wallet', 
                    cor: 'purple',
                    mr: ' mr-4',
                },
                {
                    nome: 'COMPROMETIDO C/ DESCONTO', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Valor total dos espaços comprometidos com desconto.', 
                    icone: 'mdi-currency-usd', 
                    cor: 'warning', 
                    perc: 0, 
                    percSufixo: 'p', 
                    percNome: 'Percentual Comprometido', 
                    percDesc: 'Percentual de espaços comprometidos',
                    mr: ' mr-4',
                },
                {
                    nome: 'OPORTUNIDADE', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Valor total dos espaços disponiveis para acordos.', 
                    icone: 'mdi-cash-multiple', 
                    cor: 'info',
                    perc: 0, 
                    percSufixo: 'p', 
                    percNome: 'Percentual de Oprtunidade', 
                    percDesc: 'Percentual de espaço disponiveis para acordos.',
                    mr: ' mr-4',
                },
            ],
            layoutCompacto: true,
            graficoMes: {
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                title: {
                    text: "Oportunidade Mensal",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                chart: {
                    type: 'column',
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                yAxis: {
                    visible: false,
                },
                legend: {
                    itemStyle: {
                        color: this.$vuetify.theme.dark ? '#fff' : null,
                    },
                    itemHoverStyle: {
                        color: this.$vuetify.theme.dark ? '#9e9e9e' : null,
                    },
                },
                minhasVariaveis: {
                    themeDark: this.$vuetify.theme.dark,
                    valor: [[], []]
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            useHTML: true,
                            formatter: function() {
                                return `<div class="graficoLabels">
                                    <div style="color: ${this.point.color}">${this.point.y}%</div>
                                    <div style="color: ${this.series.chart.userOptions.minhasVariaveis.themeDark ? '#C3C3C3' : ''}">
                                        ${ this.series.chart.userOptions.minhasVariaveis.valor[this.series.index][this.point.x].toLocaleString('pt-br',{style: 'currency', currency: 'BRL', }).slice(0, -3) }
                                    </div>
                                </div>`
                            },
                        },
                        states: {
                            inactive: {
                                 opacity: 1,
                            }
                        },
                        maxPointWidth: 100,
                    },
                    
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [
                    {
                        name: 'Comprometido',
                        data: [],
                        color: this.$vuetify.theme.themes.light.warning,
                        borderWidth: 0,
                        dataLabels: {
                            style: {
                                textOutline: this.$vuetify.theme.themes.light.warning,
                            },
                        },
                        cursor: 'pointer',
                        events: {
                            click: ({point}) => {
                                this.filtroDetalhado = {
                                    tipo: 'comprometido',
                                    mes: point.category.split('/')[1]+'-'+point.category.split('/')[0]
                                }
                                this.detalhado()
                            }
                        }
                    }, 
                    {
                        name: 'Oportunidade',
                        data: [],
                        color: this.$vuetify.theme.themes.light.info,
                        borderWidth: 0,
                        dataLabels: {
                            style: {
                                textOutline: this.$vuetify.theme.themes.light.info,
                            },
                        },
                        cursor: 'pointer',
                        events: {
                            click: ({point}) => {
                                this.filtroDetalhado = {
                                    tipo: 'oportunidade',
                                    mes: point.category.split('/')[1]+'-'+point.category.split('/')[0]
                                }
                                this.detalhado()
                            }
                        }
                    },
                ]
            },
            graficoAno: {
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                title: {
                    text: "Oportunidade Anual",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                chart: {
                    type: 'column',
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                yAxis: {
                    visible: false,
                },
                legend: {
                    itemStyle: {
                        color: this.$vuetify.theme.dark ? '#fff' : null,
                    },
                    itemHoverStyle: {
                        color: this.$vuetify.theme.dark ? '#9e9e9e' : null,
                    },
                },
                minhasVariaveis: {
                    themeDark: this.$vuetify.theme.dark,
                    valor: [[], []]
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            useHTML: true,
                            formatter: function() {
                                return `<div class="graficoLabels">
                                    <div style="color: ${this.point.color}">${this.point.y}%</div>
                                    <div style="color: ${this.series.chart.userOptions.minhasVariaveis.themeDark ? '#C3C3C3' : ''}">
                                        ${ this.series.chart.userOptions.minhasVariaveis.valor[this.series.index][this.point.x].toLocaleString('pt-br',{style: 'currency', currency: 'BRL', }).slice(0, -3) }
                                    </div>
                                </div>`
                            },
                        },
                        states: {
                            inactive: {
                                 opacity: 1,
                            }
                        },
                        maxPointWidth: 100,
                    },
                    
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [
                    {
                        name: 'Comprometido',
                        data: [],
                        color: this.$vuetify.theme.themes.light.warning,
                        borderWidth: 0,
                        dataLabels: {
                            style: {
                                textOutline: this.$vuetify.theme.themes.light.warning,
                            },
                        },
                        cursor: 'pointer',
                        events: {
                            click: ({point}) => {
                                this.filtroDetalhado = {
                                    tipo: 'comprometido',
                                    ano: point.category.split('/')[1]
                                }
                                this.detalhado()
                            }
                        }
                    }, 
                    {
                        name: 'Oportunidade',
                        data: [],
                        color: this.$vuetify.theme.themes.light.info,
                        borderWidth: 0,
                        dataLabels: {
                            style: {
                                textOutline: this.$vuetify.theme.themes.light.info,
                            },
                        },
                        cursor: 'pointer',
                        events: {
                            click: ({point}) => {
                                this.filtroDetalhado = {
                                    tipo: 'oportunidade',
                                    ano: point.category.split('/')[1]
                                }
                                this.detalhado()
                            }
                        }
                    },
                ]
            },
            camposAcordo: [],
            camposLoja: [],
            camposProduto: [],
            dialogoDetalhado: false,
            dadosDetalhado: [], 
            paginationDetalhado: {}, 
            totalDetalhado: null,
            filtroDetalhado: {},
        }
    },
    watch: {
        pagination: {
            handler () {
                if(this.modelosLista.length > 0) this.filtrar(false)
            },
            deep: true,
        },
        paginationDetalhado: {
            handler () {
                if(this.modelosLista.length > 0) this.detalhado(false)
            },
            deep: true,
        },
    },
    async created() {

        this.carregando = true

        this.menuFiltros = localStorage.getItem('menuFiltros') == 'true' ? true : false

        this.filtro.mesInicial = this.mesInicio
        this.filtro.mesFinal = this.mesAtual

        var data = {
            id_cargo: this.$store.getters.getCargo.id_cargo,
            gerencial: 'oportunidade',
        }

        var retorno = await this.$http().post(`/gerencial/modelos`, {data: data})

        this.modelosLista = retorno.data.modelosLista

        this.lista = retorno.data.lista

        for (const t of this.lista.filtro) {

            if(t.var_id == 'id_modelo') {

                this.filtro[t.var_id] = this.modelosLista[0].id_modelo
            }
            else {

                this.filtro[t.var_id] = []
            }
        }

        for (const c of retorno.data.camposLoja) {

            if(c.campo.substr(0, 3) == 'id_') {

                c.tabela = c.campo.substr(3)
                c.campo = 'nome'
            }

            this.headers.splice(0, 0, { text: c.nome, value: c.tabela+'_'+c.campo, align: 'center'},)
        }

        for (const c of retorno.data.camposAcordo) {

            this.headers.splice(0, 0, { text: c.nome, value: c.tabela+'_'+c.campo, align: 'center'},)
        }

        this.camposAcordo = retorno.data.camposAcordo
        this.camposLoja = retorno.data.camposLoja
        this.camposProduto = retorno.data.camposProduto

        this.carregando = false

        this.filtrar()
    },
    computed: {
        mesAtual() {

            return this.$moment().format('YYYY-MM')
        },
        mesInicio() {

            return this.$moment().subtract(2, 'months').format('YYYY-MM')
        },
    },
    methods: {
        miniFiltros () {

            localStorage.setItem('menuFiltros', !this.menuFiltros)
            this.menuFiltros = !this.menuFiltros
        },
        iconeSelecionarTudo(var_id, tabela) {

            if(this.filtro[var_id].length === this.lista[tabela].length) return 'mdi-close-box'
            if (this.filtro[var_id].length > 0 && !(this.filtro[var_id].length === this.lista[tabela].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudo (var_id, tabela) {

            this.carregando = true

            setTimeout(() => {

                if(this.filtro[var_id].length === this.lista[tabela].length) {

                    this.filtro[var_id] = []
                }
                else {

                    this.filtro[var_id] = this.lista[tabela].map(e => e.value)
                }
            }, 100)

            setTimeout(() => { this.carregando = false }, (this.lista[tabela].length > 100 ? this.lista[tabela].length : 110))
        },
        removeItemFiltro(item, filtro) {

            var index = filtro.indexOf(item.value)
            if (index >= 0) filtro.splice(index, 1)
        },
        async filtrar(fnGrafico=true) {

            if (this.modelosLista.length == 0) return

            if (!this.$refs.formFiltro.validate()) return

            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.pagination
            data.filtro = {...this.filtro}
            data.filtro.id_modelo = [this.filtro.id_modelo]
            data.modelos = this.modelosLista
            data.gerencial = 'oportunidade'
            data.fnLista = true
            data.fnGrafico = fnGrafico

            var response = await this.$http().post(`/gerencial/oportunidade`, {data: data})   
            
            this.dados = response.data.lista
            this.total = response.data.lista.length ? response.data.lista[0].linhas*1 : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            if(data.fnGrafico) {

                this.graficoMes.xAxis.categories = []
                this.graficoMes.series[0].data = []
                this.graficoMes.series[1].data = []
                this.graficoMes.minhasVariaveis.valor[0] = []
                this.graficoMes.minhasVariaveis.valor[1] = []

                this.graficoAno.xAxis.categories = []
                this.graficoAno.series[0].data = []
                this.graficoAno.series[1].data = []
                this.graficoAno.minhasVariaveis.valor[0] = []
                this.graficoAno.minhasVariaveis.valor[1] = []

                var maximo = 0

                for (const e of response.data.mes) {

                    this.graficoMes.xAxis.categories.push(e.mes)

                    this.graficoMes.series[0].data.push(e.comprometido_percentual*1)
                    this.graficoMes.series[1].data.push(e.oportunidade_percentual*1)

                    this.graficoMes.minhasVariaveis.valor[0].push(e.comprometido_valor*1)
                    this.graficoMes.minhasVariaveis.valor[1].push(e.oportunidade_valor*1)

                    let ano = e.mes.split('/')[1]

                    if (!this.graficoAno.xAxis.categories.includes(ano)) {

                        this.graficoAno.xAxis.categories.push(ano)
                    }

                    maximo = e.comprometido_percentual*1 > maximo ? e.comprometido_percentual*1 : maximo
                    maximo = e.oportunidade_percentual*1 > maximo ? e.oportunidade_percentual*1 : maximo
                }

                this.graficoMes.yAxis.max = maximo*1.2

                maximo = 0

                for (const ano of this.graficoAno.xAxis.categories) {

                    let comprometido_percentual = Math.round (
                        100 * response.data.mes.filter(e => e.mes.split('/')[1] == ano).reduce((a, e) => a + e.comprometido*1, 0) / 
                        response.data.mes.filter(e => e.mes.split('/')[1] == ano).reduce((a, e) => a + e.total*1, 0)
                    )

                    this.graficoAno.series[0].data.push(comprometido_percentual)
                    this.graficoAno.series[1].data.push(100 - comprometido_percentual)

                    this.graficoAno.minhasVariaveis.valor[0].push(response.data.mes.filter(e => e.mes.split('/')[1] == ano).reduce((a, e) => a + e.comprometido_valor*1, 0))
                    this.graficoAno.minhasVariaveis.valor[1].push(response.data.mes.filter(e => e.mes.split('/')[1] == ano).reduce((a, e) => a + e.oportunidade_valor*1, 0))

                    maximo = comprometido_percentual > maximo ? comprometido_percentual : maximo
                    maximo = (100 - comprometido_percentual) > maximo ? (100 - comprometido_percentual) : maximo
                }

                this.graficoAno.yAxis.max = maximo*1.2

                this.indicadoresTotal[0].valor = response.data.mes.reduce((a, e) => a + e.total_valor*1, 0)

                this.indicadoresTotal[1].valor = response.data.mes.reduce((a, e) => a + e.comprometido_valor*1, 0)
                this.indicadoresTotal[1].perc = Math.round (
                    100 * response.data.mes.reduce((a, e) => a + e.comprometido*1, 0) / 
                    response.data.mes.reduce((a, e) => a + e.total*1, 0)
                )

                this.indicadoresTotal[2].valor = response.data.mes.reduce((a, e) => a + e.oportunidade_valor*1, 0)
                this.indicadoresTotal[2].perc = 100 - this.indicadoresTotal[1].perc
            }

            this.carregando = false
        },
        async detalhado(fnPrimeiraBusca=true) {

            if (this.modelosLista.length == 0) return

            if(!this.$refs.formFiltro.validate()) return

            this.dialogoDetalhado = true

            this.carregando = true

            if (fnPrimeiraBusca) {
                
                this.paginationDetalhado = {...this.paginationLimpa}
                return
            }

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.paginationDetalhado
            data.filtro = {...this.filtro}
            data.filtro.id_modelo = [this.filtro.id_modelo]
            data.modelos = this.modelosLista
            data.gerencial = 'oportunidade'
            data.fnLista = true

            data.filtro.tipo = this.filtroDetalhado.tipo

            if (this.filtroDetalhado.mes) {

                data.filtro.mesInicial = this.filtroDetalhado.mes
                data.filtro.mesFinal = this.filtroDetalhado.mes 
            }

            if (this.filtroDetalhado.ano) {

                data.filtro.mesInicial = this.filtroDetalhado.ano + '-' + data.filtro.mesInicial.split('-')[1]
                data.filtro.mesFinal = this.filtroDetalhado.ano + '-' + data.filtro.mesFinal.split('-')[1] 
            }

            var response = await this.$http().post('/gerencial/oportunidadeDetalhado', {data: data})

            this.dadosDetalhado = response.data.lista

            this.totalDetalhado = response.data.lista[0] ? response.data.lista[0].linhas : 0

            if(this.totalDetalhado == 0 && this.paginationDetalhado.page > 1) {

                this.paginationDetalhado.page = 1
                this.detalhado()
            }

            this.carregando = false
        },
        limpar() {

            this.filtro = {
                mesInicial: this.mesInicio,
                mesFinal: this.mesAtual,
                id_modelo: this.filtro.id_modelo
            }  
            
            for (const t of this.lista.filtro) {

                if (t.var_id == 'id_modelo') continue

                this.filtro[t.var_id] = []
            }

            console.log(this.filtro)

            this.filtrar()
        },
        async exportar(fnDetalhado=false) {

            if (this.modelosLista.length == 0) return
           
            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.filtro = {...this.filtro}
            data.filtro.id_modelo = [this.filtro.id_modelo]
            data.modelos = this.modelosLista
            data.gerencial = 'oportunidade'
            data.fnLista = true

            if(fnDetalhado) {

                data.filtro.tipo = this.filtroDetalhado.tipo

                if (this.filtroDetalhado.mes) {

                    data.filtro.mesInicial = this.filtroDetalhado.mes
                    data.filtro.mesFinal = this.filtroDetalhado.mes 
                }

                if (this.filtroDetalhado.ano) {

                    data.filtro.mesInicial = this.filtroDetalhado.ano + '-' + data.filtro.mesInicial.split('-')[1]
                    data.filtro.mesFinal = this.filtroDetalhado.ano + '-' + data.filtro.mesFinal.split('-')[1] 
                }
            }

            var response = await this.$http().post(`/gerencial/exportarOportunidade`, {data: data})

            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        },
        mudaLayout() {

            this.layoutCompacto = !this.layoutCompacto 

            setTimeout(() => {

                this.$refs.grafico1.chart.reflow()
                this.$refs.grafico2.chart.reflow()
            }, 100)
        },
		getClassOportunidade(valor) {
			if (!valor) {
				return "";
			}
			if (valor * 1 > 80) {
				return "circulo vermelho";
			}
			if (valor * 1 > 50) {
				return "circulo amarelo";
			}
			return "circulo verde";
		},
     },
}

</script>

<style>

.circulo {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	display: inline-block;
	margin-right: 5px;
}
.circulo.verde {
	background: var(--v-success-base);
}
.circulo.amarelo {	
	background: var(--v-warning-base);
}
.circulo.vermelho {
	background: var(--v-error-base);
}

.graficoLabels {
	font-family: "Roboto", sans-serif !important;
	color: #666666;
    cursor: default;
    font-size: 11px;
    fill: #666666;
    font-weight: normal;
    text-align: center;
}

</style>
