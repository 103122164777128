<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">  
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <template v-slot:[`item.validacao`]="{ item }">
                    {{item.ultimo_nivel ? item.validacao : ''}}
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
            scrollable
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{tituloDialogo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="form"> 
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    :label="'ID '+tituloDialogo"
                                    v-model="obj.id_modelo"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    :label="tituloDialogo"
                                    v-model="obj.nome"
                                    :rules="regra.nome"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Ordem"
                                    v-model="obj.ordem"
                                    :rules="regra.ordem"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-select
                                    label="Ativo"
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    v-model="obj.ativo"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mb-2">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                <v-btn
                                    color="primary"
                                    class="pr-4"
                                    @click="novaTabela()"
                                    v-show="obj.id_modelo"
                                >
                                    <v-icon left>mdi-plus</v-icon> Adicionar Atributo
                                </v-btn> 
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="headerTabelas"
                            :items="tabelas"
                            :items-per-page="5"
                            :footer-props="{
                                'items-per-page-options': [5, 10, 15, 25, 50, 100]
                            }"
                            :options.sync="paginationTabelas" 
                            class="elevation-0"
                            v-show="obj.id_modelo"
                        >
                            <template v-slot:[`item.botao`]="{ item }"> 
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                    @click="removerConfirm(item, 'modelo_tabela')"
                                    title="Remover Atributo"
                                    v-if="item.tabela != 'acao'"
                                >
                                    mdi-trash-can
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoRemover"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{dialogoRemoverTitulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    {{dialogoRemoverTexto}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoRemover=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="error"
                        class="pr-4"
                        @click="remover(dialogoRemoverTipo)"
                    >
                        <v-icon left>mdi-close</v-icon> Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoTabela"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Atributo
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="formTabela">
                        <v-select
                            label="Atributo"
                            :items="tabelasDisponiveis"
                            v-model="modelo_tabela.tabela"
                            :rules="regra.tabela"
                            outlined
                            dense 
                        >
                        </v-select>
                        <v-text-field
                            outlined
                            dense 
                            label="Ordem"
                            v-model="modelo_tabela.ordem"
                            :rules="regra.ordem"
                        ></v-text-field> 
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoTabela=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="adicionarTabela()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Adicionar Atributo
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tabela: 'precoModelo',
            titulo: 'Modelos de Preços',
            tituloDialogo: 'Modelo de Preços',
            headers: [
                { text: 'ID Modelo', value: 'id_modelo', align: 'center', sortable: false },
                { text: 'Modelo de Preços', value: 'nome', align: 'center', sortable: false },
                { text: 'Ordem', value: 'ordem', align: 'center', sortable: false },
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            dados: [], 
            objNovo: {
                id_modelo: null,
                nome: null,
                ativo: 1,
                ordem: null,
            },
            obj: {},
            pagination: {},
            paginationTabelas: {},
            carregando: false,
            ativo: 1,
            dialogo: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                nome: [v => !!v || 'Campo obrigatório.'],
                tabela: [v => !!v || 'Campo obrigatório.'],  
                ordem: [v => !!v || 'Campo obrigatório.'],
                obrigatorio: [v => !!v || 'Campo obrigatório.'],
            }, 
            dialogoRemover: false,
            dialogoRemoverTitulo: null,
            dialogoRemoverTexto: null,
            dialogoRemoverTipo: null,
            headerTabelas: [
                { text: 'Atributo', value: 'nome', align: 'center', sortable: false },
                { text: 'Ordem', value: 'ordem', align: 'center', sortable: false },
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            tabelas: [],
            modelo_tabela: {
                id_modelo: null,
                tabela: null,
            },
            dialogoTabela: false,
            tabelasDisponiveis: [],
        }
    },
    watch: {
        ativo() {

            this.filtrar()
        },
    },
    async created() {

        this.filtrar()
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}
            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/lista`, {data: data})
            
            this.dados = response.data
            
            this.carregando = false
        },
        novo() {
           
            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogo = true
        }, 
        editar(item) {
           
            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogo = true
            
            this.filtrarTabelas(this.obj)
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }
            
            if(!this.$refs.form.validate()) return
            
            this.carregando = true
            
            var data = this.obj

            var response = await this.$http().post(`/${this.tabela}/salvar`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },  
        async removerConfirm(item, tipo) {

            if(tipo == 'modelo_tabela') {
                
                this.dialogoRemoverTitulo = 'Remover Atributo'
                this.dialogoRemoverTexto = `Deseja realmente remover o atributo '${item.nome}'?`
                this.dialogoRemoverTipo = tipo

                this.modelo_tabela = {...item}
            }

            this.dialogoRemover = true
        }, 
        async remover(tipo) {
            
            this.carregando = true

            var data = {
                tipo: tipo
            }

            if(tipo == 'modelo_tabela') {
                
                data.id_modelo = this.modelo_tabela.id_modelo
                data.tabela = this.modelo_tabela.tabela
            }

            var response = await this.$http().post(`/${this.tabela}/remover`, {data: data})  
            
            this.carregando = false

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 1000
                this.alertaTexto = response.data.msg 
                
                this.dialogoRemover = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            } 

            if(tipo == 'modelo_tabela') {
                
                this.filtrarTabelas(this.obj)
            }
        },
        async filtrarTabelas(item) {
            
            this.carregando = true

            var data = {id_modelo: item.id_modelo}

            var response = await this.$http().post(`/${this.tabela}/tabelas`, {data: data})

            this.tabelas = response.data.tabelas

            this.tabelasDisponiveis = response.data.tabelasDisponiveis.map(e => ({value: e.tabela, text: e.nome}))

            this.carregando = false
        },
        novaTabela() {
           
            this.modelo_tabela = {
                id_modelo: this.obj.id_modelo,
                tabela: null,
                ordem: null,
            }

            try {
                this.$refs.formTabela.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogoTabela = true
        },
        async adicionarTabela() {
            
            if(!this.$refs.formTabela.validate()) return
            
            this.carregando = true

            var data = this.modelo_tabela

            var response = await this.$http().post(`/${this.tabela}/adicionarTabela`, {data: data})  
            
            this.carregando = false

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 1000
                this.alertaTexto = response.data.msg 

                this.dialogoTabela = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            } 

            this.filtrarTabelas(this.obj)
        },
    },
}
</script>