<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Listagens / Exportações</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                    :disabled="tipo=='padrao'"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-select
                        label="Tipo"
                        :items="tipos"
                        v-model="tipo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        v-if="item.tipo != 'padrao'"
                        small
                        class="ml-4 mr-2"
                        color="primary"
                        @click="remover(item)"
                        title="Excluir"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Campo
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="form"> 
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2" v-show="obj.botao=='novo'">
                                <v-select
                                    label="Campos"
                                    v-model="obj.campoNovo"
                                    :items="disponiveis.filter(e => e.tipo == tipo)"
                                    outlined
                                    dense
                                    v-on:change="changeCampoNovo"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Campo"
                                    v-model="obj.campo"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Tabela"
                                    v-model="obj.tabela"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Tipo"
                                    v-model="obj.tipo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-text-field
                                    v-show="obj.tipo != 'padrao'"
                                    outlined
                                    dense 
                                    label="Ordem"
                                    v-model="obj.ordem"
                                    :rules="regra.obrigatorio"
                                    type="number"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-text-field
                                    v-show="obj.tipo != 'padrao'"
                                    outlined
                                    dense 
                                    label="Nome"
                                    v-model="obj.nome"
                                    :rules="regra.obrigatorio"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" class="px-2">
                                <v-textarea
                                    v-show="obj.tipo == 'padrao'"
                                    outlined
                                    dense 
                                    label="Valor"
                                    v-model="obj.valor"
                                    rows="6"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" class="px-2">
                                <v-switch
                                    label="Campo padrão"
                                    v-model="obj.fn_padrao"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            headers: [
                { text: 'Campo', value: 'campo', align: 'center'},
                { text: 'Nome', value: 'nome', align: 'center'},
                { text: 'Tipo', value: 'tipo', align: 'center'},
                { text: 'Ordem', value: 'ordem', align: 'center'},
                { text: 'Tabela', value: 'tabela', align: 'center'},
                { text: 'Campo padrão', value: 'fn_padrao', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            dados: [],
            disponiveis: [],
            obj: {
                campo: null,
                nome: null,
                tipo: null,
                ordem: null,
                tabela: null,
                valor: null,
                fn_padrao: false,
                campoNovo: null,
                botao: null,
            },
            tipos: [
                'campos_acordo_listagem',
                'campos_acordo_gerencial_auditoria',
                'campos_produto_gerencial_auditoria',
                'campos_loja_gerencial_auditoria',
                'campos_loja_gerencial_auditoria_ppt',
                'campos_acordo_gerencial_oportunidade',
                'campos_loja_gerencial_oportunidade',
                'campos_acordo_gerencial_investimento',
                'campos_loja_gerencial_investimento',
            ],
            tipo: 'campos_acordo_listagem',
            pagination: {},
            carregando: false,
            busca: null,
            dialogo: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: {
                obrigatorio: [
                    v => !!v || 'Campo obrigatório.',
                ],
            },
        }
    },
    watch: {
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }

            }, 1000)
        },
        tipo() {

            this.filtrar()
        },
    },
    async created() {

        this.filtrar()
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.busca = this.busca

            data.filtro = {}
            data.filtro.tipo = [this.tipo]

            var response = await this.$http().post(`/lista/lista`, {data: data})
            
            this.dados = response.data.lista
            this.disponiveis = response.data.disponiveis

            this.carregando = false
        },
        novo() {
           
            this.obj = {
                campo: null,
                nome: null,
                tipo: null,
                ordem: null,
                tabela: null,
                valor: null,
                fn_padrao: false,
                campoNovo: null,
                botao: 'novo',
            }

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        },
        editar(item) {
           
            this.obj = {...item}

            this.obj.campoNovo = null
            this.obj.botao = 'editar'

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  
            
            this.dialogo = true
        },
        changeCampoNovo() {
           
            let obj = this.disponiveis.find(e => e.value == this.obj.campoNovo)

            this.obj.campo = obj.campo
            this.obj.nome = obj.nome
            this.obj.tipo = obj.tipo
            this.obj.tabela = obj.tabela
        },
        async salvar() {

            if(!this.$refs.form.validate()) return

            this.carregando = true

            var data = this.obj

            var response = await this.$http().post(`/lista/salvar`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async remover(item) {

            this.carregando = true

            var data = item

            var response = await this.$http().post(`/lista/remover`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
    },
}
</script>