<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"   
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"   
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <template v-slot:[`item.teste`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        :color=" item.teste == 'ok' ? 'success' : ( item.teste == 'erro' ? 'error' : 'primary' ) "
                        @click="teste(item)"
                        title="Testar Servidor"
                    >
                        mdi-server-network
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{tituloDialogo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="form">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    :label="'ID '+tituloDialogo"
                                    v-model="obj['id_'+tabela]"
                                ></v-text-field> 
                            </v-col>
                            <v-col v-for="(item, index) in ads" :key="index" cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    :label="item.nome"
                                    v-model="obj[item.ad]"
                                    :rules="regra[item.ad]"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Ativo"
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    v-model="obj.ativo"
                                    outlined
                                    dense 
                                >
                                </v-select> 
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>        
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tabela: 'servidor',
            titulo: 'Servidores',
            tituloDialogo: 'Servidor',
            nomeCampo: null,
            headers: [
                { text: 'ID Servidor', value: 'id_servidor', align: 'center'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
                { text: '', value: 'teste', align: 'center', sortable: false },
            ],
            ads: [],
            dados: [], 
            obj: {
                id_servidor: null,
                nome: null,
                ativo: 1,
                ad_1: null,
                ad_2: null,
                ad_3: null,
                ad_4: null,
                ad_5: null,
                ad_6: null,
                ad_7: null,
                ad_8: null,
                ad_9: null,
                ad_10: null,
            },
            pagination: {}, 
            total: null,
            carregando: false, 
            ativo: 1,
            busca: null, 
            dialogo: false,
            colunasObrigatorias: '',
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: {},
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }

            }, 1000)
        },
        ativo() {

            this.filtrar()
        },
    },
    async created() {
        
        var data = {tabela: this.tabela}
        
        var tabela = await this.$http().post(`/configuracao/tabelaDados`, {data: data}) 

        this.titulo = tabela.data[0].plural
        this.tituloDialogo = tabela.data[0].nome
        this.headers[0].text = 'ID ' + tabela.data[0].nome
        
        var response = await this.$http().post(`/configuracao/tabela`, {data: data}) 

        for (const [i, e] of response.data.entries()) {

            if(i == 0) this.nomeCampo = e.nome

            this.ads.push(e)
            
            let ad = {
                text: e.nome, 
                value: e.ad.substr(0, 3) == 'id_' ? e.ad.substr(3) : e.ad, 
                align: 'center'}
            
            this.headers.splice((this.headers.length-2), 0, ad)

            this.regra[e.ad] = [v => !e.obrigatorio || !!v && ( typeof v == 'string' ? !!v.trim() : true ) || 'Campo obrigatório.']

            this.colunasObrigatorias += e.obrigatorio ? (this.colunasObrigatorias ? ', '+e.nome : e.nome) : ''
        }
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination
            data.busca = this.busca

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/lista`, {data: data})   
            
            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        novo() {
           
            this.obj = {
                id_servidor: null,
                nome: null,
                ativo: 1,
                ad_1: null,
                ad_2: null,
                ad_3: null,
                ad_4: null,
                ad_5: null,
                ad_6: null,
                ad_7: null,
                ad_8: null,
                ad_9: null,
                ad_10: null,
            }

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  
                        
            this.dialogo = true
        }, 
        editar(item) {
           
            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  
            
            this.dialogo = true
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }
            
            if(!this.$refs.form.validate()) return
            
            this.carregando = true
            
            var data = this.obj

            delete data.total

            var response = await this.$http().post(`/${this.tabela}/salvar`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false  
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async teste(item) {
                        
            this.carregando = true
            
            var data = item

            var response = await this.$http().post(`/${this.tabela}/teste`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg 

                item.teste = 'ok'
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg

                item.teste = 'erro'
            }  
        }, 
    },
}
</script>