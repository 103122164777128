<template>
    <v-container fluid>
        <v-toolbar-title>Home</v-toolbar-title>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    },
}
</script>