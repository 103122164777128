import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueMask from 'v-mask'
import VuetifyMoney from "vuetify-money"
import VImageInput from 'vuetify-image-input/a-la-carte'
import JsonCSV from 'vue-json-csv'
import {http} from './config/http'
import VuePapaParse from 'vue-papa-parse'
import moment from 'moment'
import pptxgen from "pptxgenjs"
import helpers from './plugins/helpers'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import loadMap from 'highcharts/modules/map'
import exportingInit from 'highcharts/modules/exporting'
import dataModule from "highcharts/modules/export-data"

loadMap(Highcharts)
exportingInit(Highcharts)
dataModule(Highcharts)

Highcharts.setOptions({
    lang: {
        contextButtonTitle: 'Menu',
        downloadJPEG: 'Exportar JPG',
        downloadPDF: 'Exportar PDF',
        downloadPNG: 'Exportar PNG',
        downloadSVG: 'Exportar SVG',
        viewFullscreen: 'Tela Inteira',
        exitFullscreen: 'Sair da Tela Inteira',
        printChart: 'Imprimir',
        decimalPoint: ',,',
    },
})

Vue.use(HighchartsVue)

Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$helpers = helpers

moment.locale('pt-br')
Vue.prototype.$moment =  moment
Vue.prototype.$pptxgen =  pptxgen

Vue.use(VueMask)
Vue.use(VuetifyMoney)
Vue.component('downloadCsv', JsonCSV)
Vue.component(VImageInput.name, VImageInput)
Vue.use(VuePapaParse)

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')


