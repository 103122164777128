<template>
    <v-app style="background: #fff">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap" rel="stylesheet">
        <v-main>

            <v-container class="containerLogin">
                <v-row class="conteudo ipdvColorGradient" no-gutters>

                    <Slider />

                    <v-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <v-card
                            color="transparent"
                            :class="{
                                'rounded-16': screen.xs || screen.sm,
                                'rounded-e-16': !screen.xs && !screen.sm,
                                'pa-32': screen.xs,
                                'pa-64': !screen.xs,
                            }"
                            class="flat"
                            height="100%"
                        >
                            <div
                                style="min-height: 90px"
                                :class="{
                                    'w-100 justify-center align-center d-flex':
                                    screen.xs || screen.sm,
                                    'd-none': !screen.xs && !screen.sm,
                                }"
                            >
                                <v-img
                                    src="https://s3.amazonaws.com/prateleira.ipdvonline.com.br/img/logo_default_header_branco.png"
                                    max-width="113px"
                                    max-height="51px"
                                />
                            </div>

                            <v-card
                                color="#fff"
                                class="rounded-12 justify-center d-flex flex-column align-center flat"
                                :height="screen.xs || screen.sm ? '80%' : '100%'"
                                >
                                    <v-card
                                        color="transparent"
                                        class="rounded-0 pa-24 flat"
                                        max-width="349px"
                                        min-width="200px"
                                        width="100%"
                                    >
                                        <div>
                                            <v-form ref="form" @submit.prevent="salvarSenha">
                                                <v-row v-show="mostrar">
                                                    <v-col cols="12" class="custom-text-h3 ma-0 pb-0">
                                                        Cadastrar nova senha
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="custom-text-ps ajuste-alerta"
                                                    >
                                                        Use no mínimo 8 caracteres, combinando letras e números.
                                                    </v-col>

                                                    <v-col cols="12" class="mb-0 pb-0">
                                                        <v-text-field
                                                            class="bordaForm"
                                                            outlined
                                                            dense
                                                            label="Nova senha"
                                                            v-model="novaSenha"
                                                            :rules="novaSenhaRegra"
                                                            required
                                                            type="password"
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" class="mb-0 pb-0">
                                                        <v-text-field
                                                        class="bordaForm"
                                                        outlined
                                                        dense
                                                        label="Confirmar senha"
                                                        v-model="confirmarSenha"
                                                        :rules="confirmarSenhaRegra"
                                                        required
                                                        type="password"
                                                    ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" class="mb-0 pb-0">
                                                        <v-btn
                                                            color="primary"
                                                            :variant="enabledBtnAcessar ? 'flat' : 'tonal'"
                                                            :disabled="!enabledBtnAcessar"
                                                            block
                                                            type="submit"
                                                            class="rounded-12 flat btn-acessar"
                                                            ref="botao"
                                                        >Atualizar Senha</v-btn>
                                                    </v-col>

                                                    <v-col style="padding-bottom: 45px;">
                                                        <div class="custom-text-ps">Em caso de duvidas contate o suporte através do e-mail: 
                                                            <a id="myAnchor" class="link" href="mailto:ifinancesuporte@ipdvonline.com.br">ifinancesuporte@ipdvonline.com.br</a>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-form>

                                            <v-row v-show="erro">
                                                <v-col 
                                                    cols="7"
                                                    md="8"
                                                    lg="8"
                                                    xl="8"
                                                    xxl="8"
                                                    :class="{
                                                        'custom-text-h3': !screen.xs && !screen.sm,
                                                        'custom-text-h5': screen.xs || screen.sm,
                                                        'pa-b-32 pr-1': true,
                                                    }"
                                                    style="margin-top: 15px;"
                                                >
                                                    Bem-vindo
                                                </v-col>
                                                <v-col
                                                    cols="5"
                                                    md="4"
                                                    lg="4"
                                                    xl="4"
                                                    xxl="4"
                                                    class="pt-2 pl-1"
                                                >
                                                    <v-img src="@/assets/images/login/logo.png" height="80" contain></v-img>
                                                </v-col>
                                                <br>
                                            
                                            <div class="text-body-2  error--text">
                                                <b>{{erroMsg}}</b>
                                            </div>
    
                                            <br>
    
                                            <div class="text-body-2" v-if="novaSolicitacao">
                                                {{erroMsgDesc}}
                                                <br>
                                                Para fazer uma nova solicitação, <a class="link" @click="recuperarSenha()">clique aqui</a>. Obrigado.
                                            </div>
                    
                                            <br>
                                            
                                            <div class="text-body-2">Em caso de duvidas contate o suporte através do e-mail: <a class="link" href="mailto:ifinancesuporte@ipdvonline.com.br">ifinancesuporte@ipdvonline.com.br</a> </div>

                                            </v-row>
                                        </div>

                                        <v-overlay :value="carregando" :z-index="300">
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                        </v-overlay>

                                        <v-snackbar
                                            v-model="alertaGeral"
                                            :color="alertaColor"
                                            :timeout="alertaTimeout"
                                            centered
                                            style="z-index: 400"
                                        >
                                            <v-alert :type="alertaColor" v-html="alertaTexto"></v-alert>
                                            <v-btn
                                                dark
                                                text
                                                class="float-right"
                                                @click="alertaGeral=false"
                                            >
                                                OK, ENTENDI
                                            </v-btn>
                                        </v-snackbar>
                                    </v-card>
                                </v-card>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row no-gutters="">
                        <Footer
                            :app="false"
                            clean
                            colorfooter="header"
                            marginTop="4px"
                        />
                    </v-row>
            </v-container>  
      </v-main>    
  </v-app>
</template>

<script>
import Slider from '../components/containment/Slider.vue'
import Footer from "../components/containment/Footer.vue";

export default {
    data() {
        return {
            logo: null,
            mostrar: false,
            erro: false,
            erroMsg: null,
            erroMsgDesc: null,
            novaSolicitacao: true,
            carregando: false,
            novaSenha: null,
            novaSenhaRegra: [
                v => !!v || 'O campo nova senha é obrigatório.',
                v => (v && v.length >= 8) || 'A senha deve ter no minimo 8 caracteres.',
                v => !v || /[0-9]/.test(v) || 'A senha deve conter pelo menos um número.',
                v => !v || /[a-z,A-Z]/.test(v) || 'A senha deve conter pelo menos uma letra.',
            ],
            confirmarSenha: null,
            confirmarSenhaRegra: [
                v => !!v || 'O campo confirmar senha é obrigatório.',
                v => (v && v == this.novaSenha) || 'As senhas não conferem.',
            ],
            alertaGeral: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            screen: this.$vuetify.breakpoint,
            enabledBtnAcessar: false,
        }
    },
    created() {
        this.$vuetify.theme.dark = false
    },
    computed: {
    },
    watch: {
        novaSenha: function () {
            this.enabledBtnAcessar = (this.novaSenha ?? "") != "" && (this.confirmarSenha ?? "") != "";
        },
        confirmarSenha: function () {
            this.enabledBtnAcessar = (this.novaSenha ?? "") != "" && (this.confirmarSenha ?? "") != "";
        },
    },
    methods: {
        async salvarSenha() {

            if(!this.$refs.form.validate()) return

            this.carregando = true

            var data = {
                cliente: this.$route.query.cliente,
                chave: this.$route.query.chave,
                senha: this.novaSenha,
            }

            var retorno = await this.$http().post(`/login/salvarSenha`, {data: data})

            this.carregando = false

            if(retorno.data.sucesso){

                this.alertaGeral = true
                this.alertaColor = 'success'
                this.alertaTimeout = -1
                this.alertaTexto = retorno.data.msg

                setTimeout(() => {
                    this.$store.dispatch('login', [retorno.data.login, data.senha, data.cliente])
                }, 3000);
            }
            else{

                this.mostrar= false
                this.erro= true
                this.erroMsg= retorno.data.msg
                this.erroMsgDesc= retorno.data.msgDesc
            }

        },
        recuperarSenha() {

            this.$router.push({name: 'login', params: { cliente: this.$route.query.cliente, recuperarSenha: true }})

        },
        async alterarSenha() {

            this.carregando = true

            this.novaSenha = '1'
            this.confirmarSenha = '2'

            setTimeout(async () => {

                this.novaSenha = null
                this.confirmarSenha = null

                try {
                    this.$refs.form.resetValidation()
                } catch (error) {
                    //console.log(error)
                }

                setTimeout(async () => {

                    var retorno = await this.$http().post('/login/cliente', {data: {nome: this.$route.query.cliente}})

                    var cliente = retorno.data[0]

                    if (!cliente) {

                        document.title = 'GOP - Cliente não encontrado.'
                        this.logo = 'https://ifinance.s3.us-east-1.amazonaws.com/adm/production/logo_login/ipdv.png'
                        this.novaSolicitacao = false
                    }
                    else {

                        document.title = cliente.titulo
                        this.$vuetify.theme.themes.light.primary = cliente.cor
                        this.$vuetify.theme.themes.light.fundo = cliente.cor_fundo
                        document.getElementById("favicon").href = cliente.logo_icone
                        this.logo = cliente.logo_login
                    }
                    
                    var data = {
                        cliente: this.$route.query.cliente,
                        chave: this.$route.query.chave,
                    }

                    retorno = await this.$http().post(`/login/validaChave`, {data: data})

                    this.carregando = false

                    if(retorno.data.sucesso){

                        this.mostrar = true
                        this.erro= false
                    }
                    else{

                        this.mostrar= false
                        this.erro= true
                        this.erroMsg= retorno.data.msg
                        this.erroMsgDesc= retorno.data.msgDesc
                    }

                }, 500)

            }, 1000)
        },
    },  
    mounted() {

        setTimeout(() => {

            this.alterarSenha()

        }, 1000)
    },
    components: {
        Slider,
        Footer,
    },
}
</script>

<style scoped>
    * {
        scrollbar-width: thin;
        scrollbar-color: 
            color-mix(in srgb, rgb(53,89,145), #ffffff 45%) rgb(255,255,255);
    }
    html {
        overflow: scroll;
    }
    body {
        font-family: Ubuntu !important;
        font-weight: 400;
        line-height: 22px;
        font-size: 15px;
    }
    button, input, optgroup, select, textarea {
        font: inherit;
    }
    .v-application {
        font-family: Ubuntu !important;
        background-color: rgb(246, 246, 250) !important;
    }

    .v-btn.v-size--default.btn-acessar {
        font-size: 14px !important;
    }

    .v-text-field.v-text-field--enclosed .v-text-field__details {
        margin-bottom: 0px !important;
    }

    .conteudo {
        height: calc((100% - 0px) - 54px) !important;
    }
    .pa-24 {
        padding: 24px;
    }
    .pa-32 {
        padding: 32px;
    }

    .pa-b-32 {
        padding-bottom: 32px !important;
    }


    .pa-64 {
        padding: 64px;
    }
    .containerLogin {
        height: 100%;
        width: 100%;
        max-width: 1440px;
        padding: 32px;
    }

    .ipdvColorGradient {
        background: linear-gradient(
            to right,
            #053594 50%,
            #400597,
            #400597
        ) !important;
        border-radius: 16px !important;
    }

    .flat {
        box-shadow: none !important;
    }

    .rounded-12 {
        border-radius: 12px !important;
    }

    .rounded-16 {
        border-radius: 16px !important;
    }
    .rounded-e-16 {
        border-radius: 0 16px 16px 0 !important;
    }

    .btn-acessar {
        letter-spacing: 1.4px;
        font-weight: 500;
    }

    .btn-acessar > span  {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }
    
    .borda {
        border-radius: 14px !important;
    }
    .bordaForm {
        border-radius: 12px !important;
    }
    .bordaForm >>> input {
        border-radius: 12px !important;
        max-height: 40px;
        max-width: 130%;
        margin: 0px -12px;
    }
    .bordaBotao {
        padding: 0px 24px;
    }
    .bordaBotao button {
        border-radius: 12px !important;
        height: 41px !important;
    }
    .custom-text-h3 {
        font-weight: 500 !important;
        line-height: 36px !important;
        font-size: 26px !important;
    }
    .custom-text-h5 {
        font-weight: 500 !important;
        line-height: 24px !important;
        font-size: 18px !important;
    }
    .custom-text-ps {
        font-weight: 400 !important;
        line-height: 20px !important;
        font-size: 13px !important;
        max-height: 24px;
        letter-spacing: 1.5px;
        margin-top: -2px;
        color: #121212 !important;
    }
    .ajuste-alerta {
        padding-bottom: 40px;
    }
    .login-footer {
        margin-bottom: 15px;
    }
    .row + .row {
        margin-top: 0px !important;
    }
    @media (min-width: 1904px) {
        .col-xl-12 {
            flex: inherit !important;
        }
        .card-flex {
            flex: 0 0 100% !important;
        }
    }

    .v-text-field >>> input {
        padding: 10px 16px !important;
    }
    .v-text-field >>> label {
        padding-left: 4px !important;
    }
    .v-text-field--outlined >>> fieldset {
        z-index: 1000;
    }
    .autofill-fix >>> label {
        transform: translateY(-16px) scale(.75) !important;
    }
    .autofill-fix >>> input {
        font: unset !important;
    }
    .autofill-fix >>> fieldset {
        padding-left: 8px !important;
    }
    .autofill-fix >>> fieldset legend {
        width: 37px !important;
    }

    .link{
        color: #8c8c8c;
        text-decoration: underline;
        font-size: 14px;
        font-family: Arial, Helvetica, "Liberation Sans", Roboto, sans-serif;
        line-height: 1.5;
    }
    .ubuntu-light {
        font-family: "Ubuntu", sans-serif;
        font-weight: 300;
        font-style: normal;
    }

    .ubuntu-regular {
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .ubuntu-medium {
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .ubuntu-bold {
        font-family: "Ubuntu", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .ubuntu-light-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 300;
        font-style: italic;
    }

    .ubuntu-regular-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-style: italic;
    }

    .ubuntu-medium-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-style: italic;
    }

    .ubuntu-bold-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 700;
        font-style: italic;
    }

    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: transparent !important;
    }

</style>
