<template>
    <v-app>   
        <v-app-bar 
            clipped-left 
            clipped-right 
            app 
            elevation="1"
        >
            <v-app-bar-nav-icon
                @click.stop="miniMenu"
            ></v-app-bar-nav-icon>
            <v-img :src="logo" height="100%" max-width="110" contain @click="rotaHome"></v-img>  
            <v-spacer></v-spacer>
            <v-menu
                v-if="notificacao.icone"
                offset-y 
                :close-on-content-click="false"
            >
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn 
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                                icon
                                class="mr-4"
                                @click="buscaNotificacoes()"
                            >
                                <v-badge
                                    color="error"
                                    :content="notificacao.dados.filter(e => !e['lido_'+e.tipo]).length"
                                    :value="notificacao.dados.filter(e => !e['lido_'+e.tipo]).length"
                                    overlap
                                >
                                    <v-icon>mdi-bell-outline</v-icon>
                                </v-badge>
                            </v-btn>
                        </template>
                        <span>Notificações</span>
                    </v-tooltip>
                </template>
                <v-card 
                    width="600"
                    class="mx-auto" 
                    tile
                >
                    <v-list>
                        <v-list-item> 
                            <v-list-item-content>
                                <v-list-item-title class="text-h5">Notificações</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                                <v-list-item-subtitle 
                                    @click="mostrarTudo()"
                                    style="cursor: pointer;"
                                    align="end"
                                >Mostrar apenas itens não lidos</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-switch
                                    label=""
                                    @click="mostrarTudo()"
                                    v-model="notificacao.mostrarFake"
                                    style="position: relative; top: 7px;"
                                ></v-switch>
                            </v-list-item-icon>
                        </v-list-item>
                        <v-divider class="mx-4"></v-divider>
                        <v-list-item
                            v-show="notificacao.dados.filter(e => !e['lido_'+e.tipo]).length > 0"
                        > 
                            <v-list-item-content>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-list-item-subtitle
                                    style="cursor: pointer; text-decoration: underline;"
                                    @click="tudoLido()"
                                >Marcar tudo como lido</v-list-item-subtitle>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                    <v-list height="600" class="overflow-auto">
                        <v-list-item
                            v-show="notificacao.dados.filter(e => !e['lido_'+e.tipo]).length == 0 && notificacao.mostrar"
                        > 
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Nenhuma notificação não lida</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-row no-gutters 
                            v-for="(item, index) in notificacao.dados" 
                            :key="index"
                            v-show=" !item['lido_'+item.tipo] || !notificacao.mostrar "
                        >
                            <v-col cols="11"> 
                                <v-card 
                                    class="mx-4 mb-4"
                                    elevation="1"
                                >
                                    <v-card-title class="subtitle-1 primary white--text">
                                        {{ item.tipo == 'sla' ? 'Acordo com SLA vencida:' : 'Acordo atualizado:' }} {{item.id_acordo}}
                                    </v-card-title>
                                    <v-card-text class="mt-2">
                                        <v-row no-gutters>
                                            <v-col cols="12"> 
                                                Status: {{item.status}}
                                            </v-col>
                                            <v-col cols="12"> 
                                                Data de atualização: {{item.data}}
                                            </v-col>
                                            <v-col cols="12"> 
                                                Bandeira: {{item.bandeira}}
                                            </v-col>
                                            <v-col cols="12"> 
                                                CNPJ: {{item.cnpj}}
                                            </v-col>
                                            <v-col cols="12"> 
                                                Razão Social: {{item.razao_social}}
                                            </v-col>
                                            <v-col cols="12"> 
                                                Valor do acordo: {{item.investimento}}
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div 
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-switch
                                                label=""
                                                v-model="item['lido_'+item.tipo]"
                                                @click="alteraLido(item)"
                                            ></v-switch>
                                        </div>
                                    </template>
                                    <span style="white-space: nowrap;">{{ item['lido_'+item.tipo] ? 'Marcar como não lido' : 'Marcar como lido' }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-list>
                </v-card>
            </v-menu>
            <v-menu offset-y>
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-avatar 
                                color="primary"
                                size="32"
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                            >
                                <span class="white--text headline" style="font-size: 15px !important;">{{nome.substr(0, 2).toUpperCase()}}</span>
                            </v-avatar>
                        </template>
                        <span style="white-space: nowrap;">Meu perfil</span>
                    </v-tooltip>
                </template>
                <v-card width="300">
                    <v-list dense nav>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{nome}}</v-list-item-title>
                                <v-list-item-subtitle>{{login}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list dense nav>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Cargo</v-list-item-title>
                                <v-list-item-subtitle>{{cargo}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list dense nav v-if="trocaCargo">
                        <v-list-item link @click="dialogoCargo = true">
                            <v-list-item-icon>
                                <v-icon>mdi-account-convert</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Trocar Cargo</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider v-if="trocaCargo"></v-divider>
                    <v-list dense nav>
                        <v-list-item link @click="rotaPerfil">
                            <v-list-item-icon>
                                <v-icon>mdi-account-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Perfil do Usuário</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list dense nav>
                        <v-list-item link @click="changeTemaEscuro()">
                            <v-list-item-icon>
                                <v-icon>mdi-palette</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Tema Escuro</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-switch
                                    label=""
                                    v-model="temaEscuroFake"
                                    style="position: relative; top: -8px;"
                                ></v-switch>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list dense nav>
                        <v-list-item link @click="logout()">
                            <v-list-item-icon>
                                <v-icon>mdi-logout</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Sair da sessão</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer 
            clipped 
            permanent 
            app
            :expand-on-hover="mini"
            width="300"
        >
           <v-list dense nav>
                <template v-for="(item, index) in itens">
                    <v-list-item
                        v-if="item.rota"
                        :key="index"
                        @click="rotaMenu(item)"
                        link
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group
                        v-if="item.itens"
                        :key="index"
                        v-model="item.active"
                        :prepend-icon="item.icon"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item
                            v-for="subItem in item.itens"
                            :key="subItem.title"
                            @click="rotaMenu(subItem)"
                        >
                             <v-list-item-icon>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main class="fundo" :class="calssMenu">
            <router-view/>
        </v-main>

        <v-footer app class="justify-center">
            <v-img src="@/assets/images/login/logo_barra.png" height="24" max-width="110" contain></v-img> IPDV © {{ new Date().getFullYear() }} | Todos os direitos reservados | Política de Privacidade
        </v-footer>

        <v-dialog
            v-model="dialogoCargo"
            width="300"
            persistent
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Selecione um Cargo
                </v-card-title>
                    <div v-for="(c, i) in cargos" :key="i">
                        <v-list dense nav>
                            <v-list-item link @click="setCargo(c)">
                                <v-list-item-icon>
                                    <v-icon>mdi-card-account-details</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{c.cargo}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                    </div>
            </v-card>
        </v-dialog>

    </v-app>
</template>

<script>
export default {
    data () {
        return {
            logo: null,
            menu: [],
            mini: null,
            temaEscuro: false,
            temaEscuroFake: false,
            trocaCargo: false,
            dialogoCargo: false,
            cargos: [],
            calssMenu: 'menu-aberto',
            notificacao: {
                icone: false,
                mostrar: true,
                mostrarFake: true,
                dados: [],
                tempo: new Date(),
            }
        }
    },
    async created() {

        if(this.$store.getters.getLogado) {

            this.cargos = this.$store.getters.getUsuario.cargos
        }
        else {

            if (sessionStorage.getItem('cliente')) {

                this.$router.push({name: 'login', params: { cliente: sessionStorage.getItem('cliente') }})
                return
            }

            if (localStorage.getItem('cliente')) {

                this.$router.push({name: 'login', params: { cliente: localStorage.getItem('cliente') }})
                return
            }

            return
        }

        if(this.$store.getters.getLogado && this.cargos.length == 1) {

            this.$store.dispatch('setCargo', [this.cargos[0]]) 
        }

        if(this.$store.getters.getLogado && this.cargos.length > 1) {

            this.trocaCargo = true
            this.dialogoCargo = true
        }

        var response = await this.$http().post('/login/cliente', {data: {nome: this.$store.getters.getCliente}})

        this.logo = response.data[0].logo_interno

        if (this.$store.getters.getCliente != 'adm') {

            response = await this.$http().post('/status/notificacoes', {data: {}})

            this.notificacao.icone = response.data.length ? true : false
        }

        response = await this.$http().post('/usuario/usuarioMenu', {data: {}})

        this.menu = response.data.length ? response.data : []

        this.buscaNotificacoes()

        this.loopNotificacoes()
    },
    mounted() {

        this.temaEscuro =  localStorage.getItem('temaEscuro') == 'true' ? true : false
        this.temaEscuroFake = this.temaEscuro
        this.$vuetify.theme.dark = this.temaEscuro

        this.notificacao.mostrar =  localStorage.getItem('notificacaoMostrar') == 'true' ? true : false
        this.notificacao.mostrarFake = this.notificacao.mostrar

        if(this.$route.name == 'main') {

            this.$router.push({name: "home"})
        }

        this.mini = localStorage.getItem('miniMenu') == 'true' ? true : false
        this.calssMenu = this.mini ? 'menu-fechado' : 'menu-aberto'
    },
    computed: {
        login() {
            return this.$store.getters.getLogado ? this.$store.getters.getUsuario.login : ''
        },
        nome() {
            return this.$store.getters.getLogado ? this.$store.getters.getUsuario.nome : ''
        },
        cargo() {
            return this.$store.getters.getLogado ? this.$store.getters.getCargo.cargo : ''
        },
        itens() {

            var itens = []

            for (const pai of this.menu.filter(p => p.pai == 0)) {

                if(!pai.cargos.includes(this.$store.getters.getCargo.id_cargo)) continue

                let itemPai = {
                    title: pai.nome,
                    icon: pai.icone,
                }

                if(pai.caminho) {

                    itemPai.rota = pai.caminho
                }
                else {

                    itemPai.itens = []
                }

                for (const filho of this.menu.filter(f => f.pai == pai.id_menu && f.visivel)) {

                    if(!filho.cargos.includes(this.$store.getters.getCargo.id_cargo)) continue

                    let itemFilho = {
                        title: filho.nome,
                        icon: filho.icone,
                        rota: filho.caminho,
                        id_menu: filho.id_menu,
                    }

                    itemPai.itens.push(itemFilho)
                }

                itens.push(itemPai)
            }

            return itens
        },
    },
    methods: {
        miniMenu () {

            this.mini = !this.mini
            localStorage.setItem('miniMenu', this.mini)
            this.calssMenu = this.mini ? 'menu-fechado' : 'menu-aberto'
        },
        rotaMenu (item) {

            if(this.$route.path != '/'+item.rota+'/'+(item.id_menu - 10000) && item.rota == 'preco') {

                this.$router.push({name: item.rota, params: { id: (item.id_menu - 10000) }})
            }

            if(this.$route.path != '/'+item.rota+'/'+(item.id_menu - 20000) && item.rota == 'inventario') {

                this.$router.push({name: item.rota, params: { id: (item.id_menu - 20000) }})
            }

            if(this.$route.name != item.rota && !['preco','inventario'].includes(item.rota)){
                    
                if (item.rota.split('/').length == 2) {

                    if (this.$route.path != '/'+item.rota) {

                        this.$router.push({name: item.rota.split('/')[0], params: { tipo: item.rota.split('/')[1] }})
                    }
                }
                else {

                    this.$router.push({name: item.rota})
                }
            }

            this.buscaNotificacoes()
        },
        rotaHome () {

            if(this.$route.name != 'home'){

                this.$router.push({name: "home"})
            }

            this.buscaNotificacoes()
        },
        rotaPerfil () {

            if(this.$route.name != 'perfil'){

                this.$router.push({name: "perfil"})
            }
        },
        logout () {

            this.$vuetify.theme.dark = this.temaEscuro
            this.$store.dispatch('logout')
        },
        async setCargo (cargo) {

            this.$store.dispatch('setCargo', [cargo])

            if(this.$route.name != 'home'){

                this.$router.push({name: "home"})
            }

            this.dialogoCargo = false

            this.buscaNotificacoes()
        },
        changeTemaEscuro () { 

            this.temaEscuro = !this.temaEscuro
            localStorage.setItem('temaEscuro', this.temaEscuro)
            this.$vuetify.theme.dark = this.temaEscuro

            this.rotaHome()

            setTimeout(() => { this.temaEscuroFake = this.temaEscuro }, 500)
        },
        async buscaNotificacoes() { 

            if (this.$store.getters.getCliente == 'adm') return []
            if (this.$store.getters.getCargo.id_cargo == null) return []
            if (!this.notificacao.icone) return []

            this.notificacao.dados = ( await this.$http().post('/acordo/notificacoes', {data: {id_cargo: this.$store.getters.getCargo.id_cargo}}) ).data

            this.notificacao.tempo = new Date()
        },
        mostrarTudo() { 

            this.notificacao.mostrar = !this.notificacao.mostrar
            localStorage.setItem('notificacaoMostrar', this.notificacao.mostrar)
            this.notificacao.mostrarFake = this.notificacao.mostrar
        },
        async tudoLido() { 

            let notificacao = this.notificacao.dados.filter(e => !e['lido_'+e.tipo])
            
            for (const n of notificacao) {

                n['lido_'+n.tipo] = true
            }

            let data = {
                id_cargo: this.$store.getters.getCargo.id_cargo,
                notificacoes: notificacao,
            }

            this.notificacao.dados = ( await this.$http().post('/acordo/alteraLido', {data: data}) ).data
        },
        async alteraLido(item) {

            let data = {
                id_cargo: this.$store.getters.getCargo.id_cargo,
                notificacoes: [item],
            }

            this.notificacao.dados = ( await this.$http().post('/acordo/alteraLido', {data: data}) ).data
        },
        loopNotificacoes() {

            setTimeout(() => {

                //se o tempo for maior que 10 minutos busca notificações
                if ( (new Date() - this.notificacao.tempo) > 10*60*1000 ) {

                    this.buscaNotificacoes()
                }

                this.loopNotificacoes()

            }, 60*1000)
        }
    },
}
</script>

<style>

/* 
   estilos
    │ 
    └─────> data table
    │ 
    └─────> botoes
    │ 
    └─────> input
    │ 
    └─────> highcharts
*/


    /* borda menu */

    .v-navigation-drawer__border {
        width: 2px !important;
    }

    /* fundo do main */

    .fundo {
        background: var(--v-fundo-base) !important;
        padding-left: 55px !important;
    }

    .menu-aberto {
        padding-left: 300px !important;
    }

    .menu-fechado {
        padding-left: 55px !important;
    }

    /* classe para erro */

    .errorClass {
        color: var(--v-error-base) !important;
    }

    /* *********************** data table inicio *********************** */

    .v-data-table-header {
        background-color: var(--v-primary-base)
    }

    .theme--light.v-data-table.v-data-table--fixed-header thead th {
        background: var(--v-primary-base);
    }
    .theme--dark.v-data-table.v-data-table--fixed-header thead th {
        background: var(--v-primary-base);
    }

    .data-table-2 .v-data-table-header {
        background-color: var(--v-transparent) !important;
    }

    .v-data-table-header__icon {
        color: #fff !important;
    }

    .v-data-table-header .v-icon {
        color: #fff !important;
    }

    th {
        color: #fff !important;

        white-space: nowrap;
        padding: 2px !important;
        padding-left: 2px !important;
    }

    .data-table-2 th {
        color: var(--v-primary-base) !important;
    }

    .data-table-2 .v-data-table-header .v-icon {
        color: var(--v-primary-base) !important;
    }

    td {
        
        white-space: nowrap;
        padding: 2px !important;
    }

    .sortable span {
        padding-left: 18px;
    }

    .v-data-table-header__sort-badge {
        color: #fff !important;
        padding-left: 0 !important;
    }

    .input_tabela input {
        font-size: 0.875rem !important;
        text-align: center !important;
    }

    /* *********************** data table fim *********************** */

    /* *********************** botoes inicio *********************** */

    .v-btn.v-size--default {
        
        font-size: .75rem !important;
    }

    .v-btn {
        
        letter-spacing: normal;
        font-weight: 400;
    }

    /* *********************** botoes fim *********************** */
    
    /* *********************** moeda inicio *********************** */

    .input-moeda {
        background-color: var(--v-transparent) !important;
    }

    .input-moeda div {
        background-color: var(--v-transparent) !important;
    }

    .input-moeda input {
        text-align: right;
        min-width: 100px;
    }

    .input-texto-branco input {
      color: var(--v-transparent) !important;
    }

    /* *********************** moeda fim *********************** */

    /* *********************** highcharts inicio *********************** */

    .highcharts-root {
        font-family: "Roboto", sans-serif !important;
    }

    .highcharts-contextbutton .highcharts-button-box {
        color: transparent !important;
        stroke: transparent !important;
        fill: transparent !important;
    }

    .highcharts-button-symbol {
        color: var(--v-primary-base) !important;
        stroke: var(--v-primary-base) !important;
        fill: var(--v-primary-base) !important;
    }

    .highcharts-menu-item {
        font-size: 0.8125rem !important;
        font-weight: 500 !important;
        line-height: 1rem !important;
    }

    .highcharts-menu-item:hover {
        background: var(--v-primary-base) !important;
    }

    .highcharts-title {
        font-family: "Roboto", sans-serif !important;
    }

    .grafico-cor-primary {
        fill: var(--v-primary-base);
        stroke: var(--v-primary-base);
    }

    /* *********************** highcharts fim *********************** */

    /* *********************** filtros inicio *********************** */

    .botao_filtro {
        position: fixed; 
        top: 130px; 
        right: -10px; 
        width: 50px;
        height: 38px;
        padding-top: 7px;
        padding-left: 10px;
        opacity: 0.6 !important; 
        z-index: 1; 
        background-color: var(--v-primary-base);
        cursor: pointer;
    }

    /* *********************** filtros fim *********************** */

    /* *********************** indicadores inicio *********************** */

    /* planejamento */
    .indicadoresCartao {
        width: 250px;
        height: 110px;
    }
    .indicadoresTitulo {
        position: relative; 
        left: 10px;  
        top: 15px; 
        font-size: 12px;
        /* font-weight: bold; */
        color: #fff;
    }
    .indicadoresValor {
        position: relative; 
        left: 10px;  
        top: 15px; 
        font-size: 18px;
        font-weight: bold;
        color: #fff;
    }
    .indicadoresIcone {
        position: relative; 
        left: 198px;  
        top: -40px; 
        font-weight: bold;
    }
    .indicadoresPercemtualBarra {
        position: relative; 
        width: 190px;
        left: 10px;  
        top: -20px; 
    }
    .indicadoresPercemtualValor {
        position: relative; 
        left: 10px;  
        top: -15px; 
        font-size: 11px;
        font-weight: bold;
        color: #fff;
    }

    /* acordos */
    .indicadoresAcordoCartao {
    width: 260px;
    height: 95px;
    background: teal !important;
    }
    .indicadoresAcordoTitulo {
    position: relative; 
    left: 10px;  
    top: 10px; 
    font-size: 12px;
    /* font-weight: bold; */
    color: #fff;
    }
    .indicadoresAcordoIcone {
    position: relative; 
    left: 205px;  
    top: -10px; 
    font-weight: bold;
    }
    .indicadoresAcordoQuant {
    position: relative; 
    left: 10px;  
    top: -35px; 
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    }
    .indicadoresAcordoValor {
    position: relative; 
    left: 10px;  
    top: -30px; 
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    }

    /* *********************** indicadores fim *********************** */

</style>
