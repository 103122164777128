<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="abrirImportar()"
                >
                    <v-icon left>mdi-upload</v-icon> Importar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.padrao`]="{ item }"> 
                    {{(item.padrao ? 'sim' : 'não')}}
                </template>
                <template v-slot:[`item.notificacao_status_email`]="{ item }"> 
                    {{(item.notificacao_status_email ? 'sim' : 'não')}}
                </template>
                <template v-slot:[`item.notificacao_status_interface`]="{ item }"> 
                    {{(item.notificacao_status_interface ? 'sim' : 'não')}}
                </template>
                <template v-slot:[`item.sla`]="{ item }"> 
                    {{(item.sla ? 'sim' : 'não')}}
                </template>
                <template v-slot:[`item.notificacao_sla_email`]="{ item }"> 
                    {{(item.notificacao_sla_email ? 'sim' : 'não')}}
                </template>
                <template v-slot:[`item.notificacao_sla_interface`]="{ item }"> 
                    {{(item.notificacao_sla_interface ? 'sim' : 'não')}}
                </template>
                <template v-slot:[`item.notificacao_recorrencia_email`]="{ item }"> 
                    {{(item.notificacao_recorrencia_email ? 'sim' : 'não')}}
                </template>
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{tituloDialogo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="form"> 
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    :label="'ID '+tituloDialogo"
                                    v-model="obj['id_'+tabela]"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    :label="tituloDialogo"
                                    v-model="obj.nome"
                                    :rules="regra.obrigatorio"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Ordem"
                                    v-model="obj.ordem"
                                    :rules="regra.obrigatorio"
                                    type="number"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Ativo"
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    v-model="obj.ativo"
                                    outlined
                                    dense 
                                >
                                </v-select> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-switch
                                    label="Notificação de troca de status por e-mail"
                                    v-model="obj.notificacao_status_email"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-switch
                                    label="Notificação de troca de status na interface"
                                    v-model="obj.notificacao_status_interface"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-switch
                                    label="SLA"
                                    v-model="obj.sla"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Tempo de SLA (em horas)"
                                    v-model="obj.sla_tempo"
                                    :rules="regra.sla_tempo"
                                    :disabled="!obj.sla"
                                    type="number"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-switch
                                    label="Notificação de SLA por e-mail"
                                    v-model="obj.notificacao_sla_email"
                                    :disabled="!obj.sla"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-switch
                                    label="Notificação de SLA na interface"
                                    v-model="obj.notificacao_sla_interface"
                                    :disabled="!obj.sla"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-switch
                                    label="Notificação de recorrência por e-mail"
                                    v-model="obj.notificacao_recorrencia_email"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Tempo de recorrência (em dias)"
                                    v-model="obj.recorrencia_tempo"
                                    :rules="regra.recorrencia_tempo"
                                    :disabled="!obj.notificacao_recorrencia_email"
                                    type="number"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-select
                                    label="Assinatura"
                                    v-model="obj.id_modelo"
                                    :items="lista.assinaturaModelo"
                                    clearable
                                    outlined
                                    dense 
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-select
                                    label="Status de saída da assinatura"
                                    v-model="obj.id_status_saida_assinatura"
                                    :items="lista.status"
                                    :rules="regra.id_status_saida_assinatura"
                                    clearable
                                    outlined
                                    dense 
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoImportar"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar CSV
                </v-card-title>
                <v-card-text>
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <li>
                                Os nomes das colunas devem ser <b>idênticos</b> aos da base exportada.
                            </li>
                            <li>
                                A coluna <b>ID Status</b> é a coluna <b>chave</b> para cadastros e atualizações. 
                            </li>
                            <li>
                                Linhas com <b>ID Status</b> em branco serão <b>inseridas</b>. 
                            </li>
                            <li>
                                Linhas com <b>ID Status</b> com valor serão <b>atualizadas</b>. No caso de mais de uma linha com o mesmo <b>ID Status</b>, a última linha irá <b>sobrescrever</b> as anteriores. 
                            </li>
                            <li>
                                Na coluna <b>ativo</b> coloque <b>0</b> para <b>desativar</b> e qualquer valor <b>diferente de 0</b> para <b>ativar</b>.
                            </li>
                            <li>
                                Linhas com a coluna <b>Padrão</b> com valor <b>sim</b> não podem ser atualizadas na importação, estas linhas só podem ser atualizadas na interface. 
                            </li>
                            <li>
                                Os campos de <b>notificações de status, SLA, recorrência, assinatura e status de saída da assinatura</b> não podem ser atualizadas na importação, estes campos devem ser atualizadas na interface. 
                            </li>
                        </ul> 
                    </v-alert> 
                    <v-row no-gutters>  
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col> 
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon> 
                                Importar Arquivo
                            </v-btn>
                        </v-col> 
                    </v-row> 
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Logs
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                            <v-data-table
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros" 
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tabela: 'status',
            titulo: 'Status',
            tituloDialogo: 'Status',
            nomeCampo: null,
            headers: [
                { text: 'ID Status', value: 'id_status', align: 'center'},
                { text: 'Status', value: 'nome', align: 'center'},
                { text: 'Ordem', value: 'ordem', align: 'center'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center'},
                { text: 'Not. e-mail', value: 'notificacao_status_email', align: 'center'},
                { text: 'Not. interface', value: 'notificacao_status_interface', align: 'center'},
                { text: 'SLA', value: 'sla', align: 'center'},
                { text: 'SLA tempo', value: 'sla_tempo', align: 'center'},
                { text: 'SLA e-mail', value: 'notificacao_sla_email', align: 'center'},
                { text: 'SLA interface', value: 'notificacao_sla_interface', align: 'center'},
                { text: 'Recor. e-mail', value: 'notificacao_recorrencia_email', align: 'center'},
                { text: 'Recor. tempo', value: 'recorrencia_tempo', align: 'center'},
                { text: 'Assinatura', value: 'assinatura', align: 'center'},
                { text: 'Status de saída da assinatura', value: 'status_saida_assinatura', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            ads: [],
            dados: [], 
            objNovo: {
                id_status: null,
                nome: null,
                ordem: null,
                ativo: 1,
                notificacao_status_email: false,
                notificacao_status_interface: false,
                sla: false,
                sla_tempo: null,
                notificacao_sla_email: false,
                notificacao_sla_interface: false,
                notificacao_recorrencia_email: false,
                recorrencia_tempo: null,
                id_modelo: null,
                id_status_saida_assinatura: null,
            },
            obj: {},
            lista: {
                assinaturaModelo: [],
                status: [],
            },
            pagination: {}, 
            paginationErros: {}, 
            total: null,
            carregando: false, 
            ativo: 1,
            busca: null, 
            dialogo: false,
            dialogoImportar: false,
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            arquivoCSV: null,
            regra: { 
                obrigatorio: [
                    v => !!v || 'Campo obrigatório.',
                ],
                sla_tempo: [
                    v => !this.obj.sla || !!v || 'Campo obrigatório.',
                    v => !this.obj.sla || (!!v && v*1 > 0) || 'O valor deve ser maior que zero.',
                ],
                recorrencia_tempo: [
                    v => !this.obj.notificacao_recorrencia_email || !!v || 'Campo obrigatório.',
                    v => !this.obj.notificacao_recorrencia_email || (!!v && v*1 > 0) || 'O valor deve ser maior que zero.',
                ],
                id_status_saida_assinatura: [
                    v => !this.obj.id_modelo || !!v || 'Campo obrigatório.',
                ],
                csv: [
                    v => !!v || 'Selecione um arquivo.',
                    v => (!!v && [
                        '.csv', 
                        '.CSV'
                    ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                ],
            },  
            isCsv: false,
            headerErros: [
                { text: 'Descrição', value: 'msg', align: 'left'},
            ],
            erros: [],
            errosLabels: {msg: 'Descrição'}, 
            erro: false
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){

                    this.filtrar()
                }

            }, 1000)
        },
        ativo() {

            this.filtrar()
        },
        'obj.sla': function(v){ //function(newVal, oldVal)

            this.obj.notificacao_sla_email = v ? this.obj.notificacao_sla_email : false
            this.obj.notificacao_sla_interface = v ? this.obj.notificacao_sla_interface : false
        },
    },
    async created() {

        this.lista.assinaturaModelo = ( await this.$http().post(`/assinaturaModelo/filtro`, {data: {}}) ).data
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination
            data.busca = this.busca

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/lista`, {data: data})   

            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.lista.status = ( await this.$http().post(`/status/filtro`, {data: {}}) ).data

            this.carregando = false
        },
        async exportar() {

            this.carregando = true

            var data = {}

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/exportar`, {data: data})   

            window.location = this.$http('baseURL')+response.data.url

            this.carregando = false
        }, 
        novo() {

            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        }, 
        editar(item) {
           
            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  
            
            this.dialogo = true
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }
            
            if(!this.$refs.form.validate()) return
            
            this.carregando = true
            
            var data = this.obj

            delete data.total

            var response = await this.$http().post(`/${this.tabela}/salvar`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false  
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        }, 
        abrirImportar() {

            this.arquivoCSV = null
            this.erro = false

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogoImportar = true 
        },
        async importar() {
            
            this.carregando = true
            
            var formData = new FormData()

            formData.append("files", this.arquivoCSV)

            this.arquivoCSV = null

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            var response = await this.$http().post(`/${this.tabela}/importar`, formData)  
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogoImportar = false
            }
            else{

                this.filtrar()
                this.erros = response.data.erros
                this.pagination.page = 1
                this.erro = true
            }  
        }, 
    },
}
</script>