var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{class:{
        'customShadow myCustomFooterClean': true,
        'rounded-16': !_vm.app,
    },style:({
        'margin-left': _vm.marginLeft,
        'margin-right': _vm.marginRight,
        'margin-bottom': _vm.marginBottom,
        'margin-top': _vm.marginTop,
        width: ("calc((100% - " + _vm.marginLeft + ") - " + _vm.marginRight + ") !important"),
    }),attrs:{"color":_vm.colorfooter,"order":_vm.order,"height":_vm.getHeight,"app":_vm.app,"elevation":_vm.elevation}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-caption align-center d-flex",attrs:{"cols":"1"}},[_c('v-img',{attrs:{"src":require("../../assets/images/login/logo_footer.png"),"max-width":"35.3","max-height":"15.9","min-width":"35.3","min-height":"15.9"}})],1),_c('v-col',{class:{
                'text-caption align-center text-center': true,
                'd-flex': !this.$vuetify.breakpoint.xs,
            },style:({
                'font-size': _vm.getFontSize + ' !important',
            }),attrs:{"cols":this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 11 : 9}},[_vm._v(" © iPDV "+_vm._s(new Date().getFullYear())+" | All Rights Reserved ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm),expression:"!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"}],attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"variant":"text"},on:{"click":function($event){return _vm.goToLink('https://www.linkedin.com/company/ipdvsistemas/')}}},[_c('v-icon',[_vm._v("mdi-linkedin")])],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm),expression:"!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"}],attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"variant":"text"},on:{"click":function($event){return _vm.goToLink(
                        'https://www.youtube.com/@ipdvinteligenciaestrategic2979'
                    )}}},[_c('v-icon',[_vm._v("mdi-youtube")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }