<template>
    <v-container fluid>

        <!-- Titulo e botões -->
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Acordos Comerciais</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                <v-btn
                    v-if="modelos.length > 0"
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar Acordo
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
            </v-col>
        </v-row>

        <!-- Filtros -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('filtros')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.filtros.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary"
                        >
                            {{mostrar.filtros.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-form ref="formFiltro" v-show="mostrar.filtros.flag">
                    <v-row no-gutters class="mt-2">
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Período Inicial"
                                v-model="main.filtro.mesInicial"
                                :rules="main.regra.mesInicial"
                                required
                                outlined
                                dense 
                                type="month"
                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Período Final"
                                v-model="main.filtro.mesFinal"
                                :rules="main.regra.mesFinal"
                                required
                                outlined
                                dense 
                                type="month"
                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Acordo"
                                outlined
                                dense 
                                v-model="main.filtro.id_acordo"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Contrato"
                                outlined
                                dense 
                                v-model="main.filtro.id_contrato"
                            ></v-text-field>
                        </v-col>
                        <v-col v-for="(obj, index) in main.lista.filtro" :key="index" cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-autocomplete
                                :label="obj.nome"
                                :items="main.lista[obj.tabela]"
                                v-model="main.filtro[obj.var_id]"
                                multiple
                                clearable
                                outlined
                                dense 
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                    ripple
                                    @mousedown.prevent
                                    @click="selecionarTudo(obj.var_id, obj.tabela, main.filtro, main.lista)"
                                    >
                                    <v-list-item-action>
                                        <v-icon :color="main.filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                        {{ iconeSelecionarTudo(obj.var_id, obj.tabela, main.filtro, main.lista) }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                        Selecionar Tudo
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{item, index}">
                                    <div 
                                        v-if="main.filtro[obj.var_id].length === 1 && index === 0"
                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                    >{{ item.text }}</div>
                                    <span
                                        v-if="main.filtro[obj.var_id].length > 1 && index === 0"
                                        class="grey--text caption mr-1"
                                    >{{ main.filtro[obj.var_id].length }} selecionados</span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="5" lg="3" xl="3" class="px-2 ml-auto" align="end">
                            <v-btn
                                class="ml-2 mb-1"
                                color="primary"
                                @click="limpar()"
                            >
                                <v-icon left>mdi-filter-off</v-icon> Limpar
                            </v-btn> 
                            <v-btn
                                class="ml-2 mb-1"
                                color="primary"
                                @click="filtrar()"
                            >
                                <v-icon left>mdi-filter</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-expand-transition>
        </v-card>

        <!-- Indicadores -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('indicadores')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.indicadores.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary" 
                        >
                            {{mostrar.indicadores.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-row no-gutters v-show="mostrar.indicadores.flag">
                    <v-tooltip 
                        v-for="indicador in indicadoresTotal"
                        :key="indicador.nome"
                        top 
                        :color="indicador.cor"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-card
                                class="elevation-1 ml-3 mt-3 indicadoresAcordoCartao"
                                @click="indicador.acao()"
                                v-bind="attrs"
                                v-on="on"
                                :color="indicador.cor"
                            >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div 
                                            class="indicadoresAcordoTitulo"
                                            v-bind="attrs"
                                            v-on="on"
                                        >{{indicador.nome}}</div>
                                    </template>
                                    <span>{{indicador.desc}}</span>
                                </v-tooltip>
                                <v-avatar 
                                    class="indicadoresAcordoIcone"
                                    size="46"
                                    :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                                >
                                    <v-icon dark>{{indicador.icone}}</v-icon>
                                </v-avatar>
                                <div v-show="indicador.quantSufixo"
                                    class="indicadoresAcordoQuant"
                                >{{(indicador.quant*1)}}</div>
                                <div v-show="indicador.valorSufixo"
                                    class="indicadoresAcordoValor"
                                >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                            </v-card>
                        </template>
                        <span>{{indicador.acaoTitulo}}</span>
                    </v-tooltip>
                </v-row>
            </v-expand-transition>
        </v-card>

        <!-- Listagem de Acordos -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('lista')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.lista.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary"
                        >
                            {{mostrar.lista.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-data-table
                    v-show="mostrar.lista.flag"
                    :headers="headers"
                    :items="dados"
                    :server-items-length="total" 
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                    }"
                    :options.sync="pagination"
                    multi-sort
                    class="elevation-0 mx-2"
                >
                    <template v-slot:[`item.investimento`]="{ item }">
                        {{(item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                    </template>
                    <template v-slot:[`item.percentual_executado`]="{ item }">
                        {{item.percentual_executado + '%'}}
                    </template>
                    <template v-slot:[`item.menu`]="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    class="mr-2"
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-menu
                                </v-icon>
                            </template>
                            <v-card>
                                <v-list dense nav>
                                    <v-list-item 
                                        link 
                                        @click="buscaHistorico(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-file-document-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Histórico</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        link 
                                        @click="buscaRecibo(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-receipt-text</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Recibo</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item 
                                        v-if="item.planograma"
                                        link 
                                        @click="mostraPlanograma(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-view-dashboard</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Planograma</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item 
                                        v-for="(processo, index) in (item.processos ? item.processos.filter(e => e.function != 'comprovaAcordo' || (e.function == 'comprovaAcordo' && item.acoes_por_acordo > 0) ) : [])" :key="index"
                                        link
                                        @click="processar(item, processo)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon :color="processo.color">{{processo.icon}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{processo.nome}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item 
                                        v-if="item.auditorias > 0"
                                        link 
                                        @click="buscaAuditorias(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-image</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Auditorias</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="modelosLista.find(e => e.id_modelo == item.id_modelo).passos.find(e => e.tipo == 'filiais')"
                                        link 
                                        @click="buscaAcordoFiliais(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-store</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Filiais</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item 
                                        v-for="(upload, index) in (item.uploads)" :key="index+1000"
                                        link
                                        @click="mostraUpload(item, upload)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon :color="upload.color">{{upload.icon}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{upload.nome}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.sla_minutos`]="{ item }">
                        <v-chip
                            v-if="item.sla"
                            class="ma-1"
                            :color=" item.sla_minutos > 0 ? ( item.sla_minutos > 60*24 ? 'success' : 'warning' ) : 'error' "
                            label
                            text-color="white"
                            small
                        >
                            <span style="width: 70px;">
                                {{ item.sla_d != 0 ? item.sla_d + 'd' : '' }} {{ item.sla_h != 0 && 10 > Math.abs(item.sla_d) ? item.sla_h + 'h' : '' }} {{ item.sla_m != 0 && item.sla_d == 0? item.sla_m + 'm' : '' }}
                            </span>
                        </v-chip>
                    </template>
                </v-data-table>
            </v-expand-transition>
        </v-card>

        <!-- Selecionar Modelo de Acordo -->
        <v-dialog
            v-model="dialogoModelo"
            width="300"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Selecione o Tipo do Acordo
                </v-card-title>
                    <div v-for="(m, i) in modelos" :key="i">
                        <v-list dense nav>
                            <v-list-item link @click="setModelo(m)">
                                <v-list-item-icon>
                                    <v-icon>mdi-file-document</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{m.nome}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                    </div>
            </v-card>
        </v-dialog>

        <!-- Cadastro do Acordo -->
        <v-dialog
            v-model="formulario.dialogo"
            width="94%"
            scrollable
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Acordo - {{modelo.nome}}
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-stepper v-model="formulario.passo" class="elevation-0">
                        <v-stepper-header class="elevation-1" :style="`position: fixed; width: calc(94%); z-index: 4; background-color: ${$vuetify.theme.dark ? '#1E1E1E' : '#fff'};`">
                            <div  v-for="(p, i) in modelo.passos" :key="i">
                                <v-stepper-step
                                    :complete="formulario.passo > (i+1)" 
                                    :step="(i+1)"
                                >{{p.nome}}</v-stepper-step>
                                <v-divider v-if="(i+1) < modelo.passos.length"></v-divider>
                            </div>
                        </v-stepper-header>
                        <v-stepper-items class="mt-13" style="min-height: 200px;">
                            <v-stepper-content 
                                v-for="(p, i) in modelo.passos"
                                :key="i"
                                :step="(i+1)"
                            >
                                <v-form ref="form">
                                    <v-row no-gutters class="mt-4">
                                        <!-- Periodo Inicial -->
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"  v-if="p.tipo == 'cadastro'">
                                            <v-text-field
                                                label="Período Inicial"
                                                v-model="acordo.mesInicial"
                                                :rules="regra.mesInicial"
                                                :min="mesLimite"
                                                :disabled="acordo.fnEdicao"
                                                outlined
                                                dense 
                                                type="month"
                                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                                                @input="alteraCadastro('mesInicial')"
                                            ></v-text-field>
                                        </v-col>
                                        <!-- Periodo Final -->
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"  v-if="p.tipo == 'cadastro'">
                                            <v-text-field
                                                label="Período Final"
                                                v-model="acordo.mesFinal"
                                                :rules="regra.mesFinal"
                                                :min="mesLimite"
                                                :disabled="acordo.fnEdicao"
                                                outlined
                                                dense 
                                                type="month"
                                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                                                @input="alteraCadastro('mesFinal')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col v-for="(obj, index) in p.tabelas" :key="index" v-show="['campo', 'assinatura_setor', 'filial', 'produto'].includes(obj.tipo)" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                                            <!-- Campos Cadastro -->
                                            <v-autocomplete
                                                v-if="['campo', 'assinatura_setor'].includes(obj.tipo)  && (!acordo.fnEdicao || obj.editar_valor)"
                                                :label="obj.nome"
                                                :items="lista[obj.tabela]"
                                                v-model="acordo[obj.var_id]"
                                                :rules="regra.obrigatorio"
                                                outlined
                                                dense
                                                @change="alteraCadastro(obj.var_id)"
                                            >
                                            </v-autocomplete>
                                            <v-text-field
                                                v-if="obj.tipo == 'campo' && acordo.fnEdicao && !obj.editar_valor"
                                                :label="obj.nome"
                                                v-model="acordo[obj.tabela]"
                                                disabled
                                                outlined
                                                dense
                                            ></v-text-field>
                                            <!-- Filtro Filial -->
                                            <v-autocomplete
                                                v-if="obj.tipo == 'filial'"
                                                :label="obj.nome"
                                                :items="lista[obj.var_id]"
                                                v-model="filtroFilial[obj.var_id]"
                                                v-on:change="filtrarFilial"
                                                multiple
                                                clearable
                                                outlined
                                                dense 
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item
                                                    ripple
                                                    @mousedown.prevent
                                                    @click="selecionarTudo(obj.var_id, obj.var_id, filtroFilial, lista, 'filtrarFilial')"
                                                    >
                                                    <v-list-item-action>
                                                        <v-icon :color="filtroFilial[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                                        {{ iconeSelecionarTudo(obj.var_id, obj.var_id, filtroFilial, lista) }}
                                                        </v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                        Selecionar Tudo
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                    </v-list-item>
                                                    <v-divider class="mt-2"></v-divider>
                                                </template>
                                                <template v-slot:selection="{item, index}">
                                                    <div 
                                                        v-if="filtroFilial[obj.var_id].length === 1 && index === 0"
                                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                                    >{{ item.text }}</div>
                                                    <span
                                                        v-if="filtroFilial[obj.var_id].length > 1 && index === 0"
                                                        class="grey--text caption mr-1"
                                                    >{{ filtroFilial[obj.var_id].length }} selecionados</span>
                                                </template>
                                            </v-autocomplete>
                                            <!-- Filtro Produto -->
                                            <v-autocomplete
                                                v-if="obj.tipo == 'produto'"
                                                :label="obj.nome"
                                                :items="lista[obj.var_id]"
                                                v-model="filtroProduto[obj.var_id]"
                                                v-on:change="filtrarProduto"
                                                multiple
                                                clearable
                                                outlined
                                                dense 
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item
                                                    ripple
                                                    @mousedown.prevent
                                                    @click="selecionarTudo(obj.var_id, obj.var_id, filtroProduto, lista, 'filtrarProduto')"
                                                    >
                                                    <v-list-item-action>
                                                        <v-icon :color="filtroProduto[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                                        {{ iconeSelecionarTudo(obj.var_id, obj.var_id, filtroProduto, lista) }}
                                                        </v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                        Selecionar Tudo
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                    </v-list-item>
                                                    <v-divider class="mt-2"></v-divider>
                                                </template>
                                                <template v-slot:selection="{item, index}">
                                                    <div 
                                                        v-if="filtroProduto[obj.var_id].length === 1 && index === 0"
                                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                                    >{{ item.text }}</div>
                                                    <span
                                                        v-if="filtroProduto[obj.var_id].length > 1 && index === 0"
                                                        class="grey--text caption mr-1"
                                                    >{{ filtroProduto[obj.var_id].length }} selecionados</span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <!-- Busca Filial -->
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"  v-if="p.tipo == 'filiais'">
                                            <v-text-field
                                                v-model="buscaFilial"
                                                append-icon="mdi-magnify"
                                                label="Pesquisar"
                                                dense 
                                                outlined
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <!-- Busca Produto -->
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"  v-if="p.tipo == 'produtos'">
                                            <v-text-field
                                                v-model="buscaProduto"
                                                append-icon="mdi-magnify"
                                                label="Pesquisar"
                                                dense 
                                                outlined
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!-- PLanograma -->
                                    <v-row no-gutters class="mt-0" v-if="p.tipo == 'planograma'">
                                        <v-col cols="8" class="mb-2 px-2">
                                            <b>Selecione uma imagem ou PDF do Planograma.</b>
                                        </v-col>
                                        <v-col cols="8"> 
                                            <v-file-input 
                                                label="Planograma" 
                                                v-model="acordo.planograma"
                                                :rules="regra.planograma"
                                                accept=".pdf,.png,.jpg,.jpeg"
                                                outlined 
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <!-- Observações -->
                                    <v-row no-gutters class="mt-0">
                                        <v-col cols="8" class="px-2"  v-if="p.obs">
                                            <v-textarea
                                                :label="p.obs_nome"
                                                v-model="acordo.obs[p.id_passo]"
                                                rows="2"
                                                outlined
                                                dense 
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <!-- Tabela de Filiais -->
                                <v-data-table
                                    v-if="p.tipo == 'filiais'"
                                    :headers="headersFiliais"
                                    :items="filiais"
                                    :search="buscaFilial"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="paginationFiliais"
                                    multi-sort
                                    class="elevation-0 mt-2"
                                >
                                    <template v-slot:header="{}">
                                        <thead>
                                            <tr>
                                                <th v-for="(h, i) in headersFilialGroup" :key="i" :colspan="h.colspan" :class="h.class">
                                                    {{h.text}}
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`header.fn_filial_${m}`]="{ }">
                                        <div :key="m" style="width: 24px; margin-left: auto; margin-right: auto; display: block;">
                                            <v-checkbox 
                                                :key="m" 
                                                v-model="fn_filial[m]"
                                                @click="selecionarTudoFilial(m)"
                                                color="#fff"
                                                class="ma-0 pa-0"
                                                hide-details
                                            ></v-checkbox >
                                        </div>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.fn_filial_${m}`]="{ item }">
                                        <v-simple-checkbox
                                            :key="m" 
                                            :ripple="false" 
                                            class="ml-2"
                                            v-model="item['fn_filial_'+m]"
                                            v-if="item['disponivel_'+m]*1 > 0"
                                        ></v-simple-checkbox>
                                    </template>
                                </v-data-table>
                                <v-row no-gutters class="mt-2" v-if="p.tipo == 'filiais'">
                                    <v-col cols="6">
                                        <v-btn
                                            color="primary"
                                            :disabled="lojasSelecionadas==0"
                                            @click="importarFilial.dialogoSelecionadas=true"
                                        >
                                            <v-icon left>mdi-store-check</v-icon> 
                                            Filiais selecionadas: {{lojasSelecionadas}}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6" align="end">
                                        <v-btn
                                            class="ml-2 my-1"
                                            color="primary"
                                            @click="abrirImportarFiliais"
                                        >
                                            <v-icon left>mdi-upload</v-icon> Importar Filiais
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!-- Tabela de Descontos -->
                                <v-data-table
                                    v-if="p.tipo == 'descontos'"
                                    :headers="headersDescontos"
                                    :items="descontos"
                                    :item-class="descontoClass"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="paginationDescontos"
                                    multi-sort
                                    class="elevation-0"
                                >
                                    <template v-slot:[`item.valor`]="{ item }">
                                        <div v-if="item.mes == 'Total' || modelo.id_modelo_preco">
                                            {{(item.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                        </div>
                                        <vuetify-money
                                            v-if="item.mes != 'Total' && !modelo.id_modelo_preco"
                                            :ripple="false" 
                                            v-model="item.valor"
                                            outlined
                                            dense
                                            hide-details
                                            backgroundColor=""
                                            class="input_tabela"
                                            @input="calculaDesconto()"
                                        />
                                    </template>
                                    <template v-slot:[`item.percentual`]="{ item }">
                                        <v-text-field
                                            v-if="item.mes != 'Total'"
                                            :ripple="false" 
                                            v-model="item.percentual"
                                            v-mask="'###'"
                                            outlined
                                            dense 
                                            hide-details
                                            backgroundColor=""
                                            class="input_tabela"
                                            @input="calculaDesconto()"
                                        />
                                    </template>
                                    <template v-slot:[`item.desconto`]="{ item }">
                                        <vuetify-money
                                            v-if="item.mes != 'Total'"
                                            :ripple="false" 
                                            v-model="item.desconto"
                                            outlined
                                            dense
                                            hide-details
                                            backgroundColor=""
                                            class="input_tabela"
                                            @input="calculaDesconto()"
                                        />
                                    </template>
                                    <template v-slot:[`item.total`]="{ item }">
                                        {{(item.total*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                    </template>
                                    <template v-slot:[`item.investimento`]="{ item }">
                                        {{(item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                    </template>
                                </v-data-table>
                                <!-- Tabela de Produtos -->
                                <v-data-table
                                    v-if="p.tipo == 'produtos'"
                                    :headers="headersProdutos"
                                    :items="produtos"
                                    :search="buscaProduto"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="paginationProdutos"
                                    multi-sort
                                    class="elevation-0 mt-2"
                                >
                                    <template v-slot:[`header.fn_produto`]="{ }">
                                        <div style="width: 24px; margin-left: auto; margin-right: auto; display: block;">
                                            <v-checkbox 
                                                v-model="fn_produto"
                                                @click="selecionarTudoProduto()"
                                                color="#fff"
                                                class="ma-0 pa-0"
                                                hide-details
                                            ></v-checkbox >
                                        </div>
                                    </template>
                                    <template v-slot:[`item.fn_produto`]="{ item }">
                                        <v-simple-checkbox
                                            :ripple="false" 
                                            class="ml-2"
                                            v-model="item['fn_produto']"
                                        ></v-simple-checkbox>
                                    </template>
                                </v-data-table>
                                <v-row no-gutters class="mt-2" v-if="p.tipo == 'produtos'">
                                    <v-col cols="6">
                                        <download-csv
                                            :data="lista.produtos.filter(e => e.fn_produto)"
                                            :fields="headersProdutos.filter(e => e.value != 'fn_produto').map(e => e.value)"
                                            :labels="headersProdutos.filter(e => e.value != 'fn_produto').reduce((a, v) => ({ ...a, [v.value]: v.text}), {})"
                                            name= "Produtos.csv"
                                            delimiter=";"
                                        >
                                            <v-btn color="primary">
                                                <v-icon left>mdi-microsoft-excel</v-icon> Produtos selecionados: {{lista.produtos.filter(e => e.fn_produto).length}}
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                    <v-col cols="6" align="end">
                                        <v-btn
                                            class="ml-2 my-1"
                                            color="primary"
                                            @click="abrirImportarProdutos"
                                        >
                                            <v-icon left>mdi-upload</v-icon> Importar Produtos
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>  
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        width="130px"
                        :disabled="formulario.passo == 1"
                        @click="voltar"
                    >
                        <v-icon left>mdi-chevron-left</v-icon>
                        Voltar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        width="130px"
                        class="mx-2"
                        @click="formulario.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> 
                        Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        width="130px"
                        @click="proximo"
                        v-show="formulario.passo < modelo.passos.length"
                    >
                        <span>Próximo</span>
                        <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn
                        color="primary"
                        width="130px"
                        @click="proximo"
                        v-show="formulario.passo == modelo.passos.length"
                    >
                        <v-icon right>mdi-content-save</v-icon>
                        Salvar 
                    </v-btn>
                </v-card-actions> 
            </v-card>

            <!-- Importação de Filiais -->
            <v-dialog
                v-model="importarFilial.dialogo"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Importar CSV
                    </v-card-title>
                    <v-card-text>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-btn
                                    class="ml-2 my-1"
                                    color="primary"
                                    @click="exportarFiliais()"
                                >
                                    <v-icon left>mdi-microsoft-excel</v-icon> Modelo de Importação
                                </v-btn> 
                            </v-col> 
                        </v-row> 
                        <v-alert
                            class="mt-4"
                            text
                            type="info"
                            border="left"
                            style="font-size: 12px;"
                        >
                            <ul>
                                <li>
                                    Os nomes das colunas devem ser idênticos aos do modelo exportado (Modelo de Importação).
                                </li>
                                <li>
                                    As colunas: <b>Codigo Loja{{colunasMeses}}</b> são obrigatórias. As demais colunas podem ser excluidas do arquivo.
                                </li>
                                <li>
                                    A coluna <b>Codigo Loja</b> é a coluna <b>chave</b> para adicionar lojas aos acordos.
                                </li>
                                <li>
                                    Para adicionar uma loja deve-se colocar o valor <b>1</b> na coluna <b>Quantidade</b> do mês desejado.
                                </li>
                                <li>
                                    Qualquer valor diferente de <b>1</b> na coluna <b>Quantidade</b> do mês, remove a loja do acordo do mês.
                                </li>
                                <li>
                                    Lojas com a coluna <b>Disponivel</b> igual a <b>0</b> para determinado mês não podem ser adicionadas ao acordo do mês.
                                </li>
                            </ul> 
                        </v-alert> 
                        <v-row no-gutters>  
                            <v-col cols="8" class="px-2">
                                <v-form ref="formImportarFilial" v-model="importarFilial.isCsv">
                                    <v-file-input 
                                        label="Arquivo CSV" 
                                        outlined 
                                        dense
                                        v-model="importarFilial.arquivoCSV"
                                        :rules="regra.csv"
                                        accept=".csv"
                                    ></v-file-input>
                                </v-form>
                            </v-col> 
                            <v-col cols="4" class="px-2">
                                <v-btn
                                    color="primary"
                                    :disabled="!importarFilial.isCsv"
                                    @click="importarFiliais()"
                                >
                                    <v-icon left>mdi-upload</v-icon> 
                                    Importar Arquivo
                                </v-btn>
                            </v-col> 
                        </v-row> 
                        <v-expand-transition>
                            <div v-show="importarFilial.erro">
                                <v-row no-gutters class="mb-0">  
                                    <v-col cols="6">
                                        <download-csv
                                            :data="importarFilial.erros"
                                            :labels="importarFilial.errosLabels"
                                            name= "erros.csv"
                                            delimiter=";"
                                        >
                                            <v-btn color="primary">
                                                <v-icon left>mdi-microsoft-excel</v-icon>
                                                Exportar Logs
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                </v-row> 
                                <v-data-table
                                    :headers="importarFilial.headerErros"
                                    :items="importarFilial.erros"
                                    :items-per-page="5"
                                    :options.sync="importarFilial.pagination" 
                                    class="elevation-0 data-table-2"
                                ></v-data-table>
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="importarFilial.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Filiais Selecionadas -->
            <v-dialog
                v-model="importarFilial.dialogoSelecionadas"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Filiais selecionadas: {{lojasSelecionadas}} 
                    </v-card-title>
                    <v-row no-gutters class="mx-2">
                        <v-col cols="6">
                        </v-col>
                        <v-col cols="6" align="end"  class="py-2">
                            <download-csv
                                :data="lojasSelecionadasLista"
                                :fields="headersFiliais.map(e => e.value)"
                                :labels="headersFiliais.reduce((a, v) => ({ ...a, [v.value]: v.text}), {})"
                                name= "Filiais.csv"
                                delimiter=";"
                            >
                                <v-btn color="primary">
                                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
                                </v-btn>
                            </download-csv>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headersFiliaisResumo"
                        :items="lojasSelecionadasTotal"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        multi-sort
                        class="elevation-0"
                    >
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="importarFilial.dialogoSelecionadas=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <!-- Importação de Produtos -->
            <v-dialog
                v-model="importarProduto.dialogo"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Importar CSV
                    </v-card-title>
                    <v-card-text>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-btn
                                    class="ml-2 my-1"
                                    color="primary"
                                    @click="exportarProdutos()"
                                >
                                    <v-icon left>mdi-microsoft-excel</v-icon> Modelo de Importação
                                </v-btn> 
                            </v-col> 
                        </v-row> 
                        <v-alert
                            class="mt-4"
                            text
                            type="info"
                            border="left"
                            style="font-size: 12px;"
                        >
                            <ul>
                                <li>
                                    Os nomes das colunas devem ser idênticos aos do modelo exportado (Modelo de Importação).
                                </li>
                                <li>
                                    A coluna: <b>Codigo Produto</b> é obrigatória. As demais colunas podem ser excluidas do arquivo.
                                </li>
                                <li>
                                    A coluna <b>Codigo Produto</b> é a coluna <b>chave</b> para adicionar produtos aos acordos.
                                </li>
                                <li>
                                    Para adicionar um produto deve-se adicionar uma linha com o <b>Codigo Produto</b> no arquivo.
                                </li>
                                <li>
                                    Para remover um produto deve-se remover a linha com o <b>Codigo Produto</b> no arquivo.
                                </li>
                            </ul> 
                        </v-alert> 
                        <v-row no-gutters>  
                            <v-col cols="8" class="px-2">
                                <v-form ref="formImportarProduto" v-model="importarProduto.isCsv">
                                    <v-file-input 
                                        label="Arquivo CSV" 
                                        outlined 
                                        dense
                                        v-model="importarProduto.arquivoCSV"
                                        :rules="regra.csv"
                                        accept=".csv"
                                    ></v-file-input>
                                </v-form>
                            </v-col> 
                            <v-col cols="4" class="px-2">
                                <v-btn
                                    color="primary"
                                    :disabled="!importarProduto.isCsv"
                                    @click="importarProdutos()"
                                >
                                    <v-icon left>mdi-upload</v-icon> 
                                    Importar Arquivo
                                </v-btn>
                            </v-col> 
                        </v-row> 
                        <v-expand-transition>
                            <div v-show="importarProduto.erro">
                                <v-row no-gutters class="mb-0">  
                                    <v-col cols="6">
                                        <download-csv
                                            :data="importarProduto.erros"
                                            :labels="importarProduto.errosLabels"
                                            name= "erros.csv"
                                            delimiter=";"
                                        >
                                            <v-btn color="primary">
                                                <v-icon left>mdi-microsoft-excel</v-icon>
                                                Exportar Logs
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                </v-row> 
                                <v-data-table
                                    :headers="importarProduto.headerErros"
                                    :items="importarProduto.erros"
                                    :items-per-page="5"
                                    :options.sync="importarProduto.pagination" 
                                    class="elevation-0 data-table-2"
                                ></v-data-table>
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="importarProduto.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <!-- Resumo -->
            <v-dialog
                v-model="resumo.dialogo"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Resumo do Acordo
                    </v-card-title>
                    <v-row no-gutters class="ma-1">
                        <v-col cols="5" v-for="(campo, index) in resumo.campos" :key="index" class="ma-1 body-2">
                            <b>{{campo.nome}}:</b> {{campo.valor}}
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headersDescontos"
                        :items="descontos"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        multi-sort
                        class="elevation-0 ma-2"
                    >
                        <template v-slot:[`item.valor`]="{ item }">
                            {{(item.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </template>
                        <template v-slot:[`item.percentual`]="{ item }">
                            <div v-if="item.mes != 'Total'">{{(item.percentual*1)}}%</div>
                        </template>
                        <template v-slot:[`item.desconto`]="{ item }">
                            <div v-if="item.mes != 'Total'">{{(item.desconto*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            {{(item.total*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </template>
                        <template v-slot:[`item.investimento`]="{ item }">
                            {{(item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            class="pr-4"
                            @click="resumo.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Cancelar
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="salvar"
                        >
                            <v-icon left>mdi-content-save</v-icon> Confirmar e Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Erros -->
            <v-dialog
                v-model="dialogoErro"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 error white--text">
                        Erro(s) ao Salvar Acordo(s)
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <v-data-table
                            :headers="listaHeaderErro"
                            :items="listaErro"
                            :items-per-page="5"
                            :options.sync="paginationErro" 
                            class="elevation-1 data-table-2"
                        ></v-data-table>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <download-csv
                            :data="listaErro"
                            :labels="listaErroLabels"
                            name= "erros.csv"
                            delimiter=";"
                        >
                            <v-btn color="primary">
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </download-csv>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="dialogoErro = false"
                        >
                            <v-icon left>mdi-close</v-icon>
                            Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-dialog>

        <!-- Confirm -->
        <v-dialog
            v-model="confirm.dialogo"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{confirm.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    {{confirm.texto}}
                    <br>
                    <br>
                    <v-form ref="formConfirm">
                        <v-select
                            v-if="processo.justificativas"
                            label="Justificativa"
                            v-model="processo.justificativa"
                            :items="processo.justificativas"
                            :rules="regra.justificativa"
                            outlined
                            dense 
                        ></v-select>
                        <v-textarea
                            v-if="processo.justificativas"
                            label="Observação"
                            v-model="processo.observacao"
                            rows="2"
                            outlined
                            dense 
                            hide-details
                        ></v-textarea>
                        <b v-if="processo.upload">Selecione uma imagem ou PDF do arquivo.</b>
                        <br v-if="processo.upload">
                        <br v-if="processo.upload">
                        <v-file-input 
                            v-if="processo.upload"
                            :label="processo.upload_nome" 
                            v-model="processo.arquivo"
                            :rules="regra.upload"
                            outlined 
                            dense
                        ></v-file-input>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="confirm.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        :color="confirm.color"
                        class="pr-4"
                        @click="confirm.funcao"
                    >
                        <v-icon left>{{confirm.icon}}</v-icon> {{confirm.botao}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Comprovação -->
        <v-dialog
            v-model="comprova.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{comprova.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="formComprova">
                        <v-row no-gutters>
                            <v-col cols="12" class="mb-2">
                                <b>O acordo foi executado?</b>
                            </v-col>
                            <v-col cols="12"> 
                                <v-select 
                                    label="Executado" 
                                    v-model="processo.executado"
                                    :items="[{value: 1, text: 'Sim'}, {value: 0, text: 'Não'}]"  
                                    v-on:change="carregando=true; carregando=false;"
                                    outlined 
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="12" class="mb-2" v-if="processo.executado==1">
                                <b>Selecione uma imagem ou PDF da evidência da execução.</b>
                            </v-col>
                            <v-col cols="12" v-if="processo.executado==1"> 
                                <v-file-input 
                                    label="Evidência" 
                                    v-model="processo.evidencia"
                                    :rules="regra.evidencia"
                                    outlined 
                                    dense
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12" class="mb-2" v-if="processo.executado==0">
                                <b>No caso da não execução informe uma justificativa.</b>
                            </v-col>
                            <v-col cols="12" v-if="processo.executado==0"> 
                                <v-select
                                    label="Justificativa"
                                    v-model="processo.justificativa"
                                    :items="processo.justificativas"
                                    :rules="regra.justificativa"
                                    outlined
                                    dense 
                                ></v-select>
                            </v-col>
                            <v-col cols="12"> 
                                <v-textarea
                                    label="Observação"
                                    v-model="processo.observacao"
                                    rows="2"
                                    outlined
                                    dense 
                                ></v-textarea> 
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="comprova.headers"
                            :items="comprova.investimento"
                            :items-per-page="5"
                            :options.sync="comprova.pagination" 
                            class="elevation-1 data-table-2"
                        >
                            <template v-slot:[`item.investimento`]="{ item }">
                                <vuetify-money
                                    v-if="processo.editar_investimento"
                                    :ripple="false" 
                                    v-model="item.investimento"
                                    dense 
                                    hide-details
                                    backgroundColor=""
                                    class="input_tabela"
                                />
                                <div v-else>{{(item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="comprova.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="comprovaAcordo"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Edição de planograma -->
        <v-dialog
            v-model="planograma.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{planograma.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="formPlanograma">
                        <v-row no-gutters>
                            <v-col cols="12" class="mb-2">
                                <b>Selecione uma imagem ou PDF do Planograma.</b>
                            </v-col>
                            <v-col cols="12"> 
                                <v-file-input 
                                    label="Planograma" 
                                    v-model="processo.planograma"
                                    :rules="regra.planograma"
                                    accept=".pdf,.png,.jpg,.jpeg"
                                    outlined 
                                    dense
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="processo.fnObservacao"> 
                                <v-textarea
                                    :label="processo.obsNome"
                                    v-model="processo.observacao"
                                    rows="2"
                                    outlined
                                    dense 
                                ></v-textarea> 
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-data-table
                        :headers="planograma.headers"
                        :items="planograma.produtos"
                        :items-per-page="5"
                        :options.sync="planograma.pagination" 
                        class="elevation-1 data-table-2"
                    >
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="planograma.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="editaPlanograma()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Editar -->
        <v-dialog
            v-model="edita.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{edita.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-data-table
                        :headers="edita.headers"
                        :items="edita.investimento"
                        :items-per-page="5"
                        :options.sync="edita.pagination" 
                        class="elevation-1 data-table-2"
                    >
                        <template v-slot:[`item.investimento`]="{ item }">
                            <vuetify-money 
                                :ripple="false" 
                                v-model="item.investimento"
                                dense 
                                hide-details
                                backgroundColor=""
                                class="input_tabela"
                            />
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="edita.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="editaAcordo"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        
        <!-- Lista de Evidencias -->
        <v-dialog
            v-model="evidencias.dialogo"
            width="70%"
            scrollable
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{evidencias.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-list>
                        <v-list-item-group>
                            <v-list-item v-for="(item, i) in evidencias.lista" :key="i" class="pa-0" @click="mostraEvidenciaItem(item)">
                                <v-list-item-action class="ma-2">
                                    <v-card class="text-subtitle-2 d-flex align-center justify-center ma-0" width="80" height="40" rounded="1" elevation="0" color="secondary lighten-5">
                                        {{item.extensao}}
                                    </v-card>
                                </v-list-item-action>
                                <v-list-item-content class="text-caption ml-2">
                                    <v-list-item-title v-text="item.nome" :title="item.nome"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="evidencias.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Exibir evidencia -->
        <v-dialog
            v-model="evidencia.dialogo"
            width="70%"
            scrollable
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{evidencia.titulo}}
                </v-card-title>
                <v-card-text class="mt-4" v-html="evidencia.html">
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="evidencia.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Histórico -->
        <v-dialog
            v-model="historico.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{historico.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="historico.headers"
                    :items="historico.dados"
                    item-key="id_historico"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                ></v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="historico.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Filiais -->
        <v-dialog
            v-model="filiaisAcordo.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{filiaisAcordo.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="filiaisAcordo.headers"
                    :items="filiaisAcordo.dados"
                    item-key="id_loja"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                ></v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="filiaisAcordo.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Auditorias -->
        <v-dialog
            v-model="auditorias.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{auditorias.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="auditorias.headers"
                    :items="auditorias.dados"
                    item-key="id_auditoria"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                >
                    <template v-slot:[`item.historico`]="{ item }"> 
                        <v-icon
                            small
                            class="mr-2"
                            color="primary"
                            @click="buscaAuditoriasHistorico(item)"
                            title="Histórico"
                        >
                            mdi-file-document-multiple
                        </v-icon>
                    </template>
                    <template v-slot:[`item.evidencia`]="{ item }"> 
                        <v-icon
                            small
                            class="mr-2"
                            color="primary"
                            @click="mostraEvidencia(item)"
                            title="Foto"
                            v-if="item.evidencia"
                        >
                            mdi-tooltip-image
                        </v-icon>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="auditorias.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Auditorias Historico -->
        <v-dialog
            v-model="auditoriasHistorico.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{auditoriasHistorico.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="auditoriasHistorico.headers"
                    :items="auditoriasHistorico.dados"
                    item-key="id_historico"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                >
                    <template v-slot:[`item.evidencia`]="{ item }"> 
                        <v-icon
                            small
                            class="mr-2"
                            color="primary"
                            @click="mostraEvidencia(item)"
                            title="Foto"
                            v-if="item.evidencia"
                        >
                            mdi-tooltip-image
                        </v-icon>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="auditoriasHistorico.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Filtros de Status -->
        <v-dialog
            v-model="status.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{status.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="status.headers"
                    :items="status.dados"
                    item-key="id_status"
                    class="data-table-2 mx-2"
                >
                    <template v-slot:[`item.total_p`]="{ item }">
                        <v-progress-linear
                            :value="item.total_p"
                            color="primary"
                            height="30"
                        ><span style="color: #fff">{{item.total_p}}%</span></v-progress-linear>
                    </template>
                    <template v-slot:[`item.investimento`]="{ item }">
                        {{item.investimento.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                    </template>
                    <template v-slot:[`item.investimento_p`]="{ item }">
                        <v-progress-linear
                            :value="item.investimento_p"
                            color="primary"
                            height="30"
                        ><span style="color: #fff">{{item.investimento_p}}%</span></v-progress-linear>
                    </template>
                    <template v-slot:[`item.botao`]="{ item }">
                        <v-icon
                            small
                            class="mx-2"
                            color="primary"   
                            @click="filtrarStatus(item.id_status)"
                            title="Filtrar"
                        >
                            mdi-filter
                        </v-icon>
                    </template>
                    <template v-slot:[`header.botao`]="{ }">
                        <v-icon
                            small
                            class="mx-2"
                            color="primary"   
                            @click="filtrarStatus(null)"
                            title="Limpar Filtro de Status"
                        >
                            mdi-filter-off
                        </v-icon>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="status.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            meses: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            modelos: [],
            modelosLista: [],
            modelo: {
                passos: [],
                tabelas: [],
                mes_limite: 0,
                passoFilial: {
                    fnLoja: false,
                }
            },
            mostrar: {
                filtros: {flag: true, icone: 'mdi-chevron-up', nome: 'Filtros'},
                indicadores: {flag: true, icone: 'mdi-chevron-up', nome: 'Indicadores'},
                lista: {flag: true, icone: 'mdi-chevron-up', nome: 'Listagem'},
            },
            headers: [
                { text: 'Menu', value: 'menu', sortable: false, align: 'center'},
                { text: 'Tipo de Acordo', value: 'modelo', align: 'center'},
                { text: 'Contrato', value: 'id_contrato', align: 'center'},
                { text: 'Acordo', value: 'id_acordo', align: 'center'},
                { text: 'Mês', value: 'mes_nome', align: 'center'},
                { text: 'Parcela', value: 'parcela', align: 'center'},
                { text: 'Status', value: 'status', align: 'center'},
                { text: 'Investimento', value: 'investimento', align: 'center'},
            ],
            dados: [],
            main: {
                lista: {},
                filtro: {},
                regra: {
                    mesInicial: [
                        v => !!v || 'Campo obrigatório.',
                    ],
                    mesFinal: [
                        v => !!v || 'Campo obrigatório.',
                        v => (!!v && v >= this.main.filtro.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                    ],
                },
            },
            fn_filial: [],
            filiais: [],
            buscaFilial: null,
            headersDescontos: [
                { text: 'Mês', value: 'mes', sortable: false, align: 'center'},
                { text: 'Filiais', value: 'filiais', sortable: false, align: 'center'},
                { text: 'Valor (R$)', value: 'valor', sortable: false, align: 'center', width: "15%"},
                { text: 'Percentual (%)', value: 'percentual', sortable: false, align: 'center', width: "15%"},
                { text: 'Desconto (R$)', value: 'desconto', sortable: false, align: 'center', width: "15%"},
                { text: 'Total de Desconto (R$)', value: 'total', sortable: false, align: 'center'},
                { text: 'Investimento (R$)', value: 'investimento', sortable: false, align: 'center'},
            ],
            descontos: [],
            fn_produto: false,
            produtos: [],
            buscaProduto: null,
            atualiza: {
                filiais: true,
                produtos: true,
            },
            total: null,
            paginationLimpa: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 5,
                multiSort: true,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            pagination: {},
            paginationFiliais: {},
            paginationDescontos: {},
            paginationProdutos: {},
            indicador: 'Total',
            indicadores: [{text: 'Total', value: 'Total'}],
            carregando: false,
            formulario: {
                dialogo: false,
                passo: 1,
            },
            acordo: {
                mesInicial: null,
                mesFinal: null,
                planograma: null
            },
            lista: {
                filiais: [],
                produtos: [],
            },
            filtroFilial: {},
            importarFilial: {
                dialogo: false,
                arquivoCSV: null,
                isCsv: false,
                headerErros: [
                    { text: 'Descrição', value: 'msg', align: 'left'},
                ],
                erros: [],
                pagination: {}, 
                errosLabels: {msg: 'Descrição'}, 
                erro: false,
                dialogoSelecionadas: false,
            },
            filtroProduto: {},
            importarProduto: {
                dialogo: false,
                arquivoCSV: null,
                isCsv: false,
                headerErros: [
                    { text: 'Descrição', value: 'msg', align: 'left'},
                ],
                erros: [],
                pagination: {}, 
                errosLabels: {msg: 'Descrição'}, 
                erro: false,
                dialogoSelecionadas: false,
            },
            dialogoModelo: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            paginationErro: {},
            dialogoErro: false,
            listaHeaderErro: [
                { text: 'Descrição do Erro', value: 'msg', align: 'left'},
            ],
            listaErro: [],
            listaErroLabels: {msg: 'Descrição do Erro'},
            regra: { 
                mesInicial: [
                    v => !!v || 'Campo obrigatório.',
                    v => ((!!v && v >= this.mesLimite) || this.acordo.fnLeitura) || 'O período inicial deve ser igual ou posterior a '+this.mesLimiteNome,
                ],
                mesFinal: [
                    v => !!v || 'Campo obrigatório.',
                    v => (!!v && v >= this.acordo.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                    v => (!!v && v.substr(0, 4) == this.acordo.mesInicial.substr(0, 4)) || 'O período final deve pertencer ao mesmo ano do período inicial',
                ],
                obrigatorio: [v => !!v || 'Campo obrigatório.'],
                justificativa: [v => !!v || 'Campo obrigatório.'],
                executado: [v => !!v || 'Campo obrigatório.'],
                evidencia: [v => !!v || 'Campo obrigatório.'],
                planograma: [
                    v => !!v || !this.modelo.passos.find(e => e.tipo == 'planograma').obrigatorio || this.acordo.fnEdicao || 'Campo obrigatório.',
                    v => !v || ['.pdf','.jpg','.png','jpeg'].includes(v.name.substr(-4).toLowerCase()) || "O arquivo deve ser do tipo PDF, JPG ou PNG.",
                ],
                upload: [v => !this.processo.upload_obrigatorio || !!v || 'Campo obrigatório.'],
                csv: [
                    v => !!v || 'Selecione um arquivo.',
                    v => (!!v && [
                        '.csv', 
                        '.CSV'
                    ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                ],
                email: [ v => !v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail invalido' ],
            },
            historico: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Processo', value: 'processo', sortable: false, align: 'center'},
                    { text: 'Detalhes', value: 'detalhe', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                ],
                dados: [],
            },
            filiaisAcordo: {
                dialogo: false,
                titulo: null,
                headers: [],
                dados: [],
            },
            auditorias: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Histórico', value: 'historico', sortable: false, align: 'center'},
                    { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                    { text: 'CNPJ Filial', value: 'cnpj', sortable: false, align: 'center'},
                    { text: 'Razão Social', value: 'razao_social', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                    { text: 'Auditoria', value: 'executado_nome', sortable: false, align: 'center'},
                    { text: 'Evidência', value: 'evidencia', sortable: false, align: 'center'},
                    { text: 'Justificativa', value: 'justificativa', sortable: false, align: 'center'},
                    { text: 'Observação', value: 'observacao', sortable: false, align: 'center'},
                ],
                dados: [],
            },
            auditoriasHistorico: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                    { text: 'CNPJ Filial', value: 'cnpj', sortable: false, align: 'center'},
                    { text: 'Razão Social', value: 'razao_social', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                    { text: 'Auditoria', value: 'executado_nome', sortable: false, align: 'center'},
                    { text: 'Evidência', value: 'evidencia', sortable: false, align: 'center'},
                    { text: 'Justificativa', value: 'justificativa', sortable: false, align: 'center'},
                    { text: 'Observação', value: 'observacao', sortable: false, align: 'center'},
                ],
                dados: [],
            },
            processo: {},
            confirm: {
                dialogo: false,
                titulo: null,
                texto: null,
                funcao: null,
                color: null,
                botao: null,
                icon: null,
            },
            planograma: {
                dialogo: false,
                titulo: null,
                headers: [],
                produtos: [],
                pagination: {}, 
            },
            comprova: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                    { text: 'Investimento', value: 'investimento', sortable: false, align: 'center', width: "160px", fixed: true},
                ],
                investimento: [],
                pagination: {}, 
            },
            edita: {
                dialogo: false,
                titulo: null,
                headers: [],
                investimento: [],
                pagination: {}, 
            },
            evidencias: {
                dialogo: false,
                titulo: null,
                lista: [],
            },
            evidencia: {
                dialogo: false,
                titulo: null,
                html: null,
            },
            status: {
                dialogo: false,
                titulo: 'Quantidade / Investimento por Status',
                headers: [
                    { text: 'Status', value: 'status', align: 'left'},
                    { text: 'Quantidade', value: 'total', align: 'center'},
                    { text: '% Quant.', value: 'total_p', align: 'center'},
                    { text: 'Investimento', value: 'investimento', align: 'center'},
                    { text: '% Invest.', value: 'investimento_p', align: 'center'},
                    { text: '', value: 'botao', sortable: false, align: 'center'},
                ],
                dados: []
            },
            indicadoresTotal: [
                {
                    nome: 'ACORDOS', 
                    quant: 0, 
                    quantSufixo: 'total', 
                    valor: 0, 
                    valorSufixo: 'investimento', 
                    desc: 'Quantidade de acordos / Valor total de Investimento dos acordos.',
                    icone: 'mdi-file-document-edit',
                    cor: 'teal', 
                    acao: () => { this.status.dialogo = true },
                    acaoTitulo: 'Exibir acordos por status',
                },
            ],
            resumo: {
                dialogo: false,
                periodo: null,
                campos: [],
            }
        }
    },
    watch: {
        pagination: {
            handler () {
                if(this.modelosLista.length > 0) this.filtrar()
            },
            deep: true,
        },
    },
    async created() {

        this.carregando = true

        this.main.filtro.mesInicial = this.mesAtual
        this.main.filtro.mesFinal = this.mesAtual
        
        var data = {
            id_cargo: this.$store.getters.getCargo.id_cargo
        }

        var retorno = await this.$http().post(`/acordo/listagem`, {data: data})

        this.modelos = retorno.data.modelos

        this.modelosLista = retorno.data.modelosLista

        // var marcaNome = null
        // var produtoNome = null

        // for (const m of this.modelosLista) {

        //     let tabelaMarca = m.tabelas.find(e => e.tabela == 'marca')
        //     if (tabelaMarca) marcaNome = tabelaMarca.nome

        //     let tabelaProduto = m.tabelas.find(e => e.tabela == 'produto')
        //     if (tabelaProduto) produtoNome = tabelaProduto.nome
        // }
        
        // if (produtoNome) this.comprova.headers.unshift({ text: produtoNome, value: 'produto', sortable: false, align: 'center'})
        // if (marcaNome) this.comprova.headers.unshift({ text: marcaNome, value: 'marca', sortable: false, align: 'center'})

        // this.edita.headers = [...this.comprova.headers]

        this.main.lista = retorno.data.lista

        for (const t of this.main.lista.filtro) {

            this.main.filtro[t.var_id] = []
        }

        for (const c of retorno.data.camposListagem) {

            let index = this.headers.findIndex(e => e.value == 'investimento')

            this.headers.splice(index, 0, { text: c.nome, value: c.tabela+'_'+c.campo, align: 'center'},)
        }

        this.headers.push({ text: '% Executado', value: 'percentual_executado', align: 'center'})

        if (retorno.data.sla.length) {

            this.headers.push({ text: 'SLA', value: 'sla_minutos', align: 'center'})

            
            this.indicadoresTotal.push({
                nome: 'ACORDOS COM SLA VENCIDA', 
                quant: 0, 
                quantSufixo: 'total_sla_vencidas', 
                valor: 0, 
                valorSufixo: 'investimento_sla_vencidas', 
                desc: 'Quantidade de acordos com SLA vencida / Valor total de Investimento dos acordos com SLA vencida.',
                icone: 'mdi-clock-remove',
                cor: 'error', 
                acao: this.filtrarSlaVencida,
                acaoTitulo: 'Filtrar acordos com SLA vencida',
            })

            this.indicadoresTotal.push({
                nome: 'ACORDOS SEM SLA VENCIDA', 
                quant: 0, 
                quantSufixo: 'total_sla_nao_vencidas', 
                valor: 0, 
                valorSufixo: 'investimento_sla_nao_vencidas', 
                desc: 'Quantidade de acordos sem SLA vencida / Valor total de Investimento dos acordos sem SLA vencida.',
                icone: 'mdi-clock-check',
                cor: 'success', 
                acao: this.filtrarSlaNaoVencida,
                acaoTitulo: 'Filtrar acordos sem SLA vencida',
            })
        }

        this.carregando = false

        this.filtrar()
    },
    computed: { 
        mesAtual() {
            
            return this.$moment().format('YYYY-MM')
        },
        mesLimite() {

            var formato = 'YYYY-MM'

            if(this.modelo.mes_limite > 0) {

                return this.$moment().add(Math.abs(this.modelo.mes_limite), 'months').format(formato)
            }
            else if(this.modelo.mes_limite < 0) {

                return this.$moment().subtract(Math.abs(this.modelo.mes_limite), 'months').format(formato)
            }
            else {

                return this.$moment().format(formato)
            }
        },
        mesLimiteNome() {

            var formato = 'MMMM[ de ]YYYY'

            if(this.modelo.mes_limite > 0) {

                return this.$moment().add(Math.abs(this.modelo.mes_limite), 'months').format(formato)
            }
            else if(this.modelo.mes_limite < 0) {

                return this.$moment().subtract(Math.abs(this.modelo.mes_limite), 'months').format(formato)
            }
            else {

                return this.$moment().format(formato)
            }
        }, 
        getMeses() {

            var meses = []

            if(this.acordo.mesInicial && this.acordo.mesFinal) {

                for (let m = this.acordo.mesInicial.substr(5, 2)*1; m <= this.acordo.mesFinal.substr(5, 2)*1; m++) {

                    meses.push(m)
                }
            }

            return meses
        },
        headersFilialGroup() {

            var headersGroup = []

            headersGroup.push({ text: null, colspan: (this.modelo.tabelas.filter(e => e.tipo == 'filial').length + 1), class: ''})

            for (const m of this.getMeses) {

                headersGroup.push({ text: this.meses[m - 1], colspan: 1, class: 'v-data-table-header text-center'})
            }

            return headersGroup
        },
        headersFiliais() {

            var headers = []

            headers.push({ text: 'ID', value: 'id_loja', align: 'center'})

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filial')) {

                headers.push({ 
                    text: t.nome, 
                    value: t.var_id.substr(0, 3) == 'id_' ? t.var_id.substr(3) : t.var_id, 
                    align: 'center'
                })
            }

            for (const m of this.getMeses) {

                headers.push({ text: this.meses[m - 1], value: 'fn_filial_'+m, align: 'center',  sortable: false})
            }

            return headers
        },
        headersFiliaisResumo() {

            var headers = []

            headers.push({ text: 'Meses', value: 'nome', align: 'center',  sortable: false})

            for (const m of this.getMeses) {

                headers.push({ text: this.meses[m - 1], value: 'total_'+m, align: 'center',  sortable: false})
            }

            return headers
        },
        lojasSelecionadas() {

            var count = 0
            var fn_filial = false

            for (const i of this.filiais) {

                fn_filial = false

                for (const m of this.getMeses) {

                    if ( i['fn_filial_'+m] ) fn_filial = true
                }

                if ( fn_filial ) count++
            }

            return count
        },
        lojasSelecionadasLista() {

            let lista = this.lista.filiais.filter(e => { 

                let fn_filial = false 

                for (const m of this.getMeses) { 

                    if ( e['fn_filial_'+m] ) fn_filial = true
                } 

                return fn_filial

            } ).map(e => ({...e}))

            for (const i of lista) {

                for (const m of this.getMeses) {

                    i['fn_filial_'+m] = i['fn_filial_'+m] ? 1 : ''
                }
            }

            return lista
        },
        lojasSelecionadasTotal() {

            let obj = {

                nome: 'Total de Lojas'
            }

            for (const m of this.getMeses) {

                obj['total_'+m] = 0

                for (const i of this.lista.filiais) {

                    obj['total_'+m] += i['fn_filial_'+m] ? 1 : 0
                }
            }

            return [obj]
        },
        colunasMeses() {

            let str = ''

            for (const m of this.getMeses) {

                str += `, Quantidade ${String(m).padStart(2, '0')}/${this.acordo.mesFinal.substr(0, 4)}`
            }

            return str
        },
        headersProdutos() {

            var headers = []

            headers.push({ text: 'ID', value: 'id_produto', align: 'center'})

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'produto')) {

                headers.push({ 
                    text: t.nome, 
                    value: t.var_id.substr(0, 3) == 'id_' ? t.var_id.substr(3) : t.var_id, 
                    align: 'center'
                })
            }

            headers.push({ text: null, value: 'fn_produto', align: 'center',  sortable: false})

            return headers
        },
    },
    methods: {
        round(n) {
            return Math.round(n * 100) / 100
        },
        novo() {

            if(this.modelos.length == 0) return

            if(this.modelos.length == 1) {

                this.setModelo(this.modelos[0])
            }

            if(this.modelos.length > 1) {

                this.dialogoModelo = true
            }
        },
        mostrarOcultar(tipo) {

            this.mostrar[tipo].flag = !this.mostrar[tipo].flag
            this.mostrar[tipo].icone = this.mostrar[tipo].flag ? 'mdi-chevron-up' : 'mdi-chevron-down'
        },
        async setModelo(modelo, acordo = null) {

            this.carregando = true

            this.modelo = modelo

            this.modelo.passoFilial = this.modelo.passos.find(e => e.nome == 'Seleção de Filiais') ? this.modelo.passos.find(e => e.nome == 'Seleção de Filiais') : {}

            this.modelo.passoFilial.fnLoja = this.modelo.passoFilial.tabelas ? this.modelo.passoFilial.tabelas.find(e => e.var_id == 'nome') : false

            this.paginationFiliais = {...this.paginationLimpa}
            this.paginationDescontos = {...this.paginationLimpa}
            this.paginationProdutos = {...this.paginationLimpa}

            this.formulario.passo = 1

            if (!acordo) {

                this.acordo = {
                    mesInicial: null,
                    mesFinal: null,
                    planograma: null,
                }

                this.atualiza = {
                    filiais: true,
                    produtos: true,
                }

                this.acordo.obs = []

                for (const p of this.modelo.passos) {

                    if (p.obs) {

                        this.acordo.obs[p.id_passo] = null
                    }
                }

                for (const t of this.modelo.tabelas) {

                    this.lista[t.tabela] = []

                    this.acordo[t.var_id] = null

                    if(t.tipo == 'campo') {

                        this.lista[t.tabela] = ( await this.$http().post(`/acordo/campo`, {data: {tabela: t, id_modelo: this.modelo.id_modelo}}) ).data
                    }
                }

            }
            else {

                this.atualiza.filiais = true
                this.atualiza.produtos = true

                this.acordo = {
                    fnEdicao: true,
                    id_acordo: acordo.id_acordo,
                    mesInicial: acordo.mes.substr(0, 7),
                    mesFinal: acordo.mes.substr(0, 7),
                    m: acordo.mes.split('-')[1]*1,
                    percentual: acordo.percentual,
                    desconto: acordo.desconto,
                    planograma: null,
                }

                this.acordo.obs = []

                for (const p of this.modelo.passos) {

                    if (p.obs) {

                        this.acordo.obs[p.id_passo] = acordo.obs.find(e => e.id_passo == p.id_passo) ? acordo.obs.find(e => e.id_passo == p.id_passo).obs : null
                    }
                }

                for (const t of this.modelo.tabelas) {

                    
                    if(t.tipo == 'campo' && t.editar_valor) {

                        this.lista[t.tabela] = ( await this.$http().post(`/acordo/campo`, {data: {tabela: t, id_modelo: this.modelo.id_modelo}}) ).data
                    }

                    if(t.tipo == 'assinatura_setor') {

                        this.lista[t.tabela] = ( await this.$http().post(`/acordo/campo`, {data: {tabela: t, id_fornecedor: acordo.id_fornecedor}}) ).data
                    }

                    if(['campo', 'assinatura_setor'].includes(t.tipo)) {

                        this.acordo[t.var_id] = acordo[t.var_id]
                        this.acordo[t.tabela] = acordo[t.tabela]
                    }
                }

                this.acordo.filiais = acordo.filiais

                this.acordo.produtos = acordo.produtos
            }

            this.filtroFilial = {}
            this.filtroProduto = {}

            this.fn_filial = []
            this.fn_produto = false

            this.filiais = []
            this.descontos = []
            this.produtos = []

            for (const t of this.modelo.tabelas) {

                if(t.tipo == 'filial') {

                    this.filtroFilial[t.var_id] = []
                }

                if(t.tipo == 'produto') {

                    this.filtroProduto[t.var_id] = []
                }
            }

            this.dialogoModelo = false

            this.formulario.dialogo = true

            setTimeout(() => {
                this.carregando = false
            }, 100)
        },
        async alteraCadastro(campo) {

            if ( ['mesInicial', 'mesFinal', 'id_acao'].includes(campo) ) {

                this.atualiza.filiais = true 
            }

            if ( ['id_fornecedor'].includes(campo) ) {

                this.atualiza.produtos = true 

                for (const t of this.modelo.tabelas) {

                    if(t.tipo == 'assinatura_setor') {

                        this.carregando = true

                        this.lista[t.tabela] = ( await this.$http().post(`/acordo/campo`, {data: {tabela: t, id_fornecedor: this.acordo.id_fornecedor}}) ).data

                        this.acordo[t.var_id] = null

                        this.carregando = false
                    }
                }
            }
        },
        proximo() {

            this.alerta = false

            if (!this.$refs.form[this.formulario.passo-1].validate()) return

            if (this.modelo.passos[this.formulario.passo] && this.modelo.passos[this.formulario.passo].tipo == 'filiais') {

                this.buscaFiliais(this.formulario.passo)
            }

            if (this.modelo.passos[this.formulario.passo-1] && this.modelo.passos[this.formulario.passo-1].tipo == 'filiais') {

                let total = ( this.lojasSelecionadasTotal )[0]

                for (const m of this.getMeses) {

                    if (this.modelo.passos[this.formulario.passo-1].obrigatorio && total['total_'+m] == 0) {

                        this.alerta = true
                        this.alertaColor = 'error'
                        this.alertaTimeout = -1
                        this.alertaTexto = 'Selecione pelo menos uma filial para cada mês.'
                        return
                    }
                }
            }

            if (this.modelo.passos[this.formulario.passo] && this.modelo.passos[this.formulario.passo].tipo == 'descontos') {

                this.buscaDescontos(this.formulario.passo)
            }

            if (this.modelo.passos[this.formulario.passo-1] && this.modelo.passos[this.formulario.passo-1].tipo == 'descontos') {

                for (const d of this.descontos) {

                    if (d.investimento*1 < 0) {

                        this.alerta = true
                        this.alertaColor = 'error'
                        this.alertaTimeout = -1
                        this.alertaTexto = 'Nenhum investimento pode ser menor que zero.'
                        return
                    }

                    if (this.modelo.passos[this.formulario.passo-1].obrigatorio && d.investimento*1 <= 0) {

                        this.alerta = true
                        this.alertaColor = 'error'
                        this.alertaTimeout = -1
                        this.alertaTexto = 'Nenhum investimento pode ser menor ou igual a zero.'
                        return
                    }
                }
            }

            if (this.modelo.passos[this.formulario.passo] && this.modelo.passos[this.formulario.passo].tipo == 'produtos') {

                this.buscaProdutos(this.formulario.passo)
            }

            if (this.modelo.passos[this.formulario.passo-1] && this.modelo.passos[this.formulario.passo-1].tipo == 'produtos') {

                if (this.modelo.passos[this.formulario.passo-1].obrigatorio && this.lista.produtos.filter(e => e.fn_produto).length == 0) {

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = -1
                    this.alertaTexto = 'Selecione pelo menos um produto.'
                    return
                }
            }

            if (this.formulario.passo < this.modelo.passos.length) {

                this.formulario.passo++
            }
            else {

                this.mostraResumo()
            }
        },
        voltar() {

            this.formulario.passo--
        },
        async buscaFiliais(passo) {

            if ( !this.atualiza.filiais ) return

            this.atualiza.filiais = false

            this.carregando = true

            this.fn_filial = []

            this.paginationFiliais = {...this.paginationLimpa}

            var data = {}

            data.modelo = this.modelo
            data.passo = this.modelo.passos[passo]
            data.acordo = this.acordo

            var retorno = await this.$http().post(`/acordo/filiais`, {data: data})

            this.lista.filiais = retorno.data
            this.filiais = retorno.data.map(e => (e))

            for (const t of data.passo.tabelas) {

                this.filtroFilial[t.var_id] = []

                if (['cnpj', 'nome'].includes(t.var_id)) {

                    this.lista[t.var_id] = this.lista.filiais.map(e => ({value: e[t.var_id], text: e.id_loja + ' - ' + e[t.var_id]}))
                }
                else if (t.var_id.substr(0, 3) == 'id_') {

                    this.lista[t.var_id] = this.lista.filiais.map(e => ({value: e[t.var_id], text: e[t.var_id] + ' - ' + e[t.var_id.substr(3)]}))
                }
                else {

                    this.lista[t.var_id] = this.lista.filiais.map(e => ({value: e[t.var_id], text: e[t.var_id]}))
                }

                this.lista[t.var_id] = this.lista[t.var_id].filter((v, i, a) => a.findIndex(e => e.value == v.value) === i)

                this.lista[t.var_id] = this.lista[t.var_id].sort((a,b) => a.value - b.value )
            }

            if (this.acordo.fnEdicao) {

                for (const f of this.filiais) {

                    f['fn_filial_'+this.acordo.m] = this.acordo.filiais.includes(f.id_loja)
                }
            }

            this.carregando = false
        },
        async exportarFiliais() {

            this.carregando = true
           
            var data = {}

            data.modelo = this.modelo
            data.passo = this.modelo.passos[this.formulario.passo - 1]
            data.acordo = this.acordo

            var retorno = await this.$http().post(`/acordo/exportarFiliais`, {data: data})

            window.location = this.$http('baseURL')+retorno.data.url

            this.carregando = false
        },
        filtrarFilial () {

            this.paginationFiliais.page = 1

            var filiais = this.lista.filiais.map(e => (e))

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filial')) {

                if(this.filtroFilial[t.var_id].length) {

                    filiais = filiais.filter( e => this.filtroFilial[t.var_id].includes( e[t.var_id] ) )
                }
            }

            this.fn_filial = []
            this.filiais = filiais
        },
        selecionarTudoFilial(m) {

            for (const i of this.filiais) {

                if (i['disponivel_'+m]*1 > 0) {

                    i['fn_filial_'+m] = this.fn_filial[m] 
                }
            }
        },
        abrirImportarFiliais() {

            this.importarFilial.arquivoCSV = null
            this.importarFilial.erro = false
            this.importarFilial.pagination.page = 1
            this.importarFilial.erros = []
            this.alerta = false

            try {
                this.$refs.formImportarFilial.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.importarFilial.dialogo = true 
        },
        async importarFiliais() {

            this.carregando = true

            for (const i of this.filiais) {

                for (const m of this.getMeses) { 

                    i['fn_filial_'+m] = false
                }
            }

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filial')) {

                this.filtroFilial[t.var_id] = []
            }

            this.alerta = false
            this.importarFilial.erros = []
            this.filiais = this.lista.filiais.map(e => (e))
            
            const parseCsv = async (csv) => {
                return new Promise(resolve => {
                    this.$papa.parse(csv, {
                        delimiter: ";",
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        complete: function(results) {
                            resolve(results)
                        },
                    })
                })
            }

            var csv = await parseCsv(this.importarFilial.arquivoCSV)
            this.importarFilial.arquivoCSV = null

            if(csv.errors.length){

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Erro ao ler o arquivo CSV. Exporte novamente o modelo e importe o modelo editado.'
                this.carregando = false
                return
            }

            var fnId = Object.keys(csv.data[0]).includes('Codigo Loja')

           if(!fnId){

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = `Verifique se a coluna "Codigo Loja" está escrita corretamente no arquivo CSV.`
                this.carregando = false
                return
            }

            for (const m of this.getMeses) {

                let quantidade = `Quantidade ${String(m).padStart(2, '0')}/${this.acordo.mesFinal.substr(0, 4)}`

                let campo = Object.keys(csv.data[0]).includes(quantidade)

                if(!campo){

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = -1
                    this.alertaTexto = `Verifique se a coluna "${quantidade}" está escrita corretamente no arquivo CSV.`
                    this.carregando = false
                    return
                }
            }

            var linha = 2
            var erroLinha = null

            for (const l of csv.data) {

                erroLinha = ' - Erro linha '+linha
                linha++

                let index = -1

                if (fnId) {

                    index = this.lista.filiais.findIndex(e => e.nome == l['Codigo Loja'])
                }

                if(index >= 0){

                    for (const m of this.getMeses) { 

                        let quantidade = `Quantidade ${String(m).padStart(2, '0')}/${this.acordo.mesFinal.substr(0, 4)}`

                        if (this.filiais[index]['disponivel_'+m]*1 <= 0 && l[quantidade] == 1) {

                            this.importarFilial.erros.push({
                                msg: `Não há inventário disponível para o mês ${String(m).padStart(2, '0')}/${this.acordo.mesFinal.substr(0, 4)}.`+erroLinha
                            })
                        }
                        else {

                            this.filiais[index]['fn_filial_'+m] = l[quantidade] == 1 ? true : false
                        }
                    }
                }
                else{

                    this.importarFilial.erros.push({
                        msg: `Loja não foi encontrada.`+erroLinha
                    })
                }
            }

            if(this.importarFilial.erros.length > 0){

                this.importarFilial.pagination.page = 1
                this.importarFilial.erro = true
            }
            else {

                this.importarFilial.dialogo = false
            }

            this.carregando = false
        },
        async buscaDescontos(passo) {

            this.carregando = true

            var data = {}

            data.modelo = this.modelo
            data.passo = this.modelo.passos[passo]
            data.acordo = this.acordo

            var precos = []

            if (data.modelo.id_modelo_preco) {

                precos = ( await this.$http().post(`/acordo/precos`, {data: data}) ).data
            }

            var descontosOld = this.descontos.map(e => ({...e}))

            this.descontos = []

            this.paginationDesconto = {...this.paginationLimpa}

            let filiais = ( this.lojasSelecionadasTotal )[0]
            let totalFiliais = 0
            let totalValor = 0

            for (const m of this.getMeses) {

                totalFiliais += filiais['total_'+m]
                totalValor += data.modelo.id_modelo_preco ? precos.find(e => e.mes == m).preco * filiais['total_'+m] : 0
                
                let obj = {
                    mes: this.meses[m - 1],
                    filiais: filiais['total_'+m],
                    valor: data.modelo.id_modelo_preco ? this.round( precos.find(e => e.mes == m).preco * filiais['total_'+m] ) : 0,
                    percentual: descontosOld.find(e => e.mes == this.meses[m - 1]) ? descontosOld.find(e => e.mes == this.meses[m - 1]).percentual : null,
                    desconto: descontosOld.find(e => e.mes == this.meses[m - 1]) ? descontosOld.find(e => e.mes == this.meses[m - 1]).desconto : null,
                    total: 0,
                    investimento: 0,
                }

                if (this.acordo.fnEdicao && !descontosOld.length) {

                    obj.percentual = this.acordo.percentual
                    obj.desconto = this.acordo.desconto
                }

                this.descontos.push(obj)
            }

            let obj = {
                mes: 'Total',
                filiais: totalFiliais,
                valor: this.round( totalValor ),
                total: 0,
                investimento: 0,
            }

            this.descontos.push(obj)

            this.calculaDesconto()

            this.carregando = false
        },
        calculaDesconto() {

            let total = 0
            let investimento = 0
            let valor = 0

            for (const d of this.descontos) {

                if ( d.mes != 'Total' ) {

                    d.total = this.round( ( d.percentual / 100 ) * d.valor + d.desconto * 1 )
                    total += ( d.percentual / 100 ) * d.valor + d.desconto * 1

                    d.investimento = this.round( d.valor * ( 1 - d.percentual / 100 ) - d.desconto * 1 )
                    investimento += d.valor * ( 1 - d.percentual / 100 ) - d.desconto * 1

                    valor += d.valor*1
                }
                else {

                    d.total = this.round( total )
                    d.investimento = this.round( investimento )

                    if (!this.modelo.id_modelo_preco) {

                        d.valor = this.round( valor )
                    }
                }
            }
        },
        descontoClass(item) {

            return item.investimento < 0 ? 'errorClass' : ''
        },
        async buscaProdutos(passo) {

            if ( !this.atualiza.produtos ) return

            this.atualiza.produtos = false

            this.carregando = true

            this.fn_produto = false

            this.paginationProdutos = {...this.paginationLimpa}

            var data = {}

            data.modelo = this.modelo
            data.passo = this.modelo.passos[passo]
            data.acordo = this.acordo

            var retorno = await this.$http().post(`/acordo/produtos`, {data: data})

            this.lista.produtos = retorno.data
            this.produtos = retorno.data.map(e => (e))

            for (const t of data.passo.tabelas) {

                this.filtroProduto[t.var_id] = []

                
                if (['codigo', 'nome'].includes(t.var_id)) {

                    this.lista[t.var_id] = this.lista.produtos.map(e => ({value: e[t.var_id], text: e.id_produto + ' - ' + e[t.var_id]}))
                }
                else if (t.var_id.substr(0, 3) == 'id_') {

                    this.lista[t.var_id] = this.lista.produtos.map(e => ({value: e[t.var_id], text: e[t.var_id] + ' - ' + e[t.var_id.substr(3)]}))
                }
                else {

                    this.lista[t.var_id] = this.lista.produtos.map(e => ({value: e[t.var_id], text: e[t.var_id]}))
                }

                this.lista[t.var_id] = this.lista[t.var_id].filter((v, i, a) => a.findIndex(e => e.value == v.value) === i)

                this.lista[t.var_id] = this.lista[t.var_id].sort((a,b) => a.value - b.value )
            }

            if (this.acordo.fnEdicao) {

                for (const p of this.produtos) {

                    p.fn_produto = this.acordo.produtos.includes(p.id_produto)
                }
            }

            this.carregando = false
        },
        filtrarProduto () {

            this.paginationProdutos.page = 1

            var produtos = this.lista.produtos.map(e => (e))

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'produto')) {

                if(this.filtroProduto[t.var_id].length) {

                    produtos = produtos.filter( e => this.filtroProduto[t.var_id].includes( e[t.var_id] ) )
                }
            }

            this.fn_produto = false
            this.produtos = produtos
        },
        selecionarTudoProduto() {

            for (const i of this.produtos) {

                i.fn_produto = this.fn_produto
            }
        },
        async exportarProdutos() {

            this.carregando = true
           
            var data = {}

            data.modelo = this.modelo
            data.passo = this.modelo.passos[this.formulario.passo - 1]
            data.acordo = this.acordo

            var retorno = await this.$http().post(`/acordo/exportarProdutos`, {data: data})

            window.location = this.$http('baseURL')+retorno.data.url

            this.carregando = false
        },
        abrirImportarProdutos() {

            this.importarProduto.arquivoCSV = null
            this.importarProduto.erro = false
            this.importarProduto.pagination.page = 1
            this.importarProduto.erros = []
            this.alerta = false

            try {
                this.$refs.formImportarProduto.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.importarProduto.dialogo = true 
        },
        async importarProdutos() {

            this.carregando = true

            for (const i of this.produtos) {

                i.fn_produto = false
            }

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'produto')) {

                this.filtroProduto[t.var_id] = []
            }

            this.alerta = false
            this.importarProduto.erros = []
            this.produtos = this.lista.produtos.map(e => (e))
            
            const parseCsv = async (csv) => {
                return new Promise(resolve => {
                    this.$papa.parse(csv, {
                        delimiter: ";",
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        complete: function(results) {
                            resolve(results)
                        },
                    })
                })
            }

            var csv = await parseCsv(this.importarProduto.arquivoCSV)
            this.importarProduto.arquivoCSV = null

            if(csv.errors.length){

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Erro ao ler o arquivo CSV. Exporte novamente o modelo e importe o modelo editado.'
                this.carregando = false
                return
            }

            var fnId = Object.keys(csv.data[0]).includes('Codigo Produto')

           if(!fnId){

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = `Verifique se a coluna "Codigo Produto" está escrita corretamente no arquivo CSV.`
                this.carregando = false
                return
            }

            var linha = 2
            var erroLinha = null

            for (const l of csv.data) {

                erroLinha = ' - Erro linha '+linha
                linha++

                let index = -1

                if (fnId) {

                    index = this.lista.produtos.findIndex(e => e.codigo == l['Codigo Produto'])
                }

                if(index >= 0) {

                    this.produtos[index].fn_produto = true
                }
                else {

                    this.importarProduto.erros.push({
                        msg: `Produto não foi encontrada.`+erroLinha
                    })
                }
            }

            if(this.importarProduto.erros.length > 0){

                this.importarProduto.pagination.page = 1
                this.importarProduto.erro = true
            }
            else {

                this.importarProduto.dialogo = false
            }

            this.carregando = false
        },
        mostraResumo() {

            let ano = this.acordo.mesInicial.split('-')[0]
            let mesInicial = this.acordo.mesInicial.split('-')[1] - 1
            let mesFinal = this.acordo.mesFinal.split('-')[1] - 1

            this.resumo.campos = [
                {nome: `Período de`, valor: `${this.meses[mesInicial]} a ${this.meses[mesFinal]} de ${ano}`}
            ]

            for (const t of this.modelo.tabelas) {

                if ( ['campo', 'assinatura_setor'].includes(t.tipo) ) {

                    if (!this.acordo.fnEdicao || t.editar_valor) {

                        this.resumo.campos.push( {nome: t.nome, valor: this.lista[t.tabela].find(e => e.value == this.acordo[t.var_id]).text} )
                    }
                    else {

                        this.resumo.campos.push( {nome: t.nome, valor: this.acordo[t.tabela]} )
                    }
                }
            }

            for (const p of this.modelo.passos) {

                if (p.obs) {

                    this.resumo.campos.push( {nome: p.obs_nome, valor: this.acordo.obs[p.id_passo]} )
                }
            }

            if ( this.lista.produtos.filter(e => e.fn_produto).length > 0 ) {

                this.resumo.campos.push( {nome: `Produtos Selecionados`, valor: this.lista.produtos.filter(e => e.fn_produto).length} )
            }

            this.resumo.dialogo = true
        },
        async salvar() {

            this.carregando = true

            var data = {}

            data.modelo = this.modelo
            data.acordo = this.acordo
            data.processo = this.processo

            data.filiais = []

            for (const m of this.getMeses) {

                data.filiais[m] = this.filiais.filter(e => e['fn_filial_'+m]).map(e => e.id_loja)
            }

            data.descontos = this.descontos

            data.produtos = this.produtos.filter(e => e.fn_produto).map(e => e.id_produto)

            var formData = new FormData()

            formData.append("files", this.acordo.planograma)
            formData.append("data", JSON.stringify(data))

            var response = {}

            if (this.acordo.fnEdicao) {

                response = await this.$http().post(`/acordo/editaAcordoCompleto`, formData)
            }
            else {

                response = await this.$http().post(`/acordo/salvar`, formData)
            }

            this.carregando = false

            this.filtrar()

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 3000
                this.alertaTexto = response.data.msg

                setTimeout(() => { 

                    this.alerta = false
                    this.carregando = false
                    this.formulario.dialogo = false
                }, 1000);
            }
            else{

                if(response.data.msg) {

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = -1
                    this.alertaTexto = response.data.msg
                }
                else {

                    this.dialogoErro = true 
                    this.listaErro = response.data.erros
                    this.paginationErro.page = 1
                }
            }
        },
        filtrarStatus(id_status) {

            this.main.filtro.id_status = id_status ? [id_status] : []

            this.status.dialogo = false

            this.filtrar()
        },
        async filtrarSlaVencida() {

            this.main.filtro.sla = 'vencidas'

            await this.filtrar()
        },
        async filtrarSlaNaoVencida() {

            this.main.filtro.sla = 'nao_vencidas'

            await this.filtrar()
        },
        async filtrar() {

            if (this.modelosLista.length == 0) return

            if (!this.$refs.formFiltro.validate()) return

            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.pagination

            data.filtro = {...this.main.filtro}
            data.filtro.id_acordo = this.main.filtro.id_acordo ? [this.main.filtro.id_acordo] : []
            data.filtro.id_contrato = this.main.filtro.id_contrato ? [this.main.filtro.id_contrato] : []

            data.modelos = this.modelosLista

            var response = await this.$http().post(`/acordo/lista`, {data: data})
            
            this.dados = response.data.lista
            this.total = response.data.lista.length ? response.data.indicadores[0].total*1 : 0

            let indicadores = response.data.indicadores[0]

            for (const indicador of this.indicadoresTotal) {

                let propriedade = indicador.quantSufixo
                let quant = indicadores[propriedade]

                indicador.quant = quant ? quant*1 : 0

                propriedade = indicador.valorSufixo
                let valor = indicadores[propriedade]

                indicador.valor = valor ? valor*1 : 0
            }

            let statusDados = this.main.lista['acordo.status'].map(e => ({id_status: e.value, status: e.text}))

            for (const status of statusDados) {
                
                status.total = indicadores['total_'+status.id_status]*1
                status.total_p = indicadores.total*1 ? Math.round(100*indicadores['total_'+status.id_status]/indicadores.total) : 0
                status.investimento = indicadores['investimento_'+status.id_status]*1
                status.investimento_p = indicadores.investimento*1 ? Math.round(100*indicadores['investimento_'+status.id_status]/indicadores.investimento) : 0
            }

            statusDados = this.main.filtro.id_status.length ? statusDados.filter(e => this.main.filtro.id_status.includes(e.id_status)) : statusDados

            statusDados = statusDados.sort((a,b) => b.investimento - a.investimento)

            this.status.dados = [...statusDados]

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar()
            }

            this.carregando = false
        },
        limpar() {

            this.pagination = {...this.paginationLimpa}

            this.main.filtro = {
                mesInicial: this.mesAtual,
                mesFinal: this.mesAtual,
                acordo: null,
                contrato: null,
            }  
            
            for (const t of this.main.lista.filtro) {
                
                this.main.filtro[t.var_id] = []
            }
        },
        async exportar() {

            if (this.modelosLista.length == 0) return
           
            this.carregando = true
            
            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.filtro = {}

            data.filtro = {...this.main.filtro}
            data.filtro.id_acordo = this.main.filtro.id_acordo ? [this.main.filtro.id_acordo] : []
            data.filtro.id_contrato = this.main.filtro.id_contrato ? [this.main.filtro.id_contrato] : []

            data.modelos = this.modelosLista

            var response = await this.$http().post(`/acordo/exportar`, {data: data})   

            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        }, 
        async buscaHistorico(item) {

            this.carregando = true
            
            this.historico.dados = []
            
            this.historico.titulo = 'Histórico (Acordo: '+item.id_acordo+')'

            var response = await this.$http().post('/acordo/historico', {data: {id_acordo: item.id_acordo}})

            this.historico.dados = response.data

            this.historico.dialogo = true
            this.carregando = false
        },
        async buscaAcordoFiliais(item) {

            this.carregando = true

            this.filiaisAcordo.headers = [
                { text: 'ID Loja', value: 'id_loja', sortable: false, align: 'center'},
            ]

            let tabelasFiliais = this.modelosLista.find(e => e.id_modelo == item.id_modelo).passos.find(e => e.tipo == 'filiais').tabelas

            for (const t of tabelasFiliais) {

                this.filiaisAcordo.headers.push({ text: t.nome, value: t.var_id, sortable: false, align: 'center'})
            }
            
            this.filiaisAcordo.dados = []
            
            this.filiaisAcordo.titulo = 'Filiais (Acordo: '+item.id_acordo+')'

            var response = await this.$http().post('/acordo/acordoFiliais', {data: {id_acordo: item.id_acordo}})

            this.filiaisAcordo.dados = response.data

            this.filiaisAcordo.dialogo = true
            this.carregando = false
        },
        async buscaAuditorias(item) {

            this.carregando = true
            
            this.auditorias.dados = []
            
            this.auditorias.titulo = 'Auditorias (Acordo: '+item.id_acordo+')'

            var response = await this.$http().post('/acordo/auditorias', {data: {id_acordo: item.id_acordo}})

            this.auditorias.dados = response.data

            this.auditorias.dialogo = true
            this.carregando = false
        },
        async buscaAuditoriasHistorico(item) {

            this.carregando = true
            
            this.auditoriasHistorico.dados = []
            
            this.auditoriasHistorico.titulo = 'Auditorias Histórico (Acordo: '+item.id_acordo+' / Auditoria: '+item.id_auditoria+')'

            var response = await this.$http().post('/acordo/auditoriasHistorico', {data: {id_auditoria: item.id_auditoria}})

            this.auditoriasHistorico.dados = response.data

            this.auditoriasHistorico.dialogo = true
            this.carregando = false
        },
        async processar(item, processo) {

            this.processo = processo
            this.processo.acordos = [item.id_acordo]
            this.processo.id_acordo = item.id_acordo
            this.processo.justificativa = null
            this.processo.observacao = null
            this.processo.executado = 1
            this.processo.evidencia = null
            this.processo.arquivo = null
            this.processo.planograma = null

            if (processo.confirm) {

                this.confirm.titulo = `${processo.nome} - (Acordo: ${item.id_acordo})`
                this.confirm.texto = processo.confirm_msg
                this.confirm.color = processo.color
                this.confirm.icon = processo.icon
                this.confirm.botao = processo.nome
                this.confirm.justificativas = processo.justificativas
                this.confirm.funcao = this[processo.function]
                this.confirm.dialogo = true
            }
            else if (processo.function == 'editaPlanograma') {

                let data = this.processo
                data.modelo = this.modelosLista.find(e => e.id_modelo == item.id_modelo)
                data.passo = data.modelo.passos.find(e => e.tipo == 'planograma')

                this.planograma.headers = []

                for (const t of data.modelo.tabelas.filter(e => e.tipo == 'produto')) {

                    this.planograma.headers.push({ 
                        text: t.nome, 
                        value: t.var_id.substr(0, 3) == 'id_' ? t.var_id.substr(3) : t.var_id, 
                        align: 'center'
                    })
                }

                if ( data.passo.obs ) {

                    this.processo.observacao = item['obs_' + data.passo.ordem]
                    this.processo.fnObservacao = true
                    this.processo.obsNome = data.passo.obs_nome
                }

                this.planograma.produtos = ( await this.$http().post('/acordo/acordoProdutos', {data: data}) ).data

                this.planograma.titulo = `${processo.nome} - (Acordo: ${item.id_acordo})`
                this.planograma.pagination = this.paginationLimpa
                this.planograma.dialogo = true
            }
            else if (processo.function == 'comprovaAcordo') {

                let data = this.processo
                data.modelo = this.modelosLista.find(e => e.id_modelo == item.id_modelo)

                this.comprova.investimento = ( await this.$http().post('/acordo/acordoInvestimento', {data: data}) ).data
                
                this.comprova.titulo = `${processo.nome} - (Acordo: ${item.id_acordo})`
                this.comprova.pagination = this.paginationLimpa
                this.comprova.dialogo = true
            }
            else if (processo.function == 'editaAcordo') {

                let data = this.processo
                data.modelo = this.modelos.find(e => e.id_modelo == item.id_modelo)

                this.edita.investimento = ( await this.$http().post('/acordo/acordoInvestimento', {data: data}) ).data

                this.edita.titulo = `${processo.nome} - (Acordo: ${item.id_acordo})`
                this.edita.pagination = this.paginationLimpa
                this.edita.dialogo = true
            }
            else if (processo.function == 'editaAcordoCompleto') {

                let data = this.processo
                data.modelo = this.modelos.find(e => e.id_modelo == item.id_modelo)

                //this.carregando = true

                let acordo = ( await this.$http().post('/acordo/acordoDados', {data: data}) ).data

                this.setModelo(data.modelo, acordo)
            }
            else {

                this[processo.function]()
            }
        },
        async alteraStatus () {

            if (this.processo.confirm && !this.$refs.formConfirm.validate()) return

            this.carregando = true

            var data = this.processo

            var formData = new FormData()

            formData.append("files", data.arquivo)
            formData.append("data", JSON.stringify(data))

            var response = await this.$http().post('/acordo/alteraStatus', formData) 
            
            this.confirm.dialogo = false
            this.carregando = false
            
            if(response.data.sucesso) {

               this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async comprovaAcordo () {
            
            if (!this.$refs.formComprova.validate()) return
           
            this.carregando = true
            
            var data = this.processo
            
            data.investimento = this.comprova.investimento

            var formData = new FormData()

            formData.append("files", data.evidencia)
            formData.append("data", JSON.stringify(data))

            var response = await this.$http().post('/acordo/comprovaAcordo', formData) 
            
            this.carregando = false
            
            if(response.data.sucesso) {
                
                this.comprova.dialogo = false

                this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        async editaPlanograma () {

            if (!this.$refs.formPlanograma.validate()) return

            this.carregando = true

            var data = this.processo

            var formData = new FormData()

            formData.append("files", data.planograma)
            formData.append("data", JSON.stringify(data))

            var response = await this.$http().post('/acordo/editaPlanograma', formData) 
            
            this.carregando = false
            
            if(response.data.sucesso) {

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 3000
                this.alertaTexto = response.data.msg

                this.planograma.dialogo = false

                this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        mostraPlanograma(item) {

            this.evidencia.dialogo = true
            this.evidencia.titulo = `Planograma (Acordo: ${item.id_acordo})`

            if(item.planograma.tipo == 'pdf')  {  

                this.evidencia.html = `<embed src="${item.planograma.url}" type="application/pdf" width="100%" height="450px">`
            }
            else {

                this.evidencia.html = `<img src="${item.planograma.url}" width="100%"></img>` 
            }
            
        },
        mostraEvidencia(item) {

            this.evidencias.titulo = `Evidências (Acordo: ${item.id_acordo}${item.id_acao ? ' / Ação: '+item.acao : ''}${item.cnpj ? ' / Loja: '+item.cnpj : ''})`
            this.evidencias.lista = item.evidencia
            this.evidencias.dialogo = true
        },
        mostraEvidenciaItem(item) {

            this.evidencia.titulo = `Evidência: ${item.nome}`

            if (item.tipo == 'application/pdf')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="application/pdf" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo == 'text/plain')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="text/plain" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo == 'application/json')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="application/json" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo.split('/')[0] == 'image') {

                this.evidencia.html = `<img src="${item.evidencia}" width="100%"></img>`
                this.evidencia.dialogo = true
            }
            else {

                window.location = item.evidencia
            }
        },
        mostraUpload(item, upload) {

            this.evidencia.dialogo = true
            this.evidencia.titulo = `${upload.nome} (Acordo: ${item.id_acordo}${item.acao ? ' / Ação: '+item.acao : ''}${item.cnpj ? ' / Loja: '+item.cnpj : ''})`

            if (upload.arquivo.tipo == 'pdf')  {

                this.evidencia.html = `<embed src="${upload.arquivo.url}" type="application/pdf" width="100%" height="450px">`
            }
            else {

                this.evidencia.html = `<img src="${upload.arquivo.url}" width="100%"></img>` 
            }
            
        },
        async editaAcordo () {
           
            this.carregando = true
            
            var data = this.processo
            
            data.investimento = this.edita.investimento

            var response = await this.$http().post('/acordo/editaAcordo', {data: data}) 
            
            this.carregando = false
            
            if(response.data.sucesso) {
                
                this.edita.dialogo = false

                this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        async enviaRecibo () {

            this.carregando = true
            
            var data = this.processo

            var response = await this.$http().post('/acordo/enviaRecibo', {data: data}) 
            
            this.confirm.dialogo = false
            this.carregando = false
            
            if(response.data.sucesso) {

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 3000
                this.alertaTexto = response.data.msg
               
                this.filtrar()
            }
            else {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async buscaRecibo(item) {

            this.carregando = true
            
            var response = await this.$http().post('/acordo/recibo', {data: item})
            
            this.evidencia.dialogo = true
            this.evidencia.titulo = 'Recibo (Acordo: '+item.id_acordo+')'

            this.evidencia.html = `<embed src="${this.$http('baseURL')+response.data.url}?x=${Date.now()}" type="application/pdf" width="100%" height="450px">`

            this.carregando = false
        },
        iconeSelecionarTudo(var_id, tabela, filtro, lista) {

            if(!filtro[var_id] || !lista[tabela]) return

            if(filtro[var_id].length === lista[tabela].length) return 'mdi-close-box'
            if (filtro[var_id].length > 0 && !(filtro[var_id].length === lista[tabela].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudo (var_id, tabela, filtro, lista, funcao = false) {

            if(!filtro[var_id] || !lista[tabela]) return

            this.carregando = true

            setTimeout(() => {

                if(filtro[var_id].length === lista[tabela].length) {

                    filtro[var_id] = []
                }
                else {

                    filtro[var_id] = lista[tabela].map(e => e.value)
                }
            }, 100)

            setTimeout(() => { 
                
                if(funcao) this[funcao]()

                this.carregando = false 
            }, 
            (lista[tabela].length > 100 ? lista[tabela].length : 110))
        },
    },
}
</script>

<style>
.style-investimento-negativo {
    color: var(--v-error-base) !important;
}
.sla{
  width:100px;
}
.sla span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>