<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.botao`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <template v-slot:[`item.executar`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="confirmExecutar(item)"
                        title="Executar Query"
                    >
                        mdi-play
                    </v-icon>
                </template>
                <template v-slot:[`item.historico`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="buscaHistorico(item)"
                        title="Histórico"
                    >
                        mdi-file-multiple
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{tituloDialogo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="form">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    :label="'ID '+tituloDialogo"
                                    v-model="obj['id_'+tabela]"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Atualização"
                                    v-model="obj.nome"
                                    :rules="regra.obrigatorio"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="px-2">
                                <v-textarea
                                    outlined
                                    dense 
                                    label="Descrição"
                                    v-model="obj.descricao"
                                    :rules="regra.obrigatorio"
                                    rows="2"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" class="px-2">
                                <v-textarea
                                    outlined
                                    dense 
                                    label="Query"
                                    v-model="obj.query"
                                    :rules="regra.obrigatorio"
                                    rows="10"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" class="px-2">
                                <v-switch
                                    label="Separar Query"
                                    v-model="obj.separar_query"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="confirm.dialogo"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 error white--text">
                    {{confirm.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <b>{{confirm.texto}}</b>
                    <br>
                    <br>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="confirm.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="error"
                        class="pr-4"
                        @click="confirm.funcao"
                    >
                        <v-icon left>mdi-play</v-icon> {{confirm.botao}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="historico.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{historico.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="historico.headers"
                    :items="historico.dados"
                    item-key="id_historico"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                >
                    <template v-slot:[`item.query`]="{ item }"> 
                        <v-icon
                            small
                            class="mr-2"
                            color="primary"
                            @click="mostraQuery(item)"
                            title="Query"
                        >
                            mdi-text-box
                        </v-icon>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="historico.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="query.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Query
                </v-card-title>
                <v-textarea
                    outlined
                    dense 
                    label="Query"
                    v-model="query.query"
                    rows="15"
                    class="ma-4"
                ></v-textarea>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="query.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tabela: 'atualizacao',
            titulo: 'Atualizações de Banco',
            tituloDialogo: 'Atualização de Banco',
            headers: [
                { text: 'ID Atualização', value: 'id_atualizacao', align: 'center'},
                { text: 'Atualização', value: 'nome', align: 'center'},
                { text: 'Usuário Criação', value: 'usuario_criacao', align: 'center'},
                { text: 'Data Criação', value: 'data_criacao', align: 'center'},
                { text: 'Usuário Atualização', value: 'usuario_atualizacao', align: 'center'},
                { text: 'Data Atualização', value: 'data_atualizacao', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
                { text: '', value: 'executar', align: 'center', sortable: false },
                { text: '', value: 'historico', align: 'center', sortable: false },
            ],
            dados: [], 
            obj: {},
            objNovo: {
                id_atualizacao: null,
                nome: null,
                query: null,
                separar_query: true,
                descricao: null,
            },
            pagination: {}, 
            total: null,
            carregando: false, 
            busca: null, 
            dialogo: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                obrigatorio: [ v => !!v || 'Campo obrigatório.' ] ,
            },
            confirm: {
                dialogo: false,
                titulo: null,
                texto: null,
                funcao: null,
                botao: null,
            },
            historico: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'ID Histórico', value: 'id_historico', sortable: false, align: 'center'},
                    { text: 'Cliente', value: 'cliente', sortable: false, align: 'center'},
                    { text: 'Códogo de Resposta', value: 'codigo', sortable: false, align: 'center'},
                    { text: 'Erro', value: 'erro', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Usuário', value: 'usuario', sortable: false, align: 'center'},
                    { text: '', value: 'query', align: 'center', sortable: false },
                ],
                dados: [],
            },
            query: {
                dialogo: false,
                query: null,
            },
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }

            }, 1000)
        },
    },
    async created() {
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination
            data.busca = this.busca

            data.filtro = {}

            var response = await this.$http().post(`/${this.tabela}/lista`, {data: data})   
            
            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        novo() {
           
            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  
                        
            this.dialogo = true
        }, 
        editar(item) {
           
            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  
            
            this.dialogo = true
        }, 
        async salvar() {

            if(!this.$refs.form.validate()) return
            
            this.carregando = true
            
            var data = this.obj

            var response = await this.$http().post(`/${this.tabela}/salvar`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false  
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },        
        confirmExecutar(item) {

            this.obj = {...item}

            this.confirm.titulo = 'Executar Query'
            this.confirm.texto = 'Deseja realmente executar a QUERY em todos os clientes cadastrados?'
            this.confirm.botao = 'Executar Query'
            this.confirm.funcao = this['executar']
            this.confirm.dialogo = true
        },
        async executar() {

            this.confirm.dialogo = false

            this.carregando = true

            var data = this.obj

            var response = await this.$http().post(`/${this.tabela}/executar`, {data: data})
            
            this.carregando = false

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg 
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async buscaHistorico(item) {

            this.carregando = true
            
            this.historico.dados = []
            
            this.historico.titulo = `Histórico`

            var response = await this.$http().post(`/${this.tabela}/historico`, {data: item})

            this.historico.dados = response.data

            this.historico.dialogo = true

            this.carregando = false
        },
        async mostraQuery(item) {

            this.carregando = true
            
            this.query.dialogo = true

            this.query.query = item.query

            this.carregando = false
        },
    },
}
</script>