var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticStyle:{"background":"#fff"}},[_c('link',{attrs:{"rel":"preconnect","href":"https://fonts.googleapis.com"}}),_c('link',{attrs:{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":""}}),_c('link',{attrs:{"href":"https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap","rel":"stylesheet"}}),_c('v-main',[_c('v-container',{staticClass:"containerLogin"},[_c('v-row',{staticClass:"conteudo ipdvColorGradient",attrs:{"no-gutters":""}},[_c('Slider'),_c('v-col',{attrs:{"xs":"12","sm":"12","md":"5","lg":"5","xl":"5"}},[_c('v-card',{staticClass:"flat",class:{
                              'rounded-16': _vm.screen.xs || _vm.screen.sm,
                              'rounded-e-16': !_vm.screen.xs && !_vm.screen.sm,
                              'pa-32': _vm.screen.xs,
                              'pa-64': !_vm.screen.xs,
                          },attrs:{"color":"transparent","height":"100%"}},[_c('div',{class:{
                                  'w-100 justify-center align-center d-flex':
                                  _vm.screen.xs || _vm.screen.sm,
                                  'd-none': !_vm.screen.xs && !_vm.screen.sm,
                              },staticStyle:{"min-height":"90px"}},[_c('v-img',{attrs:{"src":"https://s3.amazonaws.com/prateleira.ipdvonline.com.br/img/logo_default_header_branco.png","max-width":"113px","max-height":"51px"}})],1),_c('v-card',{staticClass:"rounded-12 justify-center d-flex flex-column align-center flat",attrs:{"color":"#fff","height":_vm.screen.xs || _vm.screen.sm ? '80%' : '100%'}},[_c('v-card',{staticClass:"rounded-0 pa-24 flat",attrs:{"color":"transparent","max-width":"349px","min-width":"200px","width":"100%"}},[_c('v-row',[_c('v-col',{class:{
                                              'custom-text-h3': !_vm.screen.xs && !_vm.screen.sm,
                                              'custom-text-h5': _vm.screen.xs || _vm.screen.sm,
                                              'pa-b-32 pr-1': true,
                                          },staticStyle:{"margin-top":"15px"},attrs:{"cols":"7","md":"8","lg":"8","xl":"8","xxl":"8"}},[_vm._v(" Bem-vindo ")]),_c('v-col',{staticClass:"pt-2 pl-1",attrs:{"cols":"5","md":"4","lg":"4","xl":"4","xxl":"4"}},[_c('v-img',{attrs:{"src":require("@/assets/images/login/logo.png"),"height":"80","contain":""}})],1),_c('div',[_vm._v(" Cliente não encontrado. Verifique se o endereço do sistema está escrito corretamente. ")])],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('Footer',{attrs:{"app":false,"clean":"","colorfooter":"header","marginTop":"4px"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }