<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="abrirImportar()"
                >
                    <v-icon left>mdi-upload</v-icon> Importar
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary" 
                    @click="exportarHistorico({})"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar Histórico
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">  
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                    <v-text-field
                        label="Mês"
                        v-model="filtro.mes"
                        :rules="regra.mes"
                        required
                        outlined
                        dense 
                        type="month"
                        :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col> 
                <v-col cols="12" sm="6" md="4" lg="5" xl="5" class="px-2 ml-auto" align="end">
                    <v-btn
                        class="ml-2 mb-1"
                        color="primary"
                        @click="limpar()"
                    >
                        <v-icon left>mdi-filter-off</v-icon> Limpar
                    </v-btn> 
                    <v-btn
                        class="ml-2 mb-1"
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.mes`]="{ item }"> 
                    {{item.mes.split('-')[1]+'/'+item.mes.split('-')[0]}}
                </template>
                <template v-slot:[`item.historico`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="buscaHistorico(item)"
                        title="Histórico"
                    >
                        mdi-file-document-multiple
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <!-- Importar CSV -->
        <v-dialog
            v-model="dialogoImportar"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar CSV
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="4" class="mt-4">
                            <v-text-field
                                label="Mês"
                                v-model="filtro.mes"
                                :rules="regra.mes"
                                required
                                outlined
                                dense 
                                type="month"
                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="mt-4 ml-4">
                            <v-select
                                label="Tipo de Importação"
                                :items="[{value: 1, text: 'Importar somente para o mês selecionado'}, {value: 12, text: 'Importar para os doze meses a partir do mês selecionado'}]"
                                v-model="importacaoTipo"
                                outlined
                                dense 
                                hide-details
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-alert
                        class="mt-0"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <li>
                                Os nomes das colunas devem ser <b>idênticos</b> aos do base exportada.
                            </li>
                            <li>
                                Coluna(s) obrigatória(s): <b>{{colunasObrigatorias}}</b>.
                            </li>
                            <li>
                                A coluna <b>Mês</b> não é considerada na importação. O <b>mês</b> considerado na importação é o <b>mês selecionano</b>.
                            </li>
                            <li>
                                Na coluna <b>Quantidade</b> qualquer valor que não seja um <b>número inteiro maior que 0 (zero)</b> será considerado como <b>0 (zero)</b>.
                            </li>
                        </ul> 
                    </v-alert> 
                    <v-row no-gutters>
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col> 
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon> 
                                Importar Arquivo
                            </v-btn>
                        </v-col> 
                    </v-row>
                    <v-row no-gutters  class="mx-2" v-show="progresso">
                        <v-col cols="12" class="px-2">
                            {{ labelProgresso }}
                        </v-col>
                        <v-col cols="12" class="px-2">
                            <v-progress-linear 
                                v-model="indexProgresso" 
                                height="25" 
                            >
                                <span class="font-weight-bold white--text">{{ Math.ceil(indexProgresso) }}%</span>
                            </v-progress-linear>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Logs
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                            <v-data-table
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros" 
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Historico -->
        <v-dialog
            v-model="historico.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Histórico
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters class="mt-2 mb-2">
                        <v-col cols="6">
                        </v-col>
                        <v-col cols="6" align="end">  
                            <v-btn
                                class="ml-2"
                                color="primary" 
                                @click="exportarHistorico({fnId: true})"  
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="historico.headers"
                        :items="historico.dados"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        class="elevation-0"
                    >
                        <template v-slot:[`item.mes`]="{ item }"> 
                            {{$moment(item.mes).format('MM/YYYY')}}
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="historico.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tabela: 'inventario',
            titulo: '',
            modelo: {},
            headers: [],
            ads: [],
            filtro: {},
            dados: [],
            pagination: {},
            paginationLimpa: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 5,
                multiSort: true,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            total: null,
            carregando: false,
            busca: null,
            dialogoImportar: false,
            importacaoTipo: 1,
            colunasObrigatorias: '',
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            arquivoCSV: null,
            regra: { 
                csv: [
                    v => !!v || 'Selecione um arquivo.',
                    v => (!!v && [
                        '.csv', 
                        '.CSV'
                    ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                ] ,
                mes: [
                    v => !!v || 'Campo obrigatório.',
                ],
                tipo: [v => !!v || 'Campo obrigatório.'],
            },  
            isCsv: false,
            headerErros: [
                { text: 'Tipo', value: 'tipo', align: 'center'},
                { text: 'Linha', value: 'linha', align: 'center'},
                { text: 'Descrição', value: 'msg', align: 'center'},
            ],
            erros: [],
            errosLabels: {msg: 'Descrição'},
            erro: false,
            paginationErros: {},
            progresso: false,
            indexProgresso: 0,
            labelProgresso: null,
            historico: {
                headers: [],
                dados: [],
                dialogo: false,
                item: {}
            }
        }
    },
    watch: {
        pagination: {
            handler () {
                if(this.modelo.nome) this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }
            }, 1000)
        },
        '$route' () {

            this.buscaModelo() 
        }
    },
    async created() {

        this.filtro.mes = this.mesAtual

        this.buscaModelo()

    },
    computed: { 
        mesAtual() {

            return this.$moment().format('YYYY-MM')
        },
    },
    methods: {
        async buscaModelo() {

            this.carregando = true

            var data = {
                id_modelo: this.$route.params.id
            }

            var response = await this.$http().post(`/${this.tabela}Modelo/dados`, {data: data})
            
            this.modelo = response.data

            this.titulo = this.modelo.nome

            this.headers = [
                { text: '', value: 'historico', align: 'center', sortable: false },
            ]

            this.colunasObrigatorias = ''

            for (const t of this.modelo.tabelas) {

                this.headers.push({ text: 'ID ' + t.nome, value: t.id, align: 'center'})
                this.headers.push({ text: t.codigo, value: 'codigo_' + t.tabela, align: 'center'})
                this.headers.push({ text: t.nome_campo, value: t.tabela, align: 'center'})

                this.colunasObrigatorias += t.codigo + ', '
            }

            this.colunasObrigatorias += 'Quantidade'

            this.headers.push({ text: 'Mês', value: 'mes', align: 'center'})
            this.headers.push({ text: 'Quantidade', value: 'quantidade', align: 'center'})

            this.historico.headers = this.headers.map(e => ({...e}))
            this.historico.headers.shift()
            //this.historico.headers.pop()
            this.historico.headers.unshift({ text: 'Data', value: 'data', align: 'center'})
            this.historico.headers.unshift({ text: 'Responsável', value: 'usuario_atualizacao', align: 'center'})

            this.carregando = false

            this.filtrar()
        },
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination
            data.busca = this.busca
            data.filtro = this.filtro
            data.modelo = this.modelo

            var response = await this.$http().post(`/${this.tabela}/lista`, {data: data})
            
            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        limpar() {

            this.pagination = {...this.paginationLimpa}

            this.filtro.mes = this.mesAtual
            this.busca = null
        },
        async exportar() {
           
            this.carregando = true
            
            var data = {}

            data.filtro = this.filtro
            data.modelo = this.modelo

            var response = await this.$http().post(`/${this.tabela}/exportar`, {data: data})
          
            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        }, 
        abrirImportar() {

            this.arquivoCSV = null
            this.erro = false
            this.progresso = false

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogoImportar = true 
        },
        async importar() {
            
            this.carregando = true

            this.erro = false
            this.progresso = true
            this.indexProgresso = 0
            this.labelProgresso = 'Enviando arquivo.'

            var formData = new FormData()

            formData.append("files", this.arquivoCSV)
            formData.append("modelo", JSON.stringify(this.modelo))
            formData.append("mes", JSON.stringify(this.filtro.mes))
            formData.append("importacaoTipo", JSON.stringify(this.importacaoTipo))

            this.arquivoCSV = null

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            var response = {}
            
            await this.$http().post(`/${this.tabela}/importar`, 
                formData,
                {
                    // onUploadProgress: (event) => {

                    //console.log(event.loaded, event.total) 
                    // },
                    onDownloadProgress: progressEvent => {

                        let retorno = progressEvent.currentTarget.response.split(";")

                        let obj = JSON.parse(retorno[(retorno.length - 2)])

                        this.indexProgresso = obj.index
                        this.labelProgresso = obj.label

                        response.sucesso = obj.sucesso ? obj.sucesso : null
                        response.erros = obj.erros ? obj.erros : null
                    }
                }
            )

            this.carregando = false
            this.filtrar()

            if(response.sucesso){

                this.dialogoImportar = false
            }
            else{

                this.progresso = false
                this.erros = response.erros
                this.paginationErros.page = 1
                this.erro = true
            }
        },
        async buscaHistorico(item) {

            this.carregando = true

            this.historico.item = {...item}
            
            var data = {
                tabela: this.tabela,
                id: item['id_'+this.tabela],
                modelo: this.modelo,
                mes: this.filtro.mes,
            }

            var response = await this.$http().post(`/historico/lista`, {data: data})
            
            this.historico.dados = response.data
            
            this.carregando = false

            this.historico.dialogo = true
        },
        async exportarHistorico({fnId=false}) {

            this.carregando = true

            var data = {
                tabela: this.tabela,
                id: fnId ? this.historico.item['id_'+this.tabela] : false,
                modelo: this.modelo,
                mes: this.filtro.mes,
            }

            var response = await this.$http().post(`/historico/exportar`, {data: data})
            
            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        },
    },
}
</script>