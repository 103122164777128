<template>
    <v-app style="background: #fff">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap" rel="stylesheet">
        <v-main>
            <v-container class="containerLogin">
                <v-row class="conteudo ipdvColorGradient" no-gutters>

                    <Slider />

                    <v-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <v-card
                            color="transparent"
                            :class="{
                                'rounded-16': screen.xs || screen.sm,
                                'rounded-e-16': !screen.xs && !screen.sm,
                                'pa-32': screen.xs,
                                'pa-64': !screen.xs,
                            }"
                            class="flat"
                            height="100%"
                        >
                            <div
                                style="min-height: 90px"
                                :class="{
                                    'w-100 justify-center align-center d-flex':
                                    screen.xs || screen.sm,
                                    'd-none': !screen.xs && !screen.sm,
                                }"
                            >
                                <v-img
                                    src="https://s3.amazonaws.com/prateleira.ipdvonline.com.br/img/logo_default_header_branco.png"
                                    max-width="113px"
                                    max-height="51px"
                                />
                            </div>

                            <v-card
                                color="#fff"
                                class="rounded-12 justify-center d-flex flex-column align-center flat"
                                :height="screen.xs || screen.sm ? '80%' : '100%'"
                            >
                                <v-card
                                    color="transparent"
                                    class="rounded-0 pa-24 flat"
                                    max-width="349px"
                                    min-width="200px"
                                    width="100%"
                                >
                                    <div v-if="cliente.nome">
                                        <v-form
                                            ref="form"
                                            lazy-validation
                                            @submit.prevent="entrar"
                                            v-show="!cliente.oculta_login"
                                        >
                                            <v-row>
                                                <v-col 
                                                    cols="7"
                                                    md="8"
                                                    lg="8"
                                                    xl="8"
                                                    xxl="8"
                                                    :class="{
                                                        'custom-text-h3': !screen.xs && !screen.sm,
                                                        'custom-text-h5': screen.xs || screen.sm,
                                                        'pa-b-32 pr-1': true,
                                                    }"
                                                >
                                                    Bem-vindo
                                                </v-col>
                                                <v-col
                                                    cols="5"
                                                    md="4"
                                                    lg="4"
                                                    xl="4"
                                                    xxl="4"
                                                    class="pt-2 pl-1"
                                                >
                                                    <v-img :src="logo" contain></v-img>
                                                </v-col>

                                                <v-col cols="12" class="mb-0 pb-0">
                                                    <v-text-field
                                                        class="bordaForm"
                                                        :class="{ 'autofill-fix': autofillFix }"
                                                        outlined
                                                        dense
                                                        label="Login"
                                                        v-model="login"
                                                        :rules="loginRegra"
                                                        required
                                                        hide-details
                                                        type="text"
                                                        @focus="autofillFix = false"
                                                        color="#053594"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" class="mb-0 pb-0">
                                                    <v-text-field
                                                        class="bordaForm"
                                                        :class="{ 'autofill-fix': autofillFix }"
                                                        outlined
                                                        dense
                                                        label="Senha"
                                                        v-model="senha"
                                                        :rules="senhaRegra"
                                                        required
                                                        hide-details
                                                        type="password"
                                                        @focus="autofillFix = false"
                                                        append-inner-icon="'mdi-eye-outline'"
                                                        color="#053594"
                                                    ></v-text-field>
                                                </v-col>

                                                <v-col cols="12" class="mb-0 pb-0 pt-5">
                                                    <v-btn
                                                        color="primary"
                                                        :variant="enabledBtnAcessar ? 'flat' : 'tonal'"
                                                        :disabled="!enabledBtnAcessar"
                                                        block
                                                        type="submit"
                                                        class="rounded-12 flat btn-acessar"
                                                    >ACESSAR</v-btn>

                                                </v-col>

                                                <v-col cols="12" class="">
                                                    <v-btn
                                                        color="transparent"
                                                        variant="text"
                                                        block
                                                        class="custom-text-ps text-none flat"
                                                        density="compact"
                                                        size="xsmall"
                                                        @click="abrisRecuperarSenha()"
                                                    >Esqueci minha senha</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>

                                        <v-btn
                                            class="mt-4"
                                            color="primary"
                                            @click="sso()"
                                            v-show="cliente.exibe_sso"
                                        >
                                            {{cliente.sso_botao}}
                                        </v-btn>

                                        <v-alert 
                                            type="error" 
                                            class="mt-5"
                                            transition="scale-transition"
                                            dismissible
                                            v-model="alerta"
                                        >
                                            {{alertaMesagem}}
                                        </v-alert>
                                    </div>
                                    <div v-if="!cliente.nome">
                                        <v-row>
                                            <v-col 
                                                cols="7"
                                                md="8"
                                                lg="8"
                                                xl="8"
                                                xxl="8"
                                                :class="{
                                                    'custom-text-h3': !screen.xs && !screen.sm,
                                                    'custom-text-h5': screen.xs || screen.sm,
                                                    'pa-b-32 pr-1': true,
                                                }"
                                                style="margin-top: 15px;"
                                            >
                                                Bem-vindo
                                            </v-col>
                                            <v-col
                                                cols="5"
                                                md="4"
                                                lg="4"
                                                xl="4"
                                                xxl="4"
                                                class="pt-2 pl-1"
                                            >
                                                <v-img src="@/assets/images/login/logo.png" height="80" contain></v-img>
                                            </v-col>
                                            <br>
                                            <div>
                                                Cliente não encontrado. Verifique se o endereço do sistema está escrito corretamente.
                                            </div>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-card>
                        </v-card>

                    </v-col>
                </v-row>

                <v-row no-gutters="">
                    <Footer
                        :app="false"
                        clean
                        colorfooter="header"
                        marginTop="4px"
                    />
                </v-row>

                <v-dialog
                    v-model="esqueciSenha.dialogo"
                    width="70%"
                >  
                    <v-card>
                        <v-card-title class="subtitle-1 primary white--text">
                            Esqueci minha senha
                        </v-card-title>
                        <v-card-text class="mt-4">  
                            <v-form ref="formEsqueciSenha">
                                <v-row no-gutters>
                                    <v-col cols="12" class="pt-4">
                                        Para recuperar seu acesso, precisamos do seu E-mail.
                                    </v-col>
                                    <v-col cols="12" class="mt-2">
                                        <v-text-field
                                            label="E-mail"
                                            v-model="esqueciSenha.email"
                                            outlined
                                            dense 
                                            :rules="esqueciSenha.emailRegra"
                                            required
                                            type="email"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="mt-2">
                                        Em caso de duvidas contate o suporte através do e-mail: <a class="link" href="mailto:ifinancesuporte@ipdvonline.com.br">ifinancesuporte@ipdvonline.com.br</a>
                                    </v-col>
                                </v-row> 
                            </v-form>   
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="esqueciSenha.dialogo = false"
                            >
                                Cancelar
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="recuperarSenha()"
                            >
                                Recuperar Senha
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-overlay :value="carregando" :z-index="300">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>

                <v-snackbar
                    v-model="alertaGeral"
                    :color="alertaColor"
                    :timeout="alertaTimeout"
                    centered
                    style="z-index: 400"
                >
                    <v-alert :type="alertaColor" v-html="alertaTexto"></v-alert>
                    <v-btn
                        dark
                        text
                        class="float-right"
                        @click="alertaGeral=false"
                    >
                    OK, ENTENDI
                    </v-btn>
                </v-snackbar>

            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Slider from '../components/containment/Slider.vue'
import Footer from "../components/containment/Footer.vue";

export default {
    data() {
        return {
            logo: null,
            cliente: {
                oculta_login: true,
                exibe_sso: false,
            },
            login: null,
            senha: null,
            loginRegra: [
                v => !!v || 'O campo usuário é obrigatório.'
            ],
            senhaRegra: [
                v => !!v || 'O campo senha é obrigatório.'
            ],
            alerta: false,
            esqueciSenha: {
                dialogo: false,
                email: null,
                emailMsg: false,
                emailRegra: [
                    v => !!v || 'O campo e-mail é obrigatório.',
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail invalido.',
                    () => !this.esqueciSenha.emailMsg || this.esqueciSenha.emailMsg,
                ],
            },
            carregando: false,
            alertaGeral: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            atalhoLogin: '',
            autofillFix: false,
            screen: this.$vuetify.breakpoint,
            enabledBtnAcessar: false,
        }
    },
    async created() {

        this.$vuetify.theme.dark = false

        document.addEventListener("keydown", this.keymonitor, false)

        this.buscaCliente()
    },
    mounted() {
        this.autoLoginCheckingInterface()
    },
    computed: {
        alertaMesagem() {
            this.atualizaAlerta()
            return this.$store.getters.getAlertaMesagem
        },
    },
    watch: {
        login: function () {
            this.enabledBtnAcessar = (this.login ?? "") != "" && (this.senha ?? "") != "";
        },
        senha: function () {
            this.enabledBtnAcessar = (this.login ?? "") != "" && (this.senha ?? "") != "";
        },
    },
    methods: {
        async buscaCliente() {

            this.cliente.nome = this.$route.params.cliente

            var response = await this.$http().post('/login/cliente', {data: {nome: this.cliente.nome}})

            this.cliente = response.data[0]

            if (!this.cliente) {

                this.cliente = {}
                this.cliente.titulo = 'GOP - Cliente não encontrado.'
            }
            else {

                //this.$vuetify.theme.themes.light.primary = this.cliente.cor
                this.$vuetify.theme.themes.light.primary = '#053594'
                this.$vuetify.theme.themes.light.fundo = this.cliente.cor_fundo
                document.getElementById("favicon").href = this.cliente.logo_icone
                this.logo = this.cliente.logo_login

                localStorage.setItem('cliente', this.cliente.nome)
                sessionStorage.setItem('cliente', this.cliente.nome)

                if (sessionStorage.getItem('token') && !this.$route.params.erroSso) {

                    this.$store.dispatch('loginToken', [sessionStorage.getItem('token'), this.cliente.nome])
                }
                else if (localStorage.getItem('token') && !this.$route.params.erroSso) {

                    this.$store.dispatch('loginToken', [localStorage.getItem('token'), this.cliente.nome])
                }
            }
            
            document.title = this.cliente.titulo

            if(this.$route.params.recuperarSenha) {

                this.abrisRecuperarSenha()
            }

            if(this.$route.params.erroSso) {

                this.alertaGeral = true
                this.alertaColor = 'info'
                this.alertaTimeout = -1
                this.alertaTexto = this.$route.params.msg
            }
        },
        atualizaAlerta() {
            if(this.$store.getters.getAlertaMesagem) {
                this.alerta = true
            }
            else {
                this.alerta = false
            }
        },
        entrar() {

            if(!this.$refs.form.validate()) return

            this.$store.dispatch('login', [this.login, this.senha, this.cliente.nome])
        },
        abrisRecuperarSenha() {

            this.esqueciSenha.dialogo = true
            this.esqueciSenha.email = null
            this.esqueciSenha.emailMsg = false

            try {
                this.$refs.formEsqueciSenha.resetValidation()
            } catch (error) {
                //console.log(error)
            } 
        },
        async recuperarSenha() {

            this.esqueciSenha.emailMsg = false

            if(!this.$refs.formEsqueciSenha.validate()) return

            this.carregando = true

            var data = {
                email: this.esqueciSenha.email, 
                cliente: this.cliente.nome,
                baseURL: location.origin,
            }

            var retorno = await this.$http().post(`/login/recuperarSenha`, {data: data})

            this.carregando = false

            if(retorno.data.sucesso){

                this.esqueciSenha.dialogo = false

                this.alertaGeral = true
                this.alertaColor = 'success'
                this.alertaTimeout = 5000
                this.alertaTexto = retorno.data.msg 
            }
            else{

                this.esqueciSenha.emailMsg = retorno.data.msg
                this.$refs.formEsqueciSenha.validate()
            }
        },
        sso() {

            window.location.href = `${this.$http('baseURL')}api/${this.cliente.nome}/sso`
        },
        keymonitor(event) {

            if (typeof event.key == 'string') {

                this.atalhoLogin += event.key

                if (event.key == 'l') {

                    this.atalhoLogin = 'l'
                }

                if (this.atalhoLogin == 'lipdv') {

                    this.cliente.oculta_login = false
                }
            }
       },
        autoLoginCheckingInterface() {
            let intervalDetectAutofill = setInterval(() => {
                if (document.querySelectorAll('input:-webkit-autofill').length > 0) {
                    this.autofillFix = true;
                    this.enabledBtnAcessar = true;
                    clearInterval(intervalDetectAutofill)
                }
            }, 100)

            setTimeout(() => {
                if (intervalDetectAutofill) {
                    clearInterval(intervalDetectAutofill)
                    intervalDetectAutofill = null
                }
            }, 3000)
        },
    },
    components: {
        Slider,
        Footer,
    },
}
</script>

<style scoped>
    * {
        scrollbar-width: thin;
        scrollbar-color: 
            color-mix(in srgb, rgb(53,89,145), #ffffff 45%) rgb(255,255,255);
    }
    html {
        overflow: scroll;
    }
    body {
        font-family: Ubuntu !important;
        font-weight: 400;
        line-height: 22px;
        font-size: 15px;
    }
    button, input, optgroup, select, textarea {
        font: inherit;
    }
    .v-application {
        font-family: Ubuntu !important;
        background-color: rgb(246, 246, 250) !important;
    }

    .v-btn.v-size--default.btn-acessar {
        font-size: 14px !important;
    }

    .conteudo {
        height: calc((100% - 0px) - 54px) !important;
    }
    .pa-24 {
        padding: 24px;
    }
    .pa-32 {
        padding: 32px;
    }

    .pa-b-32 {
        padding-bottom: 32px !important;
    }


    .pa-64 {
        padding: 64px;
    }
    .containerLogin {
        height: 100%;
        width: 100%;
        max-width: 1440px;
        padding: 32px;
    }

    .ipdvColorGradient {
        background: linear-gradient(
            to right,
            #053594 50%,
            #400597,
            #400597
        ) !important;
        border-radius: 16px !important;
    }

    .flat {
        box-shadow: none !important;
    }

    .rounded-12 {
        border-radius: 12px !important;
    }

    .rounded-16 {
        border-radius: 16px !important;
    }
    .rounded-e-16 {
        border-radius: 0 16px 16px 0 !important;
    }

    .btn-acessar {
        letter-spacing: 1.4px;
        font-weight: 500;
    }

    .btn-acessar > span  {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }
    
    .borda {
        border-radius: 14px !important;
    }
    .bordaForm {
        border-radius: 12px !important;
    }
    .bordaForm >>> input {
        border-radius: 12px !important;
        max-height: 40px;
        max-width: 130%;
        margin: 0px -12px;
    }
    .bordaBotao {
        padding: 0px 24px;
    }
    .bordaBotao button {
        border-radius: 12px !important;
        height: 41px !important;
    }
    .custom-text-h3 {
        font-weight: 500 !important;
        line-height: 36px !important;
        font-size: 26px !important;
    }
    .custom-text-h5 {
        font-weight: 500 !important;
        line-height: 24px !important;
        font-size: 18px !important;
    }
    .custom-text-ps {
        font-weight: 400 !important;
        line-height: 20px !important;
        font-size: 13px !important;
        max-height: 24px;
        letter-spacing: 1.5px;
        margin-top: -2px;
        color: #121212 !important;
    }
    .login-footer {
        margin-bottom: 15px;
    }
    .row + .row {
        margin-top: 0px !important;
    }
    @media (min-width: 1904px) {
        .col-xl-12 {
            flex: inherit !important;
        }
        .card-flex {
            flex: 0 0 100% !important;
        }
    }

    .v-text-field >>> input {
        padding: 10px 16px !important;
    }
    .v-text-field >>> label {
        padding-left: 4px !important;
    }
    .v-text-field--outlined >>> fieldset {
        z-index: 1;
    }
    .autofill-fix >>> label {
        transform: translateY(-16px) scale(.75) !important;
    }
    .autofill-fix >>> input {
        font: unset !important;
    }
    .autofill-fix >>> fieldset {
        padding-left: 8px !important;
    }
    .autofill-fix >>> fieldset legend {
        width: 37px !important;
    }

    .link{
        color: #8c8c8c;
        text-decoration: underline;
        font-size: 14px;
        font-family: Arial, Helvetica, "Liberation Sans", Roboto, sans-serif;
        line-height: 1.5;
    }
    .ubuntu-light {
        font-family: "Ubuntu", sans-serif;
        font-weight: 300;
        font-style: normal;
    }

    .ubuntu-regular {
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .ubuntu-medium {
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .ubuntu-bold {
        font-family: "Ubuntu", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .ubuntu-light-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 300;
        font-style: italic;
    }

    .ubuntu-regular-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-style: italic;
    }

    .ubuntu-medium-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-style: italic;
    }

    .ubuntu-bold-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 700;
        font-style: italic;
    }

    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: transparent !important;
    }
</style>